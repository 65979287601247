import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero26 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>26. LE BUONE IDEE DEVONO ESSERE MESSE IN AZIONE
            <br/>8 novembre 1911
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1421848030&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p>In tutto il mondo si sentono magnificare le belle frasi e si ammirano i nobili precetti. Tutti dicono di
                amare ciò che è buono e odiare tutto ciò che è cattivo. La sincerità è ammirevole mentre la menzogna è
                spregevole. La fede è una virtù e il tradimento è una calamità per il genere umano. È cosa bella
                rallegrare i cuori ed è riprovevole esser causa di dolore. Essere benevolo e misericordioso è virtù,
                mentre è peccato odiare. È nobile essere giusti e ignobile essere ingiusti. È doveroso essere pietosi e
                non far male a nessuno ed evitare la gelosia e la malignità a tutti i costi. La saggezza è la gloria
                dell’uomo, non l’ignoranza, la luce, non le tenebre! È buona cosa volgere lo sguardo verso Dio e
                stoltezza ignorarLo. È nostro dovere guidare l’uomo verso l’alto e non sviarlo ed esser causa della sua
                caduta. E molti altri esempi vi sono di simili belle frasi.</p>
            <p>Ma tutti questi detti rimangono vane parole e molto raramente li vediamo posti in azione. Al contrario,
                vediamo che gli uomini sono traviati dalle passioni e dall’egoismo, che ognuno pensa soltanto a ciò che
                gli porterà vantaggio, anche se ciò porterà la rovina di un suo fratello. Tutti sono ansiosi di far
                fortuna e poco o punto si curano della prosperità degli altri. Si interessano soltanto della propria
                tranquillità e del proprio benessere e non si preoccupano affatto delle condizioni del prossimo.</p>
            <p>Sfortunatamente è questa la via seguita dalla maggior parte degli uomini.</p>
            <p>Ma i bahá’í non devono essere così. Devono sollevarsi al disopra di questa condizione. Per essi gli atti
                devono essere più delle parole. È nelle azioni e non soltanto a parole che devono mostrarsi
                misericordiosi. In ogni occasione devono confermare con le azioni ciò che proclamano con le parole. Le
                loro azioni devono dar prova della loro fede e riflettere la luce divina.</p>
            <p>Fate che le vostre azioni proclamino al mondo che siete veramente bahá’í, perché sono le azioni che
                parlano al mondo e sono causa del progresso dell’umanità.</p>
            <p>Se siamo veri bahá’í, non abbiamo bisogno di discorsi. Le nostra azioni avranno effetto nel mondo,
                diffonderanno la civiltà, daranno impulso al progresso della scienza e aiuteranno lo sviluppo delle
                arti. Senza azione, niente si può compiere nel mondo materiale e le parole non possono da sole far
                avanzare un uomo nel Regno spirituale. Non è soltanto per il bene fatto a parola che gli eletti di Dio
                hanno conseguito la santità. Con vita paziente e con costante attività essi hanno portato luce al
                mondo.</p>
            <p>Fate dunque sì che le vostre azioni siano ogni giorno come belle preghiere. Volgetevi verso Dio e cercate
                sempre di fare ciò che è giusto e nobile. Soccorrete i poveri, rialzate i caduti, confortate gli
                afflitti, curate gli infermi, incoraggiate i timorosi, liberate gli oppressi, infondete speranza agli
                sfiduciati, date asilo i derelitti!</p>
            <p>Questo è il lavoro di un vero bahá’í. Questo è ciò che ci si aspetta da lui. Se ci adoperiamo a fare
                tutto ciò, allora siamo veri bahá’í, ma se non ce ne curiamo, non siamo seguaci della Luce e non abbiamo
                diritto a questo nome.</p>
            <p>Dio, che vede tutti i cuori, sa fino a che punto le nostre vite sono l’attuazione delle nostre
                parole.</p>
        </Wrapper>
    </div>

)
