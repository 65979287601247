import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero52 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>52. QUESTA CAUSA, GRANDE E GLORIOSA
            <br/>Avenue de Camoëns 4, Parigi
            <br/>28 novembre 1911
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1420706026&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p>In queste riunioni nelle quali ci siamo incontrati e abbiamo parlato insieme, voi tutti avete appreso i
                principi di questa dispensazione e la realtà dei fatti. A voi è stato dato conoscere queste cose, ma vi
                sono ancora molti che non sono illuminati e sono immersi nella superstizione. Hanno udito ben poco di
                questa grande e gloriosa Causa e la conoscenza che ne hanno è per lo più basata sul sentito dire. Ohimè
                povere anime! La conoscenza che ne hanno non è basata sulla verità e il fondamento della loro fede non è
                l’insegnamento di Bahá’u’lláh! V’è sicuramente qualcosa di vero in quello che è stato detto loro, ma per
                la maggior parte la loro informazione è stata inesatta.</p>
            <p>I vari principi della benedetta Causa di Dio sono le undici norme che vi ho dato e che vi ho
                accuratamente spiegato una per una. </p>
            <p>Dovete cercare sempre di vivere e agire nella diretta obbedienza agli insegnamenti e alle leggi di
                Bahá’u’lláh, così che ogni persona possa vedere in tutti gli atti della vostra vita che, in parole e
                opere, voi siete seguaci della Perfezione Benedetta. </p>
            <p>Adoperatevi affinché questo glorioso insegnamento possa abbracciare il globo e la spiritualità possa
                essere infusa nei cuori degli uomini. </p>
            <p>L’alito dello Spirito Santo vi confermerà e, sebbene molti insorgeranno contro di voi, non
                prevarranno!</p>
            <p>Quando nostro Signore Gesù Cristo fu incoronato di spine, sapeva che tutti i diademi del mondo erano ai
                Suoi piedi. Tutte le corone terrene, per quanto brillanti, potenti e splendenti, s’inchinarono adoranti
                davanti alla corona di spine! Fu per questa conoscenza sicura e certa che Egli parlò quando disse: «Ogni
                podestà mi è data in cielo, e in terra» (Matteo XXVIII, 18).</p>
            <p>Ora io vi dico, tenete tutto questo nel cuore e nella mente. In verità la vostra luce illuminerà il mondo
                intero, la vostra spiritualità toccherà il cuore delle cose. In verità diverrete le fiaccole accese del
                globo. Non temete e non siate turbati, perché la vostra luce penetrerà la più fitta tenebra. Questa è la
                Promessa di Dio che io vi do. Sorgete e servite la Potenza di Dio!</p>
        </Wrapper>
    </div>

)
