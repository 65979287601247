import * as React from "react";
import {Form, IFields, isEmail, maxLength, required} from "./Form";
// @ts-ignore
import {Field} from './Field';
import styled from "styled-components";

export const Contact: React.FC = () => {
    const Title = styled.h1`
      font-size: 2.0em;
      text-align: center;
    `;

// Create a Wrapper component that'll render a <section> tag with some styles
    const Wrapper = styled.section`
      padding: 4em;
    `;
    const fields: IFields = {
        name: {
            id: "name",
            label: "Nome *",
            validation: {rule: required}
        },
        email: {
            id: "email",
            label: "Email *",
            validation: {rule: isEmail}
        },
        reason: {
            id: "reason",
            label: "Oggetto *",
            editor: "dropdown",
            options: ["", "Domanda generale", "Suggerimenti per il miglioramento del sito", "Commenti sulla saggezza dell'audio di Abdu'l-Bahá", "Aggiungere altri contenuti audio?"],
            validation: {rule: required}
        },
        notes: {
            id: "notes",
            label: "Messaggio *",
            editor: "multilinetextbox",
            validation: {rule: maxLength, args: 1500}

        }
    };
    return (
        <div>
            <Title>Contattaci</Title>
            <Wrapper>
                <p>
                    Contatta la comunità bahá’í della Valtellina per avere maggiori informazioni riguardo la Fede bahá’í
                    e
                    le sue attività. Usando questa pagina puoi mandare un email di modo che
                    possiamo aiutare a rispondere alle tue domande e a metterti in contatto con noi.
                </p>
                <Form
                    action="https://api.emailjs.com/api/v1.0/email/send"
                    fields={fields}
                    render={() => (
                        <React.Fragment>
                            <h2>Contattaci</h2>
                            <div className="alert alert-info" role="alert">
                                Inserisci le informazioni di seguito e ti risponderemo il prima possibile.
                            </div>
                            <Field {...fields.name} />
                            <Field {...fields.email} />
                            <Field {...fields.reason} />
                            <Field {...fields.notes} />
                        </React.Fragment>
                    )}
                />
            </Wrapper>
        </div>
    );
};
