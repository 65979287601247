import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero6 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>6. LE MESCHINE CAUSE DELLA GUERRA E IL DOVERE DI OGNUNO DI FARE OPERE DI PACE
            <br/>21 ottobre 1911
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1376612485&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p>
                ‘Abdu’l-Bahá disse:<br/>
                Spero che siate tutti felici e in buona salute. Io non sono felice, ma molto triste. Le notizie della
                battaglia di Bengasi rattristano il mio cuore. Mi meraviglio della crudeltà umana che esiste ancora nel
                mondo. Com’è possibile che gli uomini combattano dalla mattina alla sera, ammazzandosi l’un l’altro,
                versando il sangue del prossimo? E per quale scopo? Per impadronirsi d’un pezzo di terra! Perfino gli
                animali, quando combattono, hanno uno scopo più immediato e ragionevole. Com’è terribile che l’uomo, che
                appartiene al regno più alto, si abbassi fino ad ammazzare il prossimo e a gettarlo nel dolore per
                impadronirsi d’un pezzo di terra!
            </p>
            <p>
                Le creature superiori combattono per il possesso della più bassa forma della materia, la terra. La terra
                non appartiene a un popolo solo, ma a tutti. Questo mondo non è la dimora degli uomini, ma la loro
                tomba. Combattono dunque per una tomba. Non c’è cosa al mondo più orribile di una tomba, un luogo nel
                quale i corpi umani si disfano.
            </p>
            <p>
                Un conquistatore, per quanto grande possa essere, per quanto numerosi possano essere i paesi che ha
                ridotto in schiavitù, non può trattenere di quei territori devastati altro che una piccolissima parte,
                la sua tomba! Se occorresse una maggiore estensione di terra per il progresso di un popolo, per la
                diffusione della civiltà (per sostituire leggi giuste a usanze barbare), certo dovrebbe essere possibile
                acquistare pacificamente il territorio necessario.
            </p>
            <p>
                Ma la guerra si fa per soddisfare l’ambizione degli uomini! Per la sete di guadagno di pochi si porta la
                miseria in tante e tante case, s’infrangono i cuori di centinaia di uomini e donne!
            </p>
            <p>
                Quante vedove piangono il marito defunto, quante storie di selvaggia crudeltà sentiamo! Quanti orfanelli
                si disperano per la morte del padre, quante donne invocano i figli uccisi!
            </p>
            <p>
                Non c’è cosa più straziante e terribile di uno scoppio di ferocia umana!
            </p>
            <p>
                Raccomando a ciascuno di voi di concentrare tutti i pensieri del suo cuore sull’amore e sull’unità.
                Quando viene un pensiero di guerra, opponetegli un più forte pensiero di pace. Un pensiero d’odio
                dev’essere distrutto da un più potente pensiero d’amore. I pensieri di guerra distruggono l’armonia, il
                benessere, il riposo e la contentezza.
            </p>
            <p>
                I pensieri d’amore producono fratellanza, pace, amicizia e felicità.
            </p>
            <p>
                Mentre i soldati del mondo sguainano le spade per uccidere, i soldati di Dio si stringono la mano. Possa
                ogni atto selvaggio dell’uomo scomparire per la Misericordia di Dio che opera mediante i cuori puri e le
                anime sincere. Non pensate che la pace del mondo sia un ideale irraggiungibile.
            </p>
            <p>
                Niente e impossibile alla divina Benevolenza di Dio.
            </p>
            <p>
                Se desiderate con tutto il cuore la fratellanza con ogni razza del mondo, i vostri pensieri positivi e
                spirituali si propagheranno, il vostro desiderio diverrà il desiderio degli altri e si farà sempre più
                forte fino a fissarsi nella mente di tutti gli uomini.
            </p>
            <p>
                Non disperate! Lavorate sempre! La sincerità e l’amore vinceranno l’odio. Quante cose che una volta
                sembravano impossibili si verificano in questi giorni! Volgete sempre il viso verso la Luce del mondo.
                Dimostrate amore a tutti. «L’amore è l’alito dello Spirito Santo nel cuore dell’uomo». Abbiate coraggio!
                Dio non abbandona mai quei figli Suoi che si sforzano e lavorano e pregano. Siano i vostri cuori pieni
                dell’ardente desiderio che la tranquillità e l’armonia pervadano tutte le anime in questo mondo in
                guerra. Così i vostri sforzi saranno coronati dal successo e con la fratellanza universale verranno il
                Regno di Dio, la pace e la benevolenza.
            </p>
            <p>
                In questa sala sono oggi presenti membri di diverse nazionalità, francesi, americani, inglesi, tedeschi,
                italiani, fratelli e sorelle che si sono incontrati in amicizia e armonia. Fate che questa riunione
                preluda a ciò che sicuramente avverrà in questo mondo, quando ogni figlio di Dio comprenderà che siamo
                foglie d’un solo albero, fiori d’uno stesso giardino, gocce d’un medesimo mare e figli d’un unico Padre,
                che si chiama amore.
            </p>
        </Wrapper>
    </div>

)
