import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero29 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>29. L’EVOLUZIONE DELLO SPIRITO
            <br/>Rue Greuse, 15, Parigi
            <br/>10 novembre 1911
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1408989367&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p>‘Abdu’l-Bahá disse:
                <br/>Stasera parlerò dell’evoluzione o progresso dello spirito.</p>
            <p>Il riposo assoluto non esiste in natura. Tutte le cose o progrediscono o perdono terreno. Tutto si muove
                o in avanti o indietro; niente è senza moto. L’uomo progredisce fisicamente dalla nascita fino alla
                maturità e poi, arrivato al fiore della vita, incomincia a declinare. La forza e i poteri del corpo
                diminuiscono e gradualmente arriva all’ora della morte. Similmente una pianta cresce dal seme fino alla
                maturità e poi la sua vita incomincia a declinare finché appassisce e muore. Un uccello si alza fino a
                una certa altezza e raggiunto in volo il più alto punto possibile, incomincia a discendere verso la
                terra.</p>
            <p>È pertanto evidente che il moto è essenziale ad ogni esistenza. Tutte le cose materiali progrediscono
                fino a un certo punto, poi incominciano a declinare. Questa è la legge che governa l’intera creazione
                fisica.</p>
            <p>Ora esaminiamo l’anima. Abbiamo visto che il moto è essenziale all’esistenza e che nessuna delle cose che
                hanno vita resta immobile. Tutta la creazione, del regno minerale o vegetale o animale, è costretta a
                obbedire alla legge del moto: deve ascendere o discendere. Ma per l’anima umana non c’è decadenza. Il
                suo solo movimento è verso la perfezione, elevazione e progresso solamente costituiscono il moto
                dell’anima.</p>
            <p>La perfezione divina è infinita, perciò il progresso dell’anima è illimitato. Fin da quando un essere
                umano viene al mondo, l’anima progredisce, l’intelletto cresce e la conoscenza aumenta. Quando il corpo
                muore, l’anima sopravvive. Tutti i diversi gradi degli esseri appartenenti alla creazione fisica sono
                limitati, ma l’anima non ha limiti.</p>
            <p>In tutte le religioni esiste la credenza che l’anima sopravviva alla morte del corpo. Si chiede
                intercessione per i cari defunti, si dicono preghiere per il loro progresso e per la remissione dei loro
                peccati. Se l’anima perisse con il corpo tutto ciò non avrebbe senso. Inoltre se all’anima non fosse
                possibile avanzare verso la perfezione dopo essersi liberata dal corpo, che vantaggio arrecherebbero
                tutte queste amorevoli, devote preghiere?</p>
            <p>Leggiamo nelle sacre Scritture che «tutte le buone opere si ritrovano». Ora, se l’anima non
                sopravvivesse, nemmeno queste parole avrebbero un significato!</p>
            <p>Il fatto stesso che il nostro istinto spirituale, non datoci certamente invano, ci porti a pregare per il
                bene dei nostri cari che se ne sono andati dal mondo materiale, non prova forse che essi continuano a
                esistere?</p>
            <p>Nel mondo dello spirito non si può retrocedere. Il mondo dei mortali è un mondo di contraddizioni, di
                opposti. Essendo il moto inevitabile, tutto deve avanzare o retrocedere. Nel campo dello spirito, non è
                possibile arretrare, ogni movimento deve andare verso uno stato perfetto. Il «progresso» è l’espressione
                dello spirito nel mondo della materia. L’intelligenza dell’uomo, il potere del ragionamento, le sue
                cognizioni, le sue scoperte scientifiche, essendo manifestazioni dello spirito, sono soggette
                all’inevitabile legge del progresso spirituale e sono, perciò, necessariamente immortali.</p>
            <p>È mia speranza che progrediate sempre tanto nel mondo dello spirito quanto nel mondo della materia, che
                la vostra intelligenza si sviluppi, le vostre cognizioni aumentino e la vostra mente si apra sempre
                più.</p>
            <p>Dovete sempre spingervi avanti, mai rimaner fermi. Evitate l’inerzia, che è il primo passo indietro,
                verso la decadenza.</p>
            <p>Tutta la creazione fisica è caduca. I corpi sono composti di atomi. Quando questi atomi incominciano a
                separarsi, ha inizio la decomposizione e poi sopravviene ciò che chiamiamo morte. Questa composizione di
                atomi, che costituisce il corpo o l’elemento mortale di qualsiasi essere creato, è temporanea. Quando
                viene meno la forza di attrazione che tiene insieme questi atomi, il corpo, come tale, cessa di
                esistere.</p>
            <p>Per l’anima è diverso. L’anima non è una combinazione di elementi, non è composta di tanti atomi. Essa è
                di una sostanza indivisibile e perciò è eterna. L’anima è completamente fuori dell’ordine della
                creazione fisica, è immortale.</p>
            <p>La filosofia scientifica ha dimostrato che un elemento semplice («semplice» nel senso di «non composto»)
                è indistruttibile, eterno. L’anima, non essendo una composizione di elementi, è, per suo carattere, come
                un elemento semplice e perciò non può cessare di esistere.</p>
            <p>Essendo di una sostanza indivisibile, l’anima non può esser soggetta né a decomposizione né a distruzione
                e perciò non c’è ragione alcuna perché possa arrivare a una fine. Tutte le cose viventi mostrano segni
                della loro esistenza. Ne consegue che questi segni non potrebbero per se stessi esistere, se ciò che
                esprimono o che provano non esistesse. Una cosa che non esiste, non può, certamente, dar segni di
                esistenza. Gli innumerevoli segni dell’esistenza dello spirito sono sempre davanti a noi.</p>
            <p>Le tracce dello Spirito di Gesù Cristo, l’influenza dei Suoi divini insegnamenti, sono oggi presenti fra
                noi e dureranno sempre.</p>
            <p>Indiscutibilmente una cosa inesistente non può dar segni di vita. Per potere scrivere, un uomo deve
                esistere. Chi non esiste non può scrivere. Lo scrivere è in se stesso un segno dell’anima e
                dell’intelligenza di chi scrive. Le sacre Scritture, con sempre gli stessi Insegnamenti, provano la
                continuità dello spirito.</p>
            <p>Considerate lo scopo della creazione. È mai possibile che tutto sia stato creato per evolversi e
                svilupparsi nel corso di innumerevoli ere, in vista di questa ristretta finalità, i pochi anni della
                vita d’un uomo sulla terra? Non è inammissibile che questo sia lo scopo supremo dell’esistenza?</p>
            <p>Il minerale si evolve finché è assorbito nella vita d’una pianta. La pianta progredisce finché perde la
                sua vita in quella dell’animale. L’animale a sua volta, facendo parte del cibo dell’uomo, è assorbito
                nella vita umana.</p>
            <p>Così si vede che l’uomo è la somma totale della creazione, l’essere superiore a tutti gli altri, la meta
                del progresso di innumerevoli ere di esistenza.</p>
            <p>Tutt’al più l’uomo trascorre una novantina d’anni in questo mondo, un breve tempo davvero.</p>
            <p>Ma cessa un uomo di esistere quando lascia il corpo? Se la vita giungesse a una fine, allora tutta la
                precedente evoluzione sarebbe inutile, tutto sarebbe stato fatto per niente! È possibile immaginare che
                la Creazione non abbia uno scopo più importante?</p>
            <p>L’anima è eterna, immortale.</p>
            <p>I materialisti chiedono: «Dov’è l’anima? Che cos’è? Non possiamo né vederla né toccarla».</p>
            <p>Ecco come dobbiamo rispondere. Per quanto grande sia il suo progresso, il minerale non ha il potere di
                comprendere il mondo vegetale. Ora, questa mancanza di comprensione non prova l’inesistenza della
                pianta.</p>
            <p>Per quanto alto sia il grado di evoluzione al quale può giungere, una pianta non può capire il mondo
                animale. Quest’ignoranza non vuol dire che l’animale non esista.</p>
            <p>L’animale, sia pure altissimo il suo grado di sviluppo, non può immaginare l’intelligenza dell’uomo, né
                può farsi un’idea della natura dell’anima umana. Ma, diciamo ancora, ciò non prova che l’uomo sia senza
                intelletto o senza anima. Tutti questi fatti dimostrano soltanto che una forma di creazione non ha il
                potere di comprendere una forma superiore a se stessa.</p>
            <p>Questo fiore può non aver nozione di un essere come l’uomo, ma la sua ignoranza non toglie che esista
                l’umanità.</p>
            <p>Allo stesso modo, se i materialisti non credono nell’esistenza dell’anima, la loro incredulità non prova
                affatto che non esista un regno come quello dello spirito. L’esistenza stessa dell’intelligenza umana è
                una prova della sua immortalità. Inoltre l’oscurità prova che esiste la luce, perché senza la luce non
                vi sarebbe l’ombra. La povertà dimostra che c’è la ricchezza, perché senza la ricchezza, come potremmo
                misurare la povertà? L’ignoranza è un segno che il sapere esiste, perché senza il sapere non vi potrebbe
                essere l’ignoranza.</p>
            <p>Dunque, l’idea della mortalità presuppone l’idea dell’immortalità, perché se non vi fosse la vita eterna
                non vi sarebbe modo di misurare la vita di questo mondo.</p>
            <p>Se lo spirito non fosse immortale, come potrebbero le Manifestazioni di Dio sopportare così dure
                prove?</p>
            <p>Perché Gesù Cristo soffrì l’orribile morte sulla croce?</p>
            <p>Perché Muḥammad sopportò tante persecuzioni?</p>
            <p>Perché il Báb fece il sacrificio supremo e perché Bahá’u’lláh passò la vita in carcere?</p>
            <p>Tanto soffrire non può non essere prova della vita eterna dello spirito.</p>
            <p>Cristo soffrì, accettò ogni tribolazione, perché il Suo spirito era immortale. Se un uomo riflette, può
                comprendere il significato spirituale della legge del progresso, come tutto si muove da un grado
                inferiore a uno superiore.</p>
            <p>Soltanto un uomo senza intelletto può, dopo aver considerato tutte queste cose, immaginare che il grande
                sistema della creazione cessi ad un tratto di progredire, che l’evoluzione giunga a una fine così
                inadeguata!</p>
            <p>I materialisti che ragionano in questo modo e pretendono che non ci è possibile vedere il mondo dello
                Spirito o percepire le benedizioni di Dio, sono davvero come gli animali che non hanno alcuna
                intelligenza. Hanno occhi e non vedono, hanno orecchi e non sentono. E questa mancanza di vista e di
                udito altro non prova che la loro inferiorità. Di essi si legge nel Corano: «Sono uomini ciechi e sordi
                per lo spirito». Non adoperano quel grande dono di Dio, il potere dell’intelletto, per il quale
                potrebbero vedere e udire con gli occhi e con gli orecchi dello spirito e comprendere con un cuore
                divinamente illuminato.</p>
            <p>L’incapacità della mente del materialista di afferrare l’idea della vita eterna non prova l’inesistenza
                di quella vita.</p>
            <p>La comprensione dell’altra vita dipende dalla nostra nascita spirituale.</p>
            <p>La mia preghiera per voi è che le vostre aspirazioni e le vostre facoltà spirituali aumentino ogni giorno
                e che non lasciate mai che i sensi materiali nascondano ai vostri occhi lo splendore della Luce
                celeste.</p>
        </Wrapper>
    </div>

)
