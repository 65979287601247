import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero7 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>7. IL SOLE DELLA VERITÀ
            <br/>22 ottobre 1911
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1384260514&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p>
                ‘Abdu’l-Bahá disse:<br/>
                Che bella giornata! Il sole brilla sulla terra dando luce e calore a tutte le creature. Anche il Sole
                della Verità risplende, dando luce e calore alle anime umane. Il sole dà vita al corpo fisico di tutte
                le creature sulla terra. Senza il suo calore la loro crescita si fermerebbe, il loro sviluppo si
                arresterebbe, esse declinerebbero e morrebbero. Ugualmente le anime umane hanno bisogno che il Sole
                della Verità effonda i suoi raggi su loro, per svilupparle, educarle e incoraggiarle. Il sole è per il
                corpo dell’uomo quello che il Sole della Verità è per la sua anima.
            </p>
            <p>
                Un uomo può essere giunto a un alto livello di progresso materiale, ma senza la luce della verità la sua
                anima è gracile e malnutrita. Un altro uomo può non avere beni materiali, può occupare l’ultimo gradino
                della scala sociale, ma avendo ricevuto il calore del Sole della Verità la sua anima è grande, la sua
                comprensione spirituale illuminata.
            </p>
            <p>
                Un filosofo greco che visse nei primi anni del Cristianesimo, essendo imbevuto di principi cristiani,
                pur non essendo cristiano professante, scrisse: «Credo che la religione sia la base della vera civiltà».
                E infatti se il carattere morale d’una nazione non è sviluppato quanto il suo cervello e i suoi talenti,
                la civiltà non ha una base sicura.
            </p>
            <p>
                Inculcando la moralità, la religione è la più autentica filosofia e su di essa si fonda l’unica civiltà
                duratura. Come esempio, il filosofo greco indicava i cristiani del tempo, la cui moralità era molto
                elevata. L’opinione di questo filosofo è conforme alla verità, perché la civiltà della Cristianità era
                la più alta e la più illuminata del mondo. I Precetti cristiani erano illuminati dal divino Sole della
                Verità, quindi ai seguaci di quella religione era stato insegnato di amare tutti gli uomini come
                fratelli, di non temere nulla, nemmeno la morte! Era stato insegnato di amare il prossimo come se stessi
                e di dimenticare i propri egoistici interessi nel lottare per il sommo bene dell’umanità. L’alto scopo
                della religione di Cristo fu di avvicinare i cuori dell’umanità alla fulgente Verità di Dio.
            </p>
            <p>
                Se i seguaci di nostro Signore Gesù Cristo avessero continuato a seguire con tutta fede questi principi,
                non vi sarebbe stato alcun bisogno di rinnovare il Messaggio cristiano, nessuna necessità di risvegliare
                il Suo popolo, perché adesso una civiltà gloriosa governerebbe il mondo e il Regno dei Cieli sarebbe già
                venuto sulla terra.
            </p>
            <p>
                Ma invece che cosa avvenne? L’umanità abbandonò i divini precetti illuminati del Maestro e l’inverno
                sopravvenne sui cuori degli uomini. Come la vita del corpo umano dipende dai raggi del sole, così le
                virtù celesti non possono crescere nell’anima senza lo splendore del Sole della Verità.
            </p>
            <p>
                Dio non lascia mai i Suoi figliuoli senza conforto, ma quando l’oscurità dell’inverno li avviluppa,
                ancora una volta Egli manda il Suo Messaggero, il Profeta, perché ritorni la divina primavera. Il Sole
                della Verità riappare sull’orizzonte del mondo, brillando sugli occhi dei dormienti, risvegliandoli ad
                ammirare la gloria di una nuova aurora. Allora, l’albero dell’umanità rifiorisce e produce i frutti
                della rettitudine per risanare le nazioni. È perché l’uomo non ha prestato ascolto alla Voce della
                Verità e ha chiuso gli occhi alla sacra Luce, trascurando la Legge di Dio, che una tenebra di guerra,
                tumulti, malessere e miseria ha portato desolazione sulla terra. Supplico il Signore che voi tutti
                cerchiate di condurre ogni Suo figlio verso la luce del Sole della Verità, affinché queste tenebre siano
                dissipate dai raggi penetranti della gloria sua e i rigori e il gelo dell’inverno siano annientati dal
                misericordioso calore del suo splendore.
            </p>
        </Wrapper>
    </div>

)
