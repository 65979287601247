import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero59 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>59. TAVOLA RIVELATA DA ‘ABDU’L-BAHÁ
            <br/>28 agosto 1913
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1420710970&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p>O figlia diletta!</p>
            <p>La tua eloquente, scorrevole lettera è stata letta in un giardino, nella fresca ombra di un
                albero, mentre spirava una brezza gentile. Strumenti di gioia fisica si offrivano agli occhi e la tua
                lettera è divenuta causa di godimento spirituale. In verità ti dico, non era una lettera ma un roseto
                adorno
                di giacinti e di fiori.Conteneva dolce fragranza di paradiso e lo zefiro del divino Amore spirava dalle
                sue
                rosate parole.Non avendo molto tempo a disposizione, darò qui una risposta breve, conclusiva e ampia. È
                come segue.</p>
            <p>In questa Rivelazione di Bahá’u’lláh, le donne procedono di pari passo con gli uomini.Non saranno
                lasciate indietro in nessun movimento. I loro diritti sono pari a quelli degli uomini. Avranno accesso a
                tutti i rami amministrativi della politica. Raggiungeranno in ogni cosa un grado tale da essere
                considerato
                lo stadio su-premo del mondo dell’umanità e prenderanno parte a tutti gli affari. Siate certe. Non
                guardate
                alle condizioni presenti. In un futuro non molto remoto massimo sarà lo splendore e totale la gloria del
                mondo delle donne, perché la Sacra Maestà di Bahá’u’lláh ha così voluto! Nel momento delle elezioni il
                voto
                è un inalienabile diritto delle donne e il loro ingresso in tutti i dipartimenti umani è un fatto
                irrefutabile e incontrovertibile. Nessuno potrà ritardarlo o impedirlo.</p>
            <p>Ma vi sono alcune questioni alle
                quali non merita che esse partecipino. Per esempio, quando la comunità prende energiche misure difensive
                contro un’aggressione nemica, le donne sono esonerate dagli impegni mi-litari. Può anche accadere che a
                un
                dato momento tribù bellicose e selvagge attacchino furiosamente lo stato con l’intenzione di massacrarne
                i
                membri. In queste circostanze è necessario difendersi,ma spetta agli uomini organizzarsi e prendere tali
                misure difensive non alle donne, perché esse hanno il cuore tenero e non possono sopportare la vista
                dell’orrore del sangue, anche se è sparso per motivi di difesa. Da tali e simili imprese le donne sono
                esonerate.</p>
            <p>Quanto alla formazione della Casa di Giustizia, Bahá’u’lláh Si rivolge agli uomini. Dice «O uomini
                della Casa di Giustizia!».</p>
            <p>Ma quando i suoi membri devono essere eletti, il diritto delle donne, quanto al
                voto e alla libera espressione, è indiscutibile. Quando esse perverranno al limite estremo del
                progresso,
                allora, conforme alle esigenze del tempo e del luogo e alle loro grandi capacità, le donne
                otterranno
                privilegi straordinari. Abbiate fiducia a tal proposito. La Sacra Maestà di Bahá’u’lláh ha molto
                rafforzato
                la causa delle donne e i diritti e i privilegi delle donne sono il più grande fra i principi di
                ‘Abdu’l-Bahá. Sii certa! Presto verranno giorni in cui gli uomini, rivolgendosi alle donne, diranno:
                <i>«Benedette voi! Benedette voi! In verità siete degne d’ogni dono. In verità meritate di cingervi il
                    capo con
                    la corona dell’eterna gloria, perché nelle scienze e nelle arti, nelle virtù e nelle perfezioni
                    diverrete
                    uguali agli uomini e quanto alla tenerezza del cuore e all’abbondanza della miseri-cordia e della
                    simpatia
                    li superate»</i>
            </p>
        </Wrapper>
    </div>

)
