import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero20 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>20. L’EVOLUZIONE DELLA MATERIA E LO SVILUPPO DELL’ANIMA
            <br/>3 novembre 1911
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1388415565&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p>A Parigi incomincia a far freddo, così freddo che presto sarò costretto ad andarmene, ma il vostro caldo
                amore mi trattiene ancora qui. Dio volendo, spero di stare con voi ancora un po’ tempo. Il freddo e il
                caldo del corpo non possono influenzare lo spirito, perché esso è riscaldato dal fuoco dell’Amore di
                Dio. Quando comprendiamo questo fatto, incominciamo a capire qualche cosa della nostra vita nel mondo
                avvenire.</p>
            <p>Dio, nella Sua Benevolenza, ce ne ha dato un’idea in questo mondo, ci ha fornito alcune prove della
                differenza che esiste fra il corpo, l’anima e lo spirito.</p>
            <p>Noi vediamo che il freddo, il caldo e le sofferenze riguardano soltanto il corpo e non hanno influenza
                sullo spirito.</p>
            <p>Quante volte vediamo un uomo povero, ammalato, miseramente vestito, senza mezzi di sussistenza, ma forte
                spiritualmente. Per quanto il suo corpo sia sofferente, il suo spirito è libero e sano! D’altra parte
                quante volte vediamo un uomo ricco, fisicamente forte e sano, ma con un’anima ammalata al punto di
                morire.</p>
            <p>Appare assolutamente chiaro ad ogni mente aperta che lo spirito dell’uomo è una cosa totalmente diversa
                dal suo corpo fisico.</p>
            <p>Lo spirito è immutabile e indistruttibile. Il progresso e lo sviluppo dell’anima, la gioia e il dolore
                dell’anima sono indipendenti dal corpo fisico.</p>
            <p>Se un amico ci procura gioia o dolore, o se un amore si rivela vero o falso, è l’anima che ne è
                impressionata. Se i nostri cari sono lontani, è l’anima che soffre e il dolore dell’anima può reagire
                sul corpo.</p>
            <p>Così, quando lo spirito è alimentato dalle sacre virtù, il corpo è felice. Se l’anima cade nel peccato,
                il corpo è in angoscia!</p>
            <p>Quando troviamo verità, costanza, fedeltà e amore siamo felici. Ma quando incontriamo menzogna, perfidia
                e inganno, siamo infelici.</p>
            <p>Queste sono tutte sensazioni dell’anima e non malattie del corpo. Così è chiaro che l’anima, come il
                corpo, ha una propria individualità. E se il corpo subisce un cambiamento, lo spirito non ne è
                necessariamente toccato. Quando si rompe uno specchio sul quale il sole brilla, lo specchio è rotto ma
                il sole continua a brillare. Se la gabbia in cui si trova un uccello si rompe, l’uccello non ne è
                danneggiato. Se un lume si rompe, la fiamma può continuare a brillare.</p>
            <p>Gli stessi esempi valgono per lo spirito dell’uomo. Benché la morte distrugga il suo corpo, essa non ha
                alcun potere sul suo spirito. Lo spirito è eterno, non nasce e non muore.</p>
            <p>Quanto all’anima dell’uomo dopo la morte, essa rimane nel grado di purezza raggiunto durante la vita del
                corpo fisico e, liberata dal corpo, essa resta immersa nell’oceano della Misericordia di Dio.</p>
            <p>Dal momento in cui l’anima lascia il corpo e arriva nel mondo celeste, la sua evoluzione è spirituale e
                questa evoluzione è l’avvicinamento a Dio.</p>
            <p>Nella creazione fisica, l’evoluzione avviene da un grado all’altro di perfezione. Il minerale passa con
                le sue perfezioni minerali nel vegetale. Il vegetale, con le sue perfezioni, passa al regno animale e
                così di seguito fino al regno umano. Questo mondo è pieno di evidenti contraddizioni. In ognuno di
                questi regni (minerale, vegetale e animale) la vita esiste in un proprio grado. Benché in confronto alla
                vita dell’uomo, la terra sembri morta, anche essa vive e ha una vita propria. In questo mondo le cose
                vivono e muoiono e vivono poi di nuovo in altre forme di vita, ma nel mondo dello spirito accade le cose
                sono molto diverse.</p>
            <p>L’anima non si evolve di grado in grado, secondo una legge. Si evolve avvicinandosi a Dio, per la
                Misericordia e la Benevolenza di Dio.</p>
            <p>La mia fervida preghiera è che noi possiamo tutti essere nel Regno di Dio e vicini a Lui.</p>
        </Wrapper>
    </div>

)
