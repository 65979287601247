import React, {MouseEvent, useState } from "react";
import {Carousel} from "react-bootstrap"
import "react-console-log";
import styled from "styled-components";
import { times } from "./Times";


const CarouselStyle = styled.div`
  .carousel-caption {
    top: 0;
    bottom: auto;
  }

  .carousel-item {
    //style: {{'height':{$imageHeight} 'width':{$imageWidth}
  }

`;

interface SlideShowProps {
    images: string[];
    quotes: string[];
    times: number[];
}

export const SlideShow: React.FC<SlideShowProps> = ({images, quotes}) => {
    let $image_width = "100%";
    let $image_height = "auto";

    let carousel_items = images.map(function (image, i) {

        const handleImageMouseEvent = (e: MouseEvent<HTMLImageElement>) => {
            console.log("DEFAULT");
            e.preventDefault();

        }
        return (
            <Carousel.Item key={(i % quotes.length).toString()}  interval = {times[i % quotes.length]}>
                <img src={process.env.PUBLIC_URL + image} alt={image} width={$image_width}
                     height={$image_height} onContextMenu={handleImageMouseEvent} contextMenu={"myMenu"}/>
                <Carousel.Caption>
                    <h2>{process.env.PUBLIC_URL + quotes[i % quotes.length]}</h2>
                </Carousel.Caption>
            </Carousel.Item>
        );
    });

    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex: number, e: Record<string, unknown> | null) => {
        setIndex(selectedIndex);
        console.log("The index is "+selectedIndex);
    };

    return (
        <div>
            {/*<audio src={ "http://www.sousound.com/music/healing/healing_01.mp3"} loop autoPlay />*/}
            <CarouselStyle>
                <Carousel activeIndex={index} onSelect={handleSelect} interval={2000} pause={false} fade={true}>
                    {carousel_items}
                </Carousel>
            </CarouselStyle>

        </div>
    )
}


