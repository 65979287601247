import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero5 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>5. DIO COMPRENDE IN SÉ TUTTO, EGLI NON PUÒ ESSERE COMPRESO
            <br/>Venerdì sera, 20 ottobre 1911
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1384249765&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            ‘Abdu’l-Bahá disse:
            <p>
                Molte conferenze si tengono ogni giorno a Parigi per diversi scopi, per discutere di politica, di
                commercio, di educazione, di arte, di scienza e di altre questioni.
            </p>
            <p>
                Tutte queste conferenze vanno bene. Ma questa assemblea si è incontrata per rivolgere il viso verso Dio
                per imparare come meglio si possa lavorare per il bene dell’umanità, per cercare di abolire i pregiudizi
                e di seminare nel cuore umano i semi dell’amore e della fratellanza universale.
            </p>
            <p>
                Dio accetta il motivo dell’incontro e ci dà la Sua benedizione.
            </p>
            <p>
                Nel Vecchio Testamento leggiamo che Dio disse: «Facciamo l’uomo a nostra immagine, secondo la nostra
                somiglianza». Nel Vangelo, Gesù disse: «Credetemi ch’io son nel Padre, e che il Padre è in me »
                (Giovanni XVI, 11.). Nel Corano Dio dice: «L’uomo è il mistero Mio e Io sono il mistero suo».
                Bahá’u’lláh scrive che Dio dice: «Il tuo cuore è la Mia dimora; santificalo per la Mia discesa. Il tuo
                spirito è il luogo della Mia rivelazione, mondalo per la Mia manifestazione».
            </p>
            <p>
                Tutte queste parole sacre ci dimostrano che l’uomo è fatto a immagine di Dio. Eppure l’Essenza di Dio è
                incomprensibile per la mente umana, perché la comprensione finita non si può applicare a questo Mistero
                infinito. Dio contiene tutto. Egli non può essere contenuto. Ciò che contiene è superiore a ciò che è
                contenuto. L’intero è superiore alla parte.
            </p>
            <p>
                L’uomo può comprendere soltanto le cose che non trascendono la sua intelligenza. Perciò è impossibile
                che il cuore dell’uomo comprenda la natura della Maestà di Dio. La nostra immaginazione può figurarsi
                soltanto ciò che è capace di creare.
            </p>
            <p>
                Il potere della comprensione differisce per grado nei vari regni del creato. I regni minerale, vegetale
                e animale non possono concepire creazione alcuna all’infuori della propria. Il minerale non può
                immaginare la capacità di crescere della pianta. L’albero non può capire come l’animale possa muoversi,
                né che cosa significhi avere la vista, l’udito e l’olfatto. Tutto ciò appartiene alla creazione fisica.
            </p>
            <p>
                Anche l’uomo fa parte della creazione fisica, ma nessuno dei regni inferiori può comprendere ciò che
                avviene nella mente dell’uomo. L’animale non può farsi un’idea dell’intelligenza di un essere umano.
                Esso conosce solamente ciò che può percepire con i propri sensi animali, non può immaginare qualche cosa
                in astratto. Un animale non potrebbe comprendere che il mondo è rotondo, che la terra gira attorno al
                sole, né potrebbe concepire il telegrafo. Solo l’uomo è capace di comprendere certe cose. L’uomo è
                l’opera più nobile della creazione, la creatura più vicina a Dio.
            </p>
            <p>
                Tutti i regni superiori sono incomprensibili a quelli inferiori. Perciò, com’è possibile che la creatura
                uomo comprenda l’onnipotente Creatore di tutte le cose?
            </p>
            <p>
                Ciò che immaginiamo non è la realtà di Dio. Egli, l’Inconoscibile, l’Inconcepibile, è di gran lunga
                superiore a quel che di più eccelso l’uomo possa immaginare.
            </p>
            <p>
                Tutte le creature che esistono dipendono dalla Munificenza divina. La Misericordia divina dà la vita.
                Come la luce del sole illumina tutto il mondo, così la Misericordia infinita di Dio si riversa su tutte
                le creature. Come il sole matura i frutti sulla terra e dà vita e calore a tutti gli esseri, così il
                Sole della Verità brilla su tutte le anime e le riempie del fuoco dell’amore divino e della divina
                comprensione.
            </p>
            <p>
                La superiorità dell’uomo sul resto del mondo creato si vede anche in questo, che l’uomo ha un’anima
                nella quale dimora lo spirito divino. L’anima delle creature inferiori è, nella sua essenza, inferiore a
                quella dell’uomo.
            </p>
            <p>
                Non c’è dubbio dunque che di tutti gli esseri creati l’uomo sia il più vicino alla natura di Dio e che
                perciò riceva un dono maggiore dalla Munificenza divina.
            </p>
            <p>
                Il regno minerale ha il potere dell’esistenza. La pianta ha il potere dell’esistenza e della crescita.
                L’animale, oltre a esistere e crescere, ha la capacità di muoversi e di adoperare le facoltà dei sensi.
                Nel regno umano troviamo tutti gli attributi dei regni inferiori e molto di più. Nell’uomo si compendia
                ogni precedente creazione, perché egli le contiene tutte.
            </p>
            <p>
                All’uomo è dato il dono speciale dell’intelletto, grazie al quale egli può ricevere una parte maggiore
                della luce divina. L’Uomo Perfetto è come uno specchio terso che riflette il Sole della Verità e che
                manifesta gli attributi di Dio.
            </p>
            <p>
                Gesù Cristo disse: «Chi mi ha veduto, ha veduto il Padre» (Matteo XI, 27, Giovanni VIII, 19.), Dio
                manifestato nell’uomo.
            </p>
            <p>
                Il Sole non abbandona il suo posto nel cielo e non discende nello specchio, perché le azioni di salire e
                scendere, di venire e andare non appartengono all’Infinito, sono metodi degli esseri finiti. Nella
                Manifestazione di Dio, lo Specchio perfettamente levigato, appaiono le qualità del Divino in una forma
                che l’uomo è capace di comprendere.
            </p>
            <p>
                Questo concetto è così semplice che tutti possono capirlo e quello che si può capire si deve per forza
                accettare.
            </p>
            <p>
                Il Padre nostro non ci rimprovererà se non accettiamo i dogmi, che non possiamo né credere né capire,
                perché Egli è sempre infinitamente giusto verso i Suoi figli.
            </p>
            <p>
                Ma questo esempio è così logico che può essere afferrato da qualunque mente voglia prenderlo in
                considerazione.
            </p>
            <p>
                Possa ognuno di voi diventare un lume risplendente, la cui fiamma sia l’Amore di Dio. Possano i vostri
                cuori effondere la radiosità dell’unità. Siano i vostri occhi illuminati dallo splendore del Sole della
                Verità!
            </p>
            <p>
                Parigi è una bellissima città e sarebbe impossibile trovare nel mondo una città più civile e più
                avanzata nel progresso materiale. Ma da molto tempo la luce spirituale non vi risplende. Il suo sviluppo
                spirituale è molto inferiore alla sua civiltà materiale. Ci vuole un potere supremo per risvegliarla
                alla realtà della verità spirituale, per ispirare nell’anima sua insonnolita l’alito della vita. Dovete
                unirvi tutti in questa opera, svegliarla e rianimare le sue genti con l’aiuto di quella Forza superiore.
            </p>
            <p>
                Quando un male non è grave, un rimedio semplice basta a guarirlo, ma quando la lieve malattia diventa
                una grave infermità, bisogna allora che il Medico divino somministri un rimedio molto forte. Vi sono
                alberi che fioriscono e danno frutti in un clima fresco, altri ve ne sono che hanno bisogno dei raggi
                caldi del sole per portare alla maturità i loro frutti. Parigi è una di quelle piante per il cui
                sviluppo spirituale occorre una grande fiamma del Sole della divina Potenza di Dio.
            </p>
            <p>
                Chiedo a voi tutti, a ciascuno di voi, di seguire la luce della verità nei sacri Insegnamenti e Dio vi
                darà forza con il Suo Spirito Santo, perché possiate superare tutti gli ostacoli e distruggere i
                pregiudizi che producono odio e separazione fra i popoli. Lasciate che i vostri cuori si riempiano del
                grande amore di Dio, fate che tutti lo sentano. Ogni uomo è un servo di Dio e tutti hanno diritto alla
                Munificenza divina.
            </p>
            <p>
                Specialmente a coloro che hanno pensieri materiali e bassi, dimostrate massimo amore e pazienza
                infinita. La vostra bontà illuminata li porterà all’unità dell’amicizia.
            </p>
            <p>
                Se sarete fedeli alla vostra grande opera, seguendo il santo Sole della Verità senza deviare, il giorno
                benedetto della fratellanza universale spunterà su questa bella città.
            </p>
        </Wrapper>
    </div>

)
