import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero8 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>8. LA LUCE DELLA VERITÀ BRILLA ORA IN ORIENTE E IN OCCIDENTE
            <br/>23 ottobre 1911
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1384261792&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p>
                Quando un uomo ha trovato in un luogo la gioia della vita, vi ritorna per essere ancora più felice.
                Quando si è trovato l’oro in una miniera, vi si ritorna per estrarne dell’altro.
            </p>
            <p>
                Questa tendenza dimostra la forza interiore e l’istinto naturale che Dio ha dato all’uomo e il potere
                dell’energia vitale innato in lui.
            </p>
            <p>
                L’Occidente ha sempre ricevuto la luce spirituale dall’Oriente. La Melodia del Regno celeste si sente
                prima in Oriente, ma in Occidente il volume del suono erompe più alto sulle orecchie che ascoltano.
            </p>
            <p>
                Nostro Signore Gesù Cristo Si levò come una Stella brillante nel cielo d’Oriente, ma la luce dei Suoi
                Insegnamenti brillò più viva in Occidente, dove l’influenza Sua mise radici più profonde e dove la Sua
                causa si propagò più che nel paese dove Egli nacque.
            </p>
            <p>
                Il suono della Melodia di Cristo ha echeggiato in tutte le terre del il mondo occidentale ed è penetrato
                nei cuori del Suo popolo.
            </p>
            <p>
                I popoli occidentali sono costanti e le basi su cui costruiscono sono di roccia. Essi sono saldi e non
                dimenticano facilmente.
            </p>
            <p>
                L’Occidente è come una pianta forte e robusta. Quando la pioggia cade dolcemente per nutrirla e il sole
                vi risplende, allora, a suo tempo, esso fiorisce e porta buoni frutti. È passato molto tempo da quando
                la luce del Sole della Verità, rispecchiata da nostro Signore Gesù Cristo, ha irradiato l’Occidente. Il
                Volto di Dio è stato velato dai peccati e dalla negligenza dell’umanità. Ma, oggi, grazie a Dio, lo
                Spirito Santo parla di nuovo al mondo! Le costellazioni dell’amore, della saggezza e del potere ancora
                una volta risplendono dall’Orizzonte divino per dare gioia a tutti coloro che volgono il viso verso la
                Luce di Dio. Bahá’u’lláh ha lacerato il velo del pregiudizio e della superstizione che soffocava le
                anime degli uomini. Preghiamo Dio perché l’alito dello Spirito Santo restituisca speranza e vigore al
                popolo, svegliando negli uomini il desiderio di fare la Volontà di Dio. Possano i cuori e le anime
                essere vivificati e rallegrarsi della loro rinascita.
            </p>
            <p>
                L’umanità, allora, indosserà una nuova veste nello splendore dell’amore di Dio e sorgerà l’alba d’una
                nuova creazione! Allora la Misericordia del Misericordioso si riverserà su tutto il genere umano e
                ognuno si risveglierà a una vita nuova.
            </p>
            <p>
                È mio ardente desiderio che tutti voi cerchiate di lavorare per questo fine glorioso, che siate fedeli e
                amorevoli lavoratori nella costruzione di questa nuova civiltà spirituale, che siate gli eletti di Dio
                per porre ad effetto con volenterosa e gioiosa obbedienza i Suoi supremi disegni! Il successo è in
                verità vicino, perché lo Stendardo della Divinità è stato issato e il Sole della Rettitudine divina è
                apparso sull’orizzonte alla vista di tutti gli uomini!
            </p>
        </Wrapper>
    </div>

)
