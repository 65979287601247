import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero25 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>25. BAHÁ’U’LLÁH
            <br/>7 novembre 1911
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1388418880&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p>‘Abdu’l-Bahá disse:
                <br/>Oggi vi parlerò di Bahá’u’lláh. Tre anni dopo che il Báb ebbe dichiarato la Sua missione,
                Bahá’u’lláh, accusato dai fanatici mullá (Mullá, sacerdoti musulmani) , di credere alla nuova dottrina,
                fu arrestato e messo in carcere. L’indomani, tuttavia, alcuni ministri del Governo e altri uomini
                influenti Lo fecero mettere in libertà. In seguito fu nuovamente arrestato e i preti Lo condannarono a
                morte. Il governatore esitò a far eseguire questa sentenza per paura d’una rivoluzione. I preti si
                adunarono nella moschea, dinanzi alla quale si trovava il luogo delle esecuzioni. Il popolo della città
                si affollò attorno alla moschea. I falegnami portarono martelli e seghe, i macellai vennero con i loro
                coltelli, i muratori coi loro picconi. Tutti questi uomini, incitati dai frenetici mullá, erano ansiosi
                di dividersi l’onore di uccidere Bahá’u’lláh. Nell’interno della moschea erano riuniti i dottori della
                religione. Bahá’u’lláh, dritto davanti a loro, rispose alle loro domande con grande saggezza. Il capo
                dei sapienti, in ispecie, fu ridotto al silenzio da Bahá’u’lláh, che confutò tutti i suoi argomenti.</p>
            <p>Sorse una discussione fra due di quei sacerdoti sul significato di alcune parole contenute negli scritti
                del Báb. Accusandolo di inesattezza, essi sfidarono Bahá’u’lláh a difenderlo se ne era capace. E i
                sacerdoti furono totalmente umiliati, perché Bahá’u’lláh provò davanti all’assemblea che il Báb aveva
                assolutamente ragione e che l’accusa era frutto di ignoranza.</p>
            <p>Gli sconfitti decisero di sottoporLo alla tortura della fustigazione e più infuriati di prima, Lo
                portarono fuori della moschea sul luogo del supplizio dove una folla traviata l’aspettava.</p>
            <p>Il governatore non osò cedere alla richiesta dei sacerdoti di giustiziarLo. Vedendo in che pericolo Si
                trovava l’insigne prigioniero, furono mandati alcuni uomini a salvarLo. Essi riuscirono nell’intento,
                facendo un foro nel muro della moschea e conducendo Bahá’u’lláh attraverso l’apertura in un luogo
                sicuro, ma non in libertà. Il governatore scaricò ogni responsabilità dalle proprie spalle, mandando
                Bahá’u’lláh a Teheran. Qui Egli fu messo in una prigione sotterranea, dove non si vedeva mai la luce del
                giorno. Gli fu posta attorno al collo una pesante catena, con la quale fu legato ad altri cinque seguaci
                del Báb. Le catene furono serrate con bulloni e dadi, forti e pesantissimi. I Suoi vestiti furono fatti
                a brandelli e così pure il Suo copricapo. In questa terribile condizione fu tenuto per quattro mesi.</p>
            <p>Durante questo periodo di tempo nessuno dei Suoi amici poté vederLo.</p>
            <p>Un funzionario addetto alla prigione tentò di avvelenarLo, ma il veleno non ebbe altro effetto che di
                causarGli acute sofferenze.</p>
            <p>Dopo qualche tempo il governo diede a Bahá’u’lláh la libertà, ma Lo mandò in esilio con la Sua famiglia,
                a Baghdad, dove Egli rimase per undici anni. Durante questo periodo Egli fu sottoposto a dure
                persecuzioni, essendo sempre circondato dal vigile odio dei Suoi nemici.</p>
            <p>Egli sopportò mali e tormenti con grande coraggio e fermezza. Spesso, quando Si alzava la mattina, non
                sapeva se avrebbe vissuto fino al tramonto. Nel frattempo ogni giorno i preti venivano a interrogarLo
                sulla religione e sulla metafisica.</p>
            <p>Finalmente il Governatore turco Lo esiliò a Costantinopoli, da qui fu inviato ad Adrianopoli dove rimase
                cinque anni. Poi fu mandato al lontanissimo carcere della fortezza di San Giovanni d’Acri. Rinchiuso
                nella fortezza, fu sottoposto alla più stretta sorveglianza. Non trovo parole atte a descrivervi le
                tante e tante tribolazioni alle quali fu sottoposto e tutte le sofferenze che ebbe a sopportare in quel
                carcere. Ma nonostante tutto, fu da quella prigione che Bahá’u’lláh scrisse a tutti i Sovrani d’Europa e
                queste lettere, eccetto una, furono mandate per posta.</p>
            <p>L’epistola allo Scià Náṣiri’d-Dín fu affidata a un bahá’í persiano, Mírzá Badí‘ Khurásání, che si impegnò
                a rimetterla direttamente nelle mani dello Scià. Questo coraggioso uomo attese nelle vicinanze di
                Teheran il passaggio dello Scià, che doveva appunto transitare da quelle parti per recarsi al suo
                palazzo d’estate. Il prode messaggero lo seguì fino al palazzo e aspettò sulla strada, presso l’entrata
                per diversi giorni. Rimase sempre lì ad aspettare sulla strada, nello stesso luogo. Infine la gente
                incominciò a domandarsi perché mai egli rimaneva là. Finalmente anche lo Scià sentì parlare di
                quell’uomo e ordinò ai suoi servi di condurlo dinanzi a lui.</p>
            <p>Badí‘ disse: «Servi dello Scià, ho una lettera, che devo rimettere nelle mani dello Scià in persona». E
                quando fu alla presenza di questi disse: «Vi porto una lettera da parte di Bahá’u’lláh».</p>
            <p>Badí‘ fu immediatamente catturato e interrogato da coloro che desideravano procurarsi pretesti per nuove
                persecuzioni contro Bahá’u’lláh. Badí‘ non rispose una sola parola. Allora lo torturarono, ma egli
                continuò a tacere. Dopo tre giorni, non essendo riusciti a farlo parlare, lo uccisero. Questi
                crudelissimi uomini lo fotografarono mentre subiva la tortura (Una persona ch’era presente quando fu
                detto a Badí‘ di portare l’epistola allo Scià, lo vide trasfigurarsi, egli divenne tutto raggiante).</p>
            <p>Lo Scià dette la lettera di Bahá’u’lláh ai sacerdoti, perché gliela spiegassero. Dopo qualche giorno essi
                dissero allo Scià che la lettera veniva da un nemico politico. Lo Scià si indignò e disse: «Questa non è
                una spiegazione. Io vi pago perché leggiate le mie lettere e rispondiate, quindi obbedite!».</p>
            <p>Il contenuto e lo spirito della lettera diretta allo Scià Násiri’d-Dín era, in breve, il seguente: «Ora
                che il tempo è venuto in cui la Causa della Gloria di Dio è apparsa, chiedo che Mi sia permesso di
                venire a Teheran per rispondere a qualunque questione i sacerdoti vogliano proporMi.</p>
            <p>«Vi esorto ad innalzarvi al disopra della magnificenza mondana del vostro impero. Ricordatevi di tutti i
                grandi re che vissero prima di voi, la loro gloria è svanita!».</p>
            <p>La lettera era scritta in un bellissimo stile, dava altri consigli al monarca e gli parlava del futuro
                trionfo del Regno di Bahá’u’lláh, tanto in Oriente quanto in Occidente.</p>
            <p>Lo Scià non prese in considerazione i moniti contenuti in quella lettera e continuò nello stesso modo
                sino alla fine.</p>
            <p>Benché Bahá’u’lláh fosse in carcere, il grande Potere dello Spirito Santo fu sempre con Lui.</p>
            <p>Nessun altro prigioniero avrebbe potuto comportarsi come Lui. Nonostante tutte le sofferenze che ebbe a
                sopportare, non Si lamentò mai.</p>
            <p>Con maestosa dignità, Egli rifiutò sempre di vedere il Governatore e le autorità della città.</p>
            <p>Sebbene fosse sotto stretta sorveglianza, pure andò e venne come volle. Morì in una casa situata a circa
                tre chilometri da San Giovanni d’Acri.</p>
        </Wrapper>
    </div>

)
