import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero37 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>37. LA CRUDELE INDIFFERENZA DELLA GENTE VERSO LE SOFFERENZE
            DEI POPOLI STRANIERI
            <br/>24 novembre 1911
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1410098473&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p>‘Abdu’l-Bahá disse:
                <br/>Mi è stato ora detto che in questo paese è accaduta una terribile disgrazia. Un treno è precipitato
                in un fiume e non meno di venti persone sono rimaste uccise. Oggi questo sinistro sarà oggetto di una
                discussione al Parlamento francese e il direttore delle Ferrovie dello Stato sarà invitato a dare
                spiegazioni. Egli sarà interrogato sulle condizioni della ferrovia e sulle cause della disgrazia e vi
                sarà un’animata discussione. Rimango sorpreso e meravigliato nel vedere quale interesse e quale emozione
                ha sollevato in tutto il paese la morte di venti persone, mentre tutti rimangono freddi e indifferenti
                di fronte al fatto che migliaia di italiani, turchi e arabi sono ammazzati a Tripoli! L’orrore di questo
                immenso massacro non ha affatto disturbato il Governo! Eppure anche quei disgraziati sono esseri umani!
            </p>
            <p>Perché tanta premura per questi venti individui e nessun interessamento per cinquemila o più persone?
                Sono anche loro uomini, appartengono tutti alla famiglia umana, ma sono di altri paesi, di altre razze!
                Non importa alle nazioni estranee al conflitto se questi uomini sono fatti a pezzi, quest’orribile
                massacro non le commuove! Come è ingiusto tutto questo, com’è crudele, com’è profondamente alieno da
                ogni sentimento di bontà e di sincerità! Anche le genti di questi altri paesi hanno figli, spose, madri
                e padri. In questi paesi oggi quasi non v’è casa dove non risuoni l’eco di amari pianti, non vi è
                focolare che la crudele mano della guerra non abbia colpito.</p>
            <p>Ahimè! Vediamo dappertutto che l’uomo è crudele, ingiusto, pieno di pregiudizi e lento a credere in Dio e
                a seguire i Suoi comandamenti.</p>
            <p>Se gli uomini si amassero e si aiutassero invece di essere così bramosi di distruggersi l’un l’altro a
                colpi di spada e di cannone, quanto più nobili sarebbero! Se vivessero come uno stormo di colombi in
                pace e in armonia invece di essere come lupi e sbranarsi reciprocamente, quanto meglio sarebbe!</p>
            <p>Perché l’uomo è così duro di cuore? Perché non conosce ancora Dio. Se conoscesse Dio non potrebbe agire
                contro le Sue leggi. Se avesse una mente spirituale non potrebbe seguire questa linea di condotta. Se
                avesse creduto, capito e seguito le leggi e i precetti dei profeti di Dio, le guerre non oscurerebbero
                la faccia della terra. Se possedesse appena gli elementi della giustizia, un tale stato di cose sarebbe
                impossibile. Perciò, io vi dico, pregate, pregate e volgete gli occhi a Dio, affinché Egli, nella Sua
                misericordia e nella Sua compassione infinita, aiuti e soccorra questi traviati. Pregate che dia loro la
                comprensione spirituale, che insegni loro la tolleranza e la misericordia, che apra gli occhi delle loro
                menti e che li doti del dono dello Spirito. Allora la pace e l’amore percorrerebbero concordi la terra e
                questa povera gente infelice potrebbe aver riposo.</p>
            <p>Lavoriamo notte e giorno per portare nel mondo condizioni migliori. Davanti allo spettacolo di tanta
                miseria il mio cuore si spezza e grida. Possa questo pianto toccare altri cuori!</p>
            <p>Allora i ciechi vedranno, i morti risorgeranno e la giustizia verrà a regnare sulla terra.</p>
            <p>Vi esorto a pregare con tutto il cuore e con tutta l’anima che ciò possa avverarsi.</p>

        </Wrapper>
    </div>

)
