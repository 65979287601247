import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero39 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>39. DISCORSO PRONUNCIATO DA ‘ABDU’L-BAHÁ NELLA CHIESA DEL PASTORE WAGNER «FOYER DE L’ÂME» A PARIGI
            <br/>26 novembre 1911
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1410099982&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p>Sono profondamente toccato dalle parole gentili che mi sono state rivolte e spero che l’affetto e il vero
                amore fra noi aumentino di giorno in giorno. Dio ha voluto che l’amore fosse una forza vitale nel mondo
                e voi tutti sapete quanto io sia lieto di parlare dell’amore.</p>
            <p>In ogni tempo Dio ha mandato i Suoi profeti al mondo per servire la causa della verità. Mosè portò la
                legge della verità e tutti i profeti d’Israele dopo di Lui cercarono di diffonderla.</p>
            <p>Venne Gesù e accese la torcia ardente della verità e la levò in alto perché il mondo intero ne fosse
                illuminato. Dopo di Lui vennero i suoi Apostoli eletti ed essi andarono dappertutto, portando la luce
                degli insegnamenti del loro Maestro nel mondo buio. Poi anche loro passarono a vita eterna.</p>
            <p>Dopo venne Muḥammad, che, al Suo tempo e nella Sua maniera, diffuse la conoscenza della verità fra un
                popolo selvaggio. Questa è sempre stata la missione degli eletti di Dio.</p>
            <p>Così, quando infine Bahá’u’lláh sorse in Persia, il Suo desiderio più ardente fu di riaccendere in tutto
                il mondo la luce declinante della verità. Tutti i santi di Dio hanno fatto ogni sforzo per diffondere la
                luce dell’amore e dell’unità nel mondo, affinché sparissero le tenebre della materialità e risplendesse
                sui figli degli uomini la luce dello spirito, affinché sparissero l’odio, la calunnia e l’assassinio e
                venisse invece il regno dell’amore, dell’unità e della pace.</p>
            <p>Tutte le Manifestazioni di Dio vennero con lo stesso scopo, tutte hanno voluto condurre l’uomo sulle vie
                della virtù. Eppure noi, loro servi, disputiamo ancora fra noi! Perché lo facciamo? Perché non ci amiamo
                l’un l’altro e non viviamo in unità?</p>
            <p>È così perché abbiamo chiuso gli occhi ai principi fondamentali di tutte le religioni, e cioè che Dio è
                uno, che è il Padre di tutti noi, che siamo tutti immersi nell’oceano della Sua misericordia e riparati
                e protetti dalla Sua amorevole sollecitudine.</p>
            <p>Il Sole glorioso della Verità brilla per tutti ugualmente. Le acque della Misericordia divina sommergono
                tutti e le Sue grazie divine sono dispensate a tutti i Suoi figli.</p>
            <p>Questo Dio amoroso desidera la pace per tutte le Sue creature. Perché dunque esse passano i loro giorni
                in guerra?</p>
            <p>Egli ama e protegge tutti i figli Suoi, perché essi Lo dimenticano?</p>
            <p>Egli prodiga a noi tutti le Sue cure paterne, perché trascuriamo i nostri fratelli?</p>
            <p>Senza dubbio, se pensassimo quanto Iddio ci ama e ci cura, cercheremmo di ordinare le nostre vite in modo
                da divenire il più possibile simili a Lui.</p>
            <p>Dio ci ha creati tutti, perché agiamo contro i Suoi desideri, mentre siamo tutti Suoi figli e amiamo lo
                stesso Padre? Tutte le divisioni che vediamo dappertutto, tutte le contese e le liti sono prodotte dal
                fatto che l’uomo si attacca ai riti e alle osservanze esteriori, dimenticando la semplice, fondamentale
                verità. Sono le pratiche esteriori della religione che sono così diverse e sono loro che causano dispute
                e inimicizie, mentre la realtà è sempre la stessa e unica. La Realtà è la Verità e la verità non ha
                divisioni. La Realtà è la guida di Dio, è la luce del mondo, è l’amore, è la misericordia. Questi
                attributi della verità sono anche virtù umane inspirate dallo Spirito Santo.</p>
            <p>Perciò teniamoci tutti stretti alla verità e saremo davvero liberi!</p>
            <p>Verrà il giorno in cui tutte le religioni del mondo si uniranno, perché in principio sono già una sola.
                Non occorrono divisioni, visto che soltanto le forme esteriori le separano. Tra i figli degli uomini
                troviamo alcune anime che soffrono per l’ignoranza. Affrettiamoci a istruirle. Altre sono come fanciulli
                bisognevoli di cure e di educazione finché non crescono. Altre ancora sono ammalate, a queste dobbiamo
                portare la guarigione divina.</p>
            <p>Siano esse ignoranti, o puerili, o ammalate, bisogna aiutarle e amarle e non disprezzarle a causa della
                loro imperfezione.</p>
            <p>I dottori della religione servono per portare la guarigione spirituale ai popoli ed essere causa di unità
                fra le nazioni. Se invece dovessero diventare causa di divisione, meglio sarebbe che non esistessero! Un
                rimedio si usa per curare un male, ma se invece non fa che aggravarlo, è meglio farne a meno. Se la
                religione dovesse esser soltanto una causa di discordia, sarebbe meglio non esistesse.</p>
            <p>Tutte le divine Manifestazioni mandate da Dio al mondo hanno affrontato terribili tribolazioni e
                sofferenze per la sola speranza di diffondere la Verità, l’unità e la concordia fra gli uomini. Cristo
                sopportò una vita di dolore, pena e sofferenza, per dare al mondo un perfetto esempio di amore e ciò
                malgrado noi continuiamo ad agire in spirito di avversione l’uno verso l’altro!</p>
            <p>Il fine di Dio per l’uomo ha come base fondamentale l’amore ed Egli ci ha comandato di amarci l’un
                l’altro come Egli ci ama. Tutte queste contese e queste dispute che sentiamo da ogni parte hanno il solo
                effetto di dare incremento alla materialità.</p>
            <p>Il mondo è per la maggior parte immerso nel materialismo e le grazie dello Spirito Santo sono ignorate.
                Vi è pochissimo sentimento spirituale vero e il progresso del mondo è per lo più puramente materiale.
                Gli uomini stanno diventando come gli animali che periscono, perché, come vediamo, non hanno alcun
                sentimento spirituale, non si rivolgono a Dio, non hanno religione! Questi sentimenti appartengono
                all’uomo soltanto e, quando ne è privo, l’uomo è prigioniero della natura e per nulla superiore al
                semplice animale.</p>
            <p>Come può l’uomo accontentarsi di condurre un’esistenza da animale, quando Dio ha fatto di lui una
                creatura cosi elevata? Tutta la creazione è soggetta alle leggi della natura, ma l’uomo è stato capace
                di vincere queste leggi. Il sole, con tutto il suo potere e il suo splendore, è assoggettato alle leggi
                della natura e non può cambiare corso nemmeno per lo spessore di un capello. Il grande e potente oceano
                non può modificare il flusso e il riflusso della marea. Nessuno può opporsi alle leggi della natura
                all’infuori dell’uomo!</p>
            <p>Dio ha dato all’uomo un potere così meraviglioso che egli può guidare, controllare e sottomettere la
                natura.</p>
            <p>Secondo la legge naturale l’uomo dovrebbe camminare sulla terra, ma egli costruisce bastimenti e vola
                nell’aria. Egli è creato per vivere sulla terra ferma, ma invece può viaggiare sul mare e anche
                sott’acqua.</p>
            <p>L’uomo ha imparato a dominare la potenza dell’elettricità. La prende a sua volontà e l’imprigiona in una
                lampada! La voce umana è fatta per parlare a breve distanza. Ma l’uomo ha saputo fare strumenti che gli
                permettono di parlare dall’Oriente all’Occidente. Tutti questi esempi vi fanno vedere che l’uomo può
                dominare la natura e che egli può, per così dire, strappare la spada dalla mano della natura e usarla
                contro di essa. Visto che l’uomo è stato creato padrone della natura, quanto è stolto a divenirne
                schiavo! Che ignoranza e che stupidità è adorare la natura, quando Dio, nella Sua bontà, ce ne ha creato
                padroni! Il potere di Dio è visibile a tutti, eppure gli uomini chiudono gli occhi e non lo vedono. Il
                Sole della Verità brilla in tutto il Suo splendore, ma l’uomo con gli occhi chiusi non può vederne la
                gloria! Io prego fervidamente Dio affinché nella Sua Misericordia e nella Sua amorosa Bontà voglia farvi
                vivere uniti e pieni di gloria infinita.</p>
            <p>Supplico ognuno di voi di unire le vostre preghiere alle mie affinché la guerra e lo spargimento di
                sangue cessino e l’amore, l’amicizia, la pace e l’unità regnino nel mondo.</p>
            <p>Vediamo che nei secoli il sangue ha macchiato la superficie della terra. Ma ora è venuto un raggio di una
                luce più intensa, l’intelligenza umana è maggiore, la spiritualità incomincia a crescere e certamente
                verrà il tempo in cui le religioni del mondo saranno in pace. Lasciamo i discordanti argomenti sulle
                forme esteriori e uniamoci per fare avanzare la divina Causa dell’Unità, finché tutta l’umanità non
                riconosca di essere una sola famiglia, unita nell’amore.</p>

        </Wrapper>
    </div>

)
