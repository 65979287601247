import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero11 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>11. IL PIÙ GRAN DONO CHE DIO HA FATTO ALL’UOMO
            <br/>Giovedì, 26 ottobre 1911
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1386990271&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p>Il dono più grande che l’uomo ha ricevuto da Dio è quello dell’intelletto o della comprensione.</p>
            <p>L’intelligenza è il potere per il quale l’uomo acquisisce la conoscenza dei vari regni del creato, dei
                vari gradi dell’esistenza e di molte cose che non sono visibili.</p>
            <p>In virtù di questo dono l’uomo riassume in sé tutte le creazioni che lo precedono. Egli può entrare in
                contatto con quei regni e grazie a questo dono può spesso, con la sua conoscenza scientifica, guardare
                avanti con visione profetica.</p>
            <p>L’intelletto in verità è il più prezioso dono fatto all’uomo dalla divina Provvidenza. L’uomo solo, fra
                tutte le cose create, ha questo meraviglioso potere.</p>
            <p>Tutta la creazione, che precede l’uomo, è vincolata dalla rigida legge della natura. Il sole, le stelle
                infinite, gli oceani e i mari, le montagne, i fiumi, gli alberi, tutti gli animali, grandi e piccoli,
                tutti devono obbedire alle leggi della natura.</p>
            <p>L’uomo soltanto è libero e per mezzo del suo intelletto ha potuto prendere il controllo di molte leggi
                della natura e adattarne alcune ai suoi bisogni. Con la potenza dell’intelletto l’uomo ha scoperto i
                mezzi non solo per attraversare con rapidità estesi continenti in ferrovia e vasti oceani sui piroscafi,
                ma, come i pesci, può viaggiare sott’acqua nei sottomarini e, come gli uccelli, può volare per l’aria
                negli aeroplani.</p>
            <p>L’uomo è riuscito a utilizzare l’elettricità in vari modi, per la luce, per la forza motrice, per mandare
                messaggi da un estremo all’altro della terra e, grazie all’elettricità, può persino udire una voce
                lontana miglia e miglia.</p>
            <p>Il dono dell’intelletto ha reso possibile all’uomo di utilizzare i raggi del sole per ritrarre le persone
                e le cose e perfino per catturare la forma di lontanissimi corpi celesti.</p>
            <p>Vediamo in quanti modi l’uomo ha potuto piegare alla sua volontà le forze della natura.</p>
            <p>È triste ora constatare che l’uomo ha adoperato questo dono di Dio per fabbricare strumenti di guerra,
                per infrangere il Comandamento di Dio: «Non uccidere» e sfidare il precetto di Cristo: «Amatevi l’un
                l’altro».</p>
            <p>Dio ha dato all’uomo il potere dell’intelletto, perché lo adoperasse per il progresso della civiltà, per
                il bene dell’umanità, per diffondere amore, concordia e pace. Ma l’uomo preferisce usare questo dono per
                distruggere invece che per costruire, per l’ingiustizia e l’oppressione, per l’odio, la discordia e la
                devastazione, per uccidere quel prossimo, che Cristo gli comandò di amare come se stesso!</p>
            <p>Spero che adoperiate la vostra intelligenza per promuovere l’unità e la tranquillità del genere umano,
                per dare luce e civiltà al popolo, per far sbocciare l’amore attorno a voi e portare la pace
                universale.</p>
            <p>Studiate le scienze, acquistate sempre maggiore sapere. Senza dubbio si può imparare fino alla morte!
                Adoperate il vostro sapere sempre a profitto degli altri. Possa così la guerra sparire da questo bel
                mondo e sorgere invece il glorioso edificio della pace e della concordia. Fate sì che i vostri alti
                ideali si realizzino nel Regno di Dio sulla terra, così come avverrà in Cielo.</p>

        </Wrapper>
    </div>

)
