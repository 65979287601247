import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero28 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>28. DISCORSO A «L’ALLEANZA SPIRITUALISTA»
            <br/>Sala dell’Ateneo
            <br/>St. Germain, Parigi, 9 novembre 1911

        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1408988641&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p>Desidero esprimervi la mia gratitudine per la vostra ospitalità e la mia gioia di trovare che siete
                spiritualmente disposti. Sono felice di essere presente in un’assemblea come questa, che si aduna per
                ascoltare un Messaggio divino. Se poteste vedere con occhi di verità, grandi ondate di spiritualità vi
                si renderebbero visibili in questo luogo. Il potere dello Spirito Santo è qui per tutti. Sia lode a Dio
                che i vostri cuori sono ispirati da divino fervore. Le vostre anime sono come onde sul mare dello
                spirito. Benché ogni individuo sia un’onda distinta dalle altre, il mare è uno e tutti sono uniti in
                Dio.</p>
            <p>Ogni cuore deve irradiare unità, perché la luce dell’unica Sorgente divina di tutti possa risplendere
                chiara e luminosa. Dobbiamo considerare il mare intero e non soltanto le onde separate. Dobbiamo salire
                dall’individuo alla collettività. Lo spirito è come un unico grande mare e le sue onde sono le anime
                degli uomini.</p>
            <p>Ci dicono le sacre Scritture che la nuova Gerusalemme apparirà sulla terra. È evidente che questa città
                celeste non è fatta di pietre e di calcina, che non è una città fatta con le mani, ma eterna nei
                Cieli.</p>
            <p>Questo è un simbolo profetico, a significare che ritorneranno gli insegnamenti divini a illuminare i
                cuori degli uomini. È passato molto tempo da quando questa sacra Guida guidava la vita dell’umanità. Ma
                ora, finalmente, la Città santa della nuova Gerusalemme è ritornata nel mondo. È riapparsa sotto un
                cielo orientale. Dall’orizzonte della Persia il Suo splendore è sorto a illuminare il mondo intero.
                Vediamo in questi giorni l’adempimento della Profezia divina. Gerusalemme è scomparsa. La città celeste
                è stata distrutta. Ora è stata ricostruita. È stata rasa al suolo, ma ora le sue mura e i suoi pinnacoli
                sono stati riedificati e torreggiano in rinnovata e gloriosa bellezza.</p>
            <p>Nel mondo occidentale la prosperità materiale ha trionfato, mentre in Oriente è apparso il sole
                spirituale. Sono molto felice di vedere un’assemblea come questa a Parigi, dove il progresso spirituale
                e il progresso materiale si sono incontrati in perfetta unione.</p>
            <p>L’uomo, il vero uomo, è anima, non corpo. Benché l’uomo fisicamente appartenga al regno animale, pure
                l’anima sua lo innalza al di sopra del resto della creazione. Guardate come la luce del sole illumina il
                mondo della materia. Allo stesso modo la luce divina diffonde i suoi raggi nel regno dell’anima. È
                l’anima che fa della creatura umana un essere celeste!</p>
            <p>Grazie al potere dello Spirito Santo, che opera attraverso la sua anima, l’uomo può percepire la realtà
                divina delle cose. Tutte le grandi opere dell’arte e della scienza stanno a provare questo potere dello
                Spirito.</p>
            <p>Lo stesso Spirito dà la vita eterna.</p>
            <p>Solo a quelli che sono battezzati dallo Spirito divino è dato il potere di stringere tutti i popoli nel
                vincolo dell’unità. È col potere dello Spirito che l’orientale Mondo del pensiero spirituale può
                mescolarsi con l’occidentale regno dell’azione, così che il mondo della materia divenga divino.</p>
            <p>Ne consegue che tutti coloro che lavorano per il Piano supremo sono soldati nell’esercito dello
                Spirito.</p>
            <p>La luce del mondo celeste fa guerra contro il mondo dell’ombra e dell’illusione. I raggi del Sole della
                Verità disperdono le tenebre della superstizione e dell’incomprensione.</p>
            <p>Voi siete dello Spirito! A voi che cercate la verità, la Rivelazione di Bahá’u’lláh verrà come una grande
                gioia! Questo insegnamento è dello Spirito, non vi è in esso un precetto che non sia dello Spirito
                divino.</p>
            <p>Lo Spirito non può essere percepito dai sensi materiali del corpo fisico, se non si esprime in segni e
                opere esteriori. Il corpo umano è visibile, l’anima è invisibile. È tuttavia l’anima che dirige le
                facoltà dell’uomo, che governa la sua umanità.</p>
            <p>L’anima ha due principali facoltà. <br/>(a) Come le circostanze esteriori sono comunicate all’anima dagli
                occhi, dagli orecchi e dal cervello dell’uomo, così l’anima comunica i suoi desideri e i suoi propositi
                attraverso il cervello alle mani e alla lingua e in tal maniera si esprime. Lo spirito nell’anima è
                l’essenza stessa della vita. <br/>(b) La seconda facoltà dell’anima si esprime nel mondo della visione,
                dove l’anima, abitata dallo spirito, ha la propria esistenza e funziona senza l’aiuto dei materiali
                sensi corporali. Là, nel regno della visione, l’anima vede senza l’aiuto dell’occhio fisico, ode senza
                bisogno dell’orecchio e viaggia senza dipendere dal moto fisico. Perciò è chiaro che lo spirito
                nell’anima dell’uomo può funzionare per mezzo del corpo, usando gli organi dei sensi ordinari, e che può
                anche vivere e agire senza il loro aiuto nel mondo della visione. Ciò prova pienamente la superiorità
                dell’anima dell’uomo in confronto del corpo, la superiorità dello spirito sulla materia.</p>
            <p>Per esempio, guardate questa lampada: non è la luce che si trova in essa superiore alla lampada che la
                contiene? Per quanto bella possa essere la forma della lampada, se non vi è luce, la lampada non
                raggiunge il suo scopo, è senza vita, una cosa morta. La lampada deve avere la luce, ma la luce non ha
                bisogno della lampada.</p>
            <p>Lo spirito non ha necessità di avere un corpo, ma il corpo o ha lo spirito o non può vivere. L’anima può
                vivere senza corpo, ma il corpo senz’anima muore.</p>
            <p>Se un uomo perde la vista, l’udito, una mano o un piede, se l’anima e ancora nel corpo, quell’uomo vive e
                può manifestare le virtù divine. D’altra parte, senza lo spirito, è impossibile che un corpo esista allo
                stato perfetto.</p>
            <p>Il più grande potere dello Spirito Santo si trova nelle Manifestazioni divine della Verità. Per mezzo del
                potere dello Spirito, gli Insegnamenti celesti sono stati portati nel mondo dell’umanità. Per mezzo del
                potere dello Spirito la vita eterna è venuta ai figli dell’uomo. Per mezzo del potere dello Spirito la
                gloria divina ha brillato da Oriente a Occidente e per mezzo dello stesso Spirito le virtù divine
                dell’umanità si manifestano.</p>
            <p>I nostri massimi sforzi devono essere diretti a distaccarci dalle cose del mondo. Dobbiamo sforzarci di
                divenire più spirituali, più illuminati, di seguire i consigli degli Insegnamenti divini, di servire la
                causa dell’unità e della vera uguaglianza, di essere misericordiosi, di riflettere su tutti gli uomini
                l’amore dell’Altissimo, cosicché la luce dello Spirito appaia in ogni nostra azione, affinché tutta
                l’umanità sia unita, il mare tempestoso si calmi e ogni burrascosa onda sparisca dalla superficie
                dell’oceano della vita, lasciandolo calmo e pacifico. Allora il genere umano vedrà la nuova Gerusalemme,
                entrerà attraverso le sue porte e riceverà il Dono divino.</p>
            <p>Ringrazio Dio di essere stato oggi fra voi e ringrazio voi per i vostri sentimenti spirituali.</p>
            <p>Prego affinché si accresca il vostro fervore divino e aumenti il potere dell’unità nello Spirito,
                cosicché si compiano le profezie e in questo grande secolo della Luce di Dio si avverino tutte le liete
                novelle annunziate nelle sacre Scritture. Questa è quella luminosa epoca di cui Gesù Cristo parlò quando
                ci insegnò a pregare «il tuo regno venga, la tua volontà sia fatta in terra, come in cielo» (Luca XI,
                2-3). Spero che questa sia anche la vostra aspettativa e il vostro gran desiderio.</p>
            <p>Siamo uniti nell’unico scopo e nella comune speranza di poter essere tutti come un solo uomo e di vedere
                ogni cuore illuminato dall’amore del nostro divino Padre Iddio.</p>
            <p>Possano tutti i nostri atti essere spirituali e tutti i nostri interessi e le nostre affezioni
                concentrate nel Regno della Gloria.</p>
        </Wrapper>
    </div>

)
