import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero47 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>47. IL SETTIMO PRINCIPIO: L’UGUAGLIANZA DEGLI UOMINI
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1414330294&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p>«Le leggi di Dio non sono imposizioni di volontà o potere o piacere, ma applicazioni della verità, della
                ragione e della giustizia».</p>
            <p>Tutti gli uomini sono uguali davanti alla legge che deve regnare sovrana.</p>
            <p>Lo scopo della punizione non è la vendetta, ma la prevenzione del crimine.</p>
            <p>I re devono governare con saggezza e con giustizia. I principi, i nobili e i contadini hanno pari diritto
                allo stesso trattamento e non si devono fare favoritismi per nessuno. Un giudice non deve tenere conto
                delle personalità, ma deve amministrare la legge con rigorosa imparzialità in ogni caso a lui
                deferito.</p>
            <p>Se una persona commette un delitto contro di voi, non avete il diritto di perdonarlo. La legge deve
                punirlo per prevenire che altri ripetano lo stesso delitto, poiché la punizione di una persona non ha
                alcuna importanza di fronte al benessere generale del popolo.</p>
            <p>Quando in tutti i paesi dell’Oriente e dell’Occidente regnerà una giustizia perfetta, la terra diverrà un
                luogo magnifico. La dignità e l’uguaglianza di ciascun servo di Dio saranno riconosciute, l’ideale della
                solidarietà della razza umana, della vera fratellanza, sarà realizzato e la gloriosa luce del Sole della
                Verità illuminerà le anime di tutti gli uomini.</p>
        </Wrapper>
    </div>

)
