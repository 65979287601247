import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero46 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>46. IL SESTO PRINCIPIO: I MEZZI DI SUSSISTENZA
            <br/>Avenue de Camoëns 4, Parigi
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1414329700&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p>Uno dei più importanti principi fra gli Insegnamenti di Bahá’u’lláh è il diritto di ogni essere umano al
                pane quotidiano col quale sussiste, ovvero la parificazione dei mezzi di sussistenza.</p>
            <p>La sistemazione delle condizioni dei popoli deve essere tale che la povertà scompaia, che ognuno abbia,
                secondo il proprio rango e la propria posizione, un proprio agio e un proprio benessere, quanto più è
                possibile.</p>
            <p>Da un canto vediamo fra noi uomini sovraccarichi di ricchezze e, dall’altro, sventurati che soffrono la
                fame perché non hanno nulla, persone che hanno imponenti palazzi e altri che non sanno dove posare il
                capo. Vediamo alcuni che consumano molte portate di cibo costoso e appetitoso, mentre altri a mala pena
                hanno un tozzo di pane per rimanere in vita. Mentre alcuni indossano velluti, pellicce e lini scelti,
                altri hanno indumenti poveri e leggeri, insufficienti a proteggerli dal freddo.</p>
            <p>Questo stato di cose è sbagliato e deve essere rimediato. Il rimedio però deve essere applicato con molta
                perizia. Non si può rimediare portando l’assoluta uguaglianza fra gli uomini.</p>
            <p>L’uguaglianza è una chimera! È assolutamente inattuabile! Anche se si potesse realizzare, non potrebbe
                durare e se potesse esistere, l’intero ordine mondiale ne sarebbe distrutto. Nel mondo dell’umanità
                devono sempre esistere la legge e l’ordine. Il cielo ha così decretato nel momento della creazione
                dell’uomo.</p>
            <p>Alcuni sono ricchi di talento, altri ne hanno una misura normale, mentre altri ancora sono privi
                d’intelletto. In queste tre classi c’è ordine, ma non uguaglianza. Com’è possibile considerare la
                saggezza identica alla stoltezza? L’umanità è come un esercito. Ha bisogno di generali, capitani,
                sottufficiali di vario grado e soldati, ognuno con le proprie mansioni prestabilite. I gradi sono
                assolutamente necessari per assicurare un’amministrazione ordinata. Un esercito non può essere
                costituito soltanto di generali, di capitani o di semplici soldati senza qualcuno che abbia autorità. Il
                risultato sicuro di un tale piano sarebbe il disordine e la demoralizzazione che s’impossesserebbero
                dell’intero esercito.</p>
            <p>Re Licurgo, il filosofo, elaborò un piano grandioso per rendere uguali i sudditi di Sparta. L’esperimento
                ebbe inizio con sacrifici personali e con grande saggezza. Poi il re convocò il popolo e gli fece
                giurare che avrebbe mantenuto lo stesso sistema di governo anche se lui avesse lasciato il paese e che
                non avrebbe cambiato nulla fino al suo ritorno. Avendo ottenuto questo giuramento, lasciò il suo regno
                di Sparta e non fece più ritorno. Licurgo abbandonò il suo stato, rinunciando al suo elevato rango,
                credendo di ottenere il bene permanente del suo paese, rendendo eguale le proprietà e le condizioni di
                vita nel regno. Questo sacrificio del re fu inutile. Il grande esperimento fallì e dopo un certo periodo
                di tempo tutto fu distrutto e la sua costituzione, così meticolosamente ideata, cessò d’esistere.</p>
            <p>La futilità del tentativo di quel piano fu dimostrata e l’impossibilità di stabilire condizioni uguali di
                esistenza fu proclamata nell’antico regno di Sparta. Oggi un simile tentativo sarebbe ugualmente
                destinato a fallire.</p>
            <p>Certamente, dato che alcune persone sono enormemente ricche e altre deplorevolmente povere è necessaria
                un’organizzazione che controlli e migliori questo stato di cose. È molto importante limitare le
                ricchezze, ma è anche importante limitare la povertà. Entrambi gli estremi non sono buoni. È meglio
                stare sulla via di mezzo «Non darmi povertà né ricchezza» (Proverbi XXX, 8). Un capitalista ha il
                diritto di possedere una grande fortuna, ma è ugualmente giusto che il suo operaio abbia mezzi
                sufficienti per vivere.</p>
            <p>Non deve esistere un finanziere con ricchezze colossali mentre accanto a lui si trova un povero nel
                peggiore bisogno. Quando vediamo che si permette che la povertà raggiunga le condizioni dell’estrema
                inedia, possiamo esser certi che da qualche parte esiste la tirannia. L’umanità deve impegnarsi per
                risolvere questo problema e non indugiare oltre a cambiare le condizioni che impongono a gran parte
                della popolazione le sofferenze di una logorante miseria. I ricchi devono dare una parte della loro
                opulenza, devono intenerirsi il cuore e coltivare la propria sensibilità alla compassione,
                preoccupandosi degli afflitti che soffrono per mancanza delle necessità essenziali alla vita.</p>
            <p>Si dovranno creare leggi speciali per regolare gli estremi di ricchezza e di bisogno. I membri del
                governo devono tenere in considerazione le leggi di Dio quando preparano i piani per governare i popoli.
                I diritti dell’umanità devono essere protetti e preservati.</p>
            <p>I governi dei vari paesi devono conformarsi alla Legge divina che garantisce uguale giustizia per tutti.
                Questa è la sola via che permetterà l’abolizione del deplorevole eccesso di ricchezze e della
                miserevole, demoralizzante e degradante povertà. Finché non lo si farà, non si obbedirà alla Legge di
                Dio.</p>
        </Wrapper>
    </div>

)
