import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero4 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>4. LA NECESSITÀ DELL’UNIONE FRA I POPOLI DELL’ORIENTE E DELL’OCCIDENTE
            <br/>20 ottobre 1911
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1376598742&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p>‘Abdu’l-Bahá disse:</p>
            <p>Nel passato, come nel presente, il Sole della Verità ha sempre brillato sull’orizzonte dell’Oriente.
                Abramo apparve in Oriente e in Oriente Mosè sorse per guidare e istruire il popolo. Sull’orizzonte
                orientale apparve il Signore Gesù Cristo. Muḥammad fu mandato in una nazione orientale. Il Báb Si
                manifestò in un paese orientale, la Persia. Bahá’u’lláh visse e insegnò in Oriente. Tutti i grandi
                Mae-stri spirituali sono sorti nel mondo orientale. Ma benché il sole di Cristo sia sorto in Oriente, la
                sua luce apparve in Occidente, dove lo splendore della gloria Sua fu anzi più fulgido. La luce divina
                degli insegnamenti di Gesù brillò con maggior forza nel mondo occidentale, dove progredì più rapidamente
                che nel paese della sua nascita.
                Oggi l’Oriente ha bisogno di progresso materiale e l’Occidente ha necessità di un’idea spirituale.
                Farebbe bene l’Occidente a volgersi all’Oriente per esserne illuminato e a dare, in compenso, il suo
                sapere scientifico. Occorre questo scambio di doni.
            </p>

            <p>
                L’Occidente e l’Oriente devono unirsi per darsi reciprocamente ciò di cui sono privi. Questa unione
                porterà una vera civiltà, nella quale l’elemento spirituale della vita si esprime e si realizza in
                quello materiale.
            </p>
            <p>
                Ricevendo così l’uno dall’altro, la più completa armonia predominerà, tutti i popoli saranno uniti, si
                conseguirà la perfezione, regnerà una forte solidarietà e questo mondo diventerà uno specchio brillante
                nel quale si rifletteranno gli attributi di Dio.
            </p>
            <p>
                Dobbiamo tutti, le nazioni orientali e occidentali, cercare giorno e notte, con tutto il cuore e tutta
                l’anima, di raggiungere il grande ideale di cementare l’unità fra tutte le nazioni della terra. Ogni
                cuore sarà, allora, contento, ogni occhio si aprirà alla verità, ci sarà dato un potere meraviglioso, la
                felicità dell’umanità sarà assicurata.
            </p>
            <p>
                Dobbiamo pregare che per la munificenza di Dio la Persia riceva la civiltà materiale e mentale
                dell’Occidente e in compenso dia, sempre per la Grazia divina, la sua luce spirituale. Lo sforzo devoto
                ed energico degli uomini uniti, occidentali e orientali, riuscirà a ottenere questo risultato, perché la
                forza dello Spirito Santo li aiuterà.
            </p>
            <p>
                I principi e gli Insegnamenti di Bahá’u’lláh devono essere studiati attentamente uno per uno, finché
                siano compresi e indelebilmente impressi nella mente e nel cuore, così diventerete forti seguaci della
                luce, veri soldati spirituali, soldati celesti di Dio che acquistano e divulgano la vera civiltà in
                Persia, in Europa e nel mondo intero.
            </p>
            <p>
                Questo sarà il paradiso che deve venire sulla terra, quando l’umanità sarà riunita sotto il baldacchino
                dell’unità, nel Regno della Gloria.
            </p>
        </Wrapper>
    </div>

)
