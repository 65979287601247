import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero32 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>32. I BAHÁ’Í DEVONO DARE CUORE E ANIMA ALL’ALTA OPERA DI PORTARE NEL MONDO UNA MIGLIORE CONDIZIONE
            <br/>19 novembre 1911
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1408991350&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>Che
            <p>Che gioia vedere un’adunanza come questa! In verità essa è una riunione di «uomini celestiali».</p>
            <p>Siamo tutti uniti in uno scopo divino, non abbiamo alcun fine materiale e il nostro desiderio più vivo è
                quello di diffondere nel mondo l’Amore di Dio!</p>
            <p>Lavoriamo e preghiamo per l’unità del genere umano, perché tutte le razze della terra diventino una razza
                sola, tutti i paesi un unico paese e tutti i cuori battano come un sol cuore, lavorando insieme per
                l’unità e la fratellanza perfette.</p>
            <p>Sia lodato Iddio, i nostri sforzi sono sinceri e i nostri cuori sono rivolti verso il Regno. La nostra
                più alta aspirazione è che la verità si insedi nel mondo e in questa speranza ci avviciniamo l’uno
                all’altro con amore e affetto. Ognuno di noi, con magnanimità e abnegazione, è pronto a sacrificare ogni
                ambizione personale al grande ideale verso cui tendiamo: amore fraterno, pace e unità fra gli
                uomini!</p>
            <p>Non dubitate che Dio è con noi, alla nostra destra e alla nostra sinistra, che ogni giorno Egli farà
                aumentare il nostro numero e che le nostre riunioni diverranno sempre più forti e più utili.</p>
            <p>La mia più cara speranza è che voi diveniate una benedizione per gli altri, che diate la vista a chi è
                spiritualmente cieco, l’udito a chi è spiritualmente sordo, la vita a chi è morto nel peccato.</p>
            <p>Possiate aiutare coloro che sono sprofondati nella materialità a comprendere di essere creature di Dio e
                incoraggiarli ad elevarsi per esser degni del loro diritto di nascita, così per i vostri sforzi possa il
                mondo dell’umanità diventare il Regno di Dio e dei Suoi eletti.</p>
            <p>Ringrazio Iddio che siamo uniti in questo grande ideale, che i miei desideri sono anche i vostri e che
                lavoriamo assieme in perfetta unione.</p>
            <p>Oggi, sulla terra, si vede il triste spettacolo di guerre crudeli! L’uomo uccide il fratello per scopi
                egoistici e per allargare i confini del proprio paese. Per queste ignobili ambizioni, l’odio si è
                impossesato dei cuori e sempre più si sparge sangue.</p>
            <p>Nuove battaglie si combattono e gli eserciti crescono. Si fabbricano più cannoni, più fucili, più
                esplosivi di ogni genere e così crescono sempre di più il rancore e l’odio.</p>
            <p>Ma quest’assemblea, grazie a Dio, anela solamente alla pace e all’unità e vuol lavorare con tutto il
                cuore e tutta l’anima per portare una condizione migliore nel mondo.</p>
            <p>Voi che siete i servi di Dio combattete contro l’oppressione, l’odio e la discordia, in modo che cessino
                le guerre e le divine leggi della pace e dell’amore imperino fra gli uomini.</p>
            <p>Mettetevi all’opera con tutta la vostra energia, divulgate la Causa del Regno di Dio fra gli uomini,
                insegnate al superbo a volgersi umilmente verso Dio, al peccatore a non peccare più e attendete con
                gioia la venuta del Regno.</p>
            <p>Amate e obbedite il Padre celeste e siate sicuri che l’aiuto divino non vi mancherà mai. In verità, io vi
                dico che conquisterete il mondo.</p>
            <p>Abbiate fede, pazienza e coraggio! Questo è soltanto l’inizio, ma avrete sicuramente successo, perché Dio
                è con voi!</p>

        </Wrapper>
    </div>

)
