import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero44 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>44. IL QUARTO PRINCIPIO: L’ACCETTAZIONE DEI RAPPORTI
            FRA LA RELIGIONE E LA SCIENZA
            <br/>Avenue de Camoëns 4, Parigi
            <br/>12 novembre 1911
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1414327888&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p> ‘Abdu’l-Bahá disse:
                <br/>Vi ho già parlato di alcuni dei principi di Bahá’u’lláh, la ricerca della verità e l’unità del
                genere umano. Vi parlerò ora del quarto principio, che è l’accettazione dei rapporti fra la religione e
                la scienza.</p>
            <p>Non v’è contraddizione fra la vera religione e la scienza. Quando si oppone alla scienza, la religione
                diventa superstizione. Tutto ciò che è contrario al sapere è ignoranza.</p>
            <p>Come si può credere che ciò che la scienza ha dimostrato è una cosa impossibile? Se ci si dovesse credere
                nonostante l’uso della ragione, questo non sarebbe fede, ma superstizione e ignoranza. I veri principi
                di tutte le religioni concordano con gli insegnamenti della scienza.</p>
            <p>L’Unità di Dio è logica e questa idea non è in contrasto con le deduzioni tratte dagli studi
                scientifici.</p>
            <p>Tutte le religioni insegnano ad essere generosi, sinceri, veritieri, rispettosi della legge e fedeli.
                Tutto questo è ragionevole e logicamente è la sola strada sulla quale l’umanità può progredire.</p>
            <p>Tutte le leggi religiose s’accordano con la ragione e si addicono ai popoli per i quali sono state
                concepite e alle età nelle quali devono essere obbedite.</p>
            <p>La religione contiene due parti principali:
                <br/>I. La parte spirituale.
                <br/>II. La parte pratica.</p>
            <p>La parte spirituale è immutabile. Tutte le Manifestazioni di Dio e i Suoi Profeti hanno insegnato le
                medesime verità e la stessa legge spirituale. Essi insegnano un solo codice di moralità. Nella verità
                non esistono suddivisioni. Il Sole ha inviato diversi raggi per illuminare l’intelligenza umana, ma la
                luce è sempre la stessa.</p>
            <p>La parte pratica della religione s’interessa di cose esteriori, di cerimonie e metodi di punizione per
                certe mancanze. Questa è la parte materiale della legge che guida gli usi e i costumi dei popoli.</p>
            <p>Ai tempi di Mosè v’erano dieci crimini punibili con la pena di morte. Quando Cristo apparve, tutto
                cambiò. Il vecchio assioma «occhio per occhio, dente per dente» fu convertito in «Ama i nemici e fa’ del
                bene a chi ti odia». La severa legge antica fu trasformata in una legge di amore, misericordia e
                tolleranza!</p>
            <p>Nei tempi antichi la punizione per il furto era l’amputazione della mano destra. Questa legge non può
                applicarsi nei nostri tempi. In questa èra, un uomo che impreca contro il padre è lasciato vivere,
                mentre nelle epoche passate era messo a morte. È quindi evidente che mentre la legge spirituale è
                immutabile, l’applicazione delle regole pratiche deve cambiare in relazione alle necessità dei tempi.
                L’aspetto spirituale della religione è il maggiore, il più importante dei due ed è lo stesso in tutti i
                tempi e non cambia mai! È sempre lo stesso, ieri, oggi e per sempre! «Come era al principio, lo è
                adesso, e sarà sempre così».</p>
            <p>Ora, tutte le questioni di moralità contenute nell’immutabile legge spirituale di tutte le religioni sono
                logicamente giuste. Una religione contraria alla logica della ragione cessa di essere una religione,
                rimanendo una mera tradizione. La religione e la scienza sono due ali sulle quali l’intelligenza
                dell’uomo può librarsi in alto, con le quali l’anima umana può progredire. Non è possibile volare con
                un’ala sola! Se dovesse tentare il volo con la sola ala della religione, l’uomo cadrebbe rapidamente nel
                pantano della superstizione. D’altro canto con la sola ala della scienza non farebbe alcun progresso e
                cadrebbe nella sconsolante palude del materialismo. Tutte le religioni oggi esistenti sono cadute in
                pratiche superstiziose, che sono in disarmonia tanto con i veri principi degli insegnamenti che
                rappresentano quanto con le scoperte scientifiche dei tempi. Molti capi religiosi sono arrivati a
                pensare che l’importanza della religione consista principalmente nell’adesione a una raccolta di dogmi e
                nella pratica di riti e cerimonie! Coloro le cui anime essi professano di curare sono indirizzati a
                credere la stessa cosa e si aggrappano tenacemente a forme esteriori, confondendole con la verità
                interiore.</p>
            <p>Ora, le forme e i riti differiscono nelle varie chiese e fra le varie sètte e talvolta sono in
                contraddizione gli uni con gli altri, dando così adito a discordie, odi e conflitti. Il risultato di
                tutti questi dissensi è che molti uomini colti credono che religione e scienza siano due termini
                contraddittori, che la religione non abbia bisogno del potere della riflessione e non debba essere
                regolata in alcun modo dalla scienza, ma che anzi le due debbano necessariamente opporsi l’una
                all’altra. Il deplorevole effetto è che la scienza si è allontanata dalla religione e la religione è
                divenuta la cieca e più o meno apatica seguace dei precetti di alcuni teologi, i quali insistono che i
                loro dogmi favoriti siano accettati, anche se sono contrari alla scienza. Questa è stoltezza, perché è
                evidente che la scienza è luce e, stando così le cose, la vera religione non si oppone al sapere.</p>
            <p>Conosciamo bene la frase «luce e tenebre», «religione e scienza». Ma la religione che non cammina con la
                mano nella mano della Scienza si trova nelle tenebre della superstizione e dell’ignoranza.</p>
            <p>La maggior parte dei conflitti e delle discordie del mondo sono creati da queste opposizioni e
                contraddizioni costruite dall’uomo. Se la religione fosse in armonia con la scienza e le due
                camminassero insieme, tutto l’odio e l’avversione che tanto male arrecano alla razza umana cesserebbero
                d’esistere.</p>
            <p>Considerate, che cosa distingue l’uomo fra tutte le cose create e fa di lui una creatura sui generis? Non
                è forse il potere della ragione, l’intelligenza? Non deve egli usare queste facoltà nello studio della
                religione? Io vi dico: pesate accuratamente sulla bilancia della ragione e della scienza tutto ciò che
                vi è presentato come religione. Se supera l’esame, accettatelo, poiché è la verità. Ma se non lo supera,
                respingetelo perché non è altro che ignoranza!</p>
            <p>Guardatevi intorno e notate come il mondo d’oggi è immerso in superstizioni e formalità esteriori!</p>
            <p>Alcuni venerano il prodotto della propria immaginazione. Si creano un Dio immaginario e lo adorano,
                mentre la creazione delle loro menti finite non potrà mai essere l’Infinito Possente Creatore di tutte
                le cose visibili e invisibili! Altri adorano il sole o gli alberi o le pietre! In età passate v’erano
                persone che adoravano il mare, la pioggia e perfino la terra!</p>
            <p>Oggi gli uomini hanno concepito un tale adorante attaccamento alle formalità esteriori e alle cerimonie,
                che cavillano su questo aspetto di un rito o su quella particolare pratica, fino al punto che si odono
                dappertutto estenuanti discussioni e inquietudini. Vi sono persone di debole intelletto e limitato
                potere razionale, ma non si deve dubitare della forza e del potere della religione a causa della loro
                incapacità di capire.</p>
            <p>Un bimbo non può comprendere le leggi che governano la natura, ma questo è dovuto al suo intelletto
                immaturo. Quando sarà cresciuto e sarà ben istruito, anche lui potrà comprendere le eterne verità. Un
                bambino non può capire che la terra ruota attorno al sole, ma quando la sua intelligenza si sarà
                risvegliata, quel fatto gli sarà chiaro ed evidente.</p>
            <p>La religione non può essere in contrasto con la scienza, anche se alcuni intelletti sono o troppo deboli
                o troppo immaturi per comprendere la verità.</p>
            <p>Dio ha fatto della religione e della scienza la misura, per così dire, della nostra comprensione. Fate
                attenzione a non trascurare questo meraviglioso potere. Pesate tutto su questa bilancia.</p>
            <p>Per colui che ha il potere della comprensione, la religione è come un libro aperto, ma come è possibile
                che un uomo privo della ragione e dell’intelletto comprenda le divine Realtà di Dio?</p>.
            <p>Ponete ogni vostro credo in armonia con la scienza. Non vi può essere opposizione, perché la verità è
                una. Quando la religione, libera dalle superstizioni, dalle tradizioni e dai dogmi inintelligibili, si
                renderà conforme alla scienza, una grande forza unificatrice e purificatrice spazzerà dal mondo tutte le
                guerre, i disaccordi, le discordie e le lotte e allora l’umanità intera sarà unita nel potere dell’Amore
                di Dio.</p>

        </Wrapper>
    </div>

)
