import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero16 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>16. IL VERO SIGNIFICATO DELLE PROFEZIE SULLA VENUTA DI CRISTO
            <br/>30 ottobre 1911
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1386998602&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p>Nella Bibbia vi sono profezie sulla venuta di Cristo. Gli ebrei attendono ancora la venuta del Messia e
                pregano Dio giorno e notte per affrettarne l’avvento.</p>
            <p>Quando Cristo venne, essi Lo denunziarono e Lo martirizzarono, dicendo: «Costui non è Colui che
                aspettavamo. Quando il Messia verrà, segni e portenti attesteranno che, in verità, Egli è il Cristo.
                Conosciamo i segni e le condizioni, ma non sono apparsi. Il Messia verrà da una città sconosciuta,
                occuperà il trono di David, brandendo una spada d’acciaio, ed ecco con uno scettro di ferro regnerà!
                Adempirà la legge dei Profeti, conquisterà l’Oriente e l’Occidente e glorificherà il Suo popolo eletto,
                gli ebrei. Porterà un regno di pace, nel quale perfino le bestie cesseranno di essere nemiche dell’uomo.
                Ecco, il lupo e l’agnello si disseteranno alla stessa sorgente, il leone e il capretto giaceranno nello
                stesso pascolo, il serpente e il topo divideranno il medesimo nido e la pace regnerà fra tutte le
                creature di Dio».</p>
            <p>Secondo gli ebrei Gesù Cristo non adempì ad alcuna di queste condizioni. Ma i loro occhi erano chiusi e
                non potevano quindi vedere.</p>
            <p>Gesù venne da Nazareth, che non è un luogo sconosciuto. Non impugnò né una spada né un bastone. Non Si
                assise sul trono di David, ma era povero. Riformò la Legge di Mosè e violò il sabato. Non conquistò né
                l’Oriente né l’Occidente, ma fu Lui stesso suddito della legge romana. Non esaltò gli ebrei, ma insegnò
                l’uguaglianza e la fratellanza e riprovò gli scribi e i farisei. Non portò il regno della pace, perché
                durante la Sua vita l’ingiustizia e la crudeltà raggiunsero a tal unto grado che Egli stesso ne cadde
                vittima ed ebbe una morte ignominiosa sulla croce.</p>
            <p>Così gli ebrei pensavano e dicevano, perché non capivano le Scritture e le luminose verità contenute in
                esse. Le conoscevano alla lettera a memoria, ma del loro spirito vivificatore non comprendevano nemmeno
                una parola.</p>
            <p>Ascoltate e ve ne spiegherò il significato. Benché venisse da Nazaret, un luogo conosciuto, Gesù venne,
                anche, dal Cielo. Il corpo Suo nacque da Maria, ma il Suo spirito venne dal Cielo. La spada che
                impugnava era la Sua lingua, con la quale divise il bene dal male, il vero dal falso, la fedeltà
                dall’infedeltà e la luce dalle tenebre. La Sua parola era, in verità, una spada tagliente. Il trono sul
                quale sedette è il Trono eterno, sul quale Egli regna per sempre, un trono del Cielo, non della terra,
                perché le cose della terra svaniscono, mentre quelle del Cielo durano sempre. Gesù reinterpretò e
                completò la Legge di Mosè e adempì la Legge dei Profeti. La Sua parola conquistò l’Oriente e
                l’Occidente. Il Suo Regno è eterno. Egli esaltò fra gli ebrei coloro che Lo riconobbero. Erano uomini e
                donne di nascita umile, ma il contatto con Gesù li rese grandi e conferì loro una dignità eterna. Per
                gli animali che dovevano vivere insieme si dovevano intendere le sètte e le razze diverse, che prima
                erano in guerra, mentre ora avrebbero vissuto in armonia e amore, bevendo insieme l’Acqua della Vita di
                Cristo, la Fonte Eterna.</p>
            <p>Così tutte le profezie sulla venuta di Cristo si avverarono, ma gli ebrei chiusero gli occhi per non
                vedere e gli orecchi per non udire e la divina Realtà di Cristo passò fra loro inosservata, sconosciuta
                e non amata.</p>
            <p>È facile leggere le sacre Scritture, ma solo con un cuore pulito e con una mente pura si può capire il
                loro vero significato. Chiediamo a Dio di aiutarci a capire i Libri sacri. Preghiamo di darci occhi che
                vedano, orecchie che odano e cuori che desiderino ardentemente la pace.</p>
            <p>L’eterna Misericordia di Dio è infinita. Egli ha sempre scelto certe anime, sulle quali ha riversato la
                divina munificenza del Suo cuore, le cui menti ha illuminato con la luce celeste, alle quali ha rivelato
                i misteri sacri e ha tenuto lo Specchio della Verità chiaro davanti ai loro occhi. Questi sono i
                discepoli di Dio e la Sua bontà è senza limiti. Voi che siete i servi dell’Altissimo potete esserne
                anche discepoli. I tesori di Dio sono illimitati.</p>
            <p>Lo Spirito che aleggia nelle sacre Scritture è cibo per tutti gli affamati. Dio Che ha dato la
                rivelazione ai Suoi profeti darà senza dubbio, dall’abbondanza Sua, il pane quotidiano a tutti quelli
                che fedelmente Glielo chiedono.</p>
        </Wrapper>
    </div>

)
