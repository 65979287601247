import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero18 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>18. LE DUE NATURE DELL’UOMO
            <br/>1 novembre 1911
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1388414314&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p>Oggi è un giorno di festa a Parigi. Si celebra la festa di «Ognissanti». Quale pensate sia la ragione per
                cui questi esseri umani furono chiamati «santi»? La parola ha un preciso significato. Santo è colui che
                conduce una vita pura, colui che si è liberato da tutte le debolezze e le imperfezioni umane.</p>
            <p>Nell’uomo vi sono due nature: una elevata, quella spirituale, e una bassa, quella materiale. Con la prima
                egli si avvicina a Dio, con l’altra vive soltanto per il mondo. Segni di ambedue queste nature si
                riscontrano nell’uomo. Nel suo aspetto materiale l’uomo esprime la falsità, la crudeltà e l’ingiustizia.
                Tutte queste qualità sono il frutto della natura bassa dell’uomo. Gli attributi della sua natura divina
                si manifestano con l’amore, la misericordia, la benevolenza, la verità e la giustizia; ciascuna delle
                quali è un’espressione della sua natura più elevata. Tutte le buone abitudini, le qualità nobili
                appartengono alla natura spirituale dell’uomo, mentre tutte le sue imperfezioni e le sue azioni cattive
                provengono dalla sua natura materiale. Quando in un uomo la natura divina domina quella umana, abbiamo
                un santo.</p>
            <p>L’uomo ha il potere di fare tanto il bene quanto il male. Se in un uomo la capacità di fare il bene
                predomina e le sue inclinazioni al male sono dominate, quell’uomo in verità può essere chiamato santo.
                Ma se, al contrario, un uomo rifiuta le cose di Dio e permette che le sue cattive passioni lo vincano,
                allora egli non è migliore di un semplice animale.</p>
            <p>I santi sono uomini che si sono liberati dal mondo materiale e che hanno soggiogato il peccato. Essi
                vivono nel mondo, ma non sono del mondo. I loro pensieri rimangono sempre nella sfera dello spirito. La
                loro vita trascorre nella santità e i loro atti esprimono l’amore, la giustizia e la bontà. Essi sono
                illuminati dall’alto, sono come fulgide lampade che brillano sui luoghi oscuri della terra. Questi sono
                i santi di Dio. Gli Apostoli, che furono discepoli di Gesù Cristo, erano uomini come gli altri. Come i
                loro simili, erano attratti dalle cose del mondo e ognuno di loro pensava soltanto al proprio interesse.
                Non avevano un preciso concetto della giustizia e fra loro non si trovavano le perfezioni divine. Ma
                poi, quando credettero in Cristo e Lo seguirono, la loro ignoranza cedette il posto alla saggezza, la
                crudeltà si cambiò in giustizia, la falsità in verità, le tenebre divennero luce. Essi, che erano stati
                del mondo materiale, divennero spirituali, divini. Erano stati figli delle tenebre, divennero figli di
                Dio, divennero santi. Cercate dunque di seguire i loro passi, lasciando indietro tutte le cose del mondo
                e sforzandovi di raggiungere il Regno spirituale.</p>
            <p>Pregate Iddio di rinforzarvi nelle virtù divine, così che possiate essere come angeli nel mondo e fari di
                luce che rivelino i misteri del Regno a coloro che hanno cuori che comprendono.</p>
            <p>Dio mandò i Suoi Profeti nel mondo per insegnare all’uomo e illuminarlo, per spiegargli il mistero del
                Potere dello Spirito Santo, per renderlo atto a riflettere la luce divina ed essere così, a sua volta,
                fonte di guida per gli altri. I Libri divini, la Bibbia, il Corano e le altre Scritture sacre sono stati
                dati da Dio per guidare l’uomo sulle vie delle virtù divine, l’amore, la giustizia, la pace.</p>
            <p>Perciò vi dico di sforzarvi di seguire i consigli di questi Libri benedetti e di regolare le vostre vite
                in modo che, seguendo gli esempi posti davanti a voi, possiate diventare, voi stessi, i santi
                dell’Altissimo.</p>

        </Wrapper>
    </div>

)
