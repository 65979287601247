import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero56 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title>56. IL MALE
        </Title>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1420708813&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p>«Che cos’è il male?».</p>
            <p>‘Abdu’l-Bahá: «Il male è l’imperfezione. Il peccato è lo stato dell’uomo nel mondo della natura
                inferiore. Infatti nella natura esistono difetti come l’ingiustizia, la tirannia, l’odio, l’ostilità, la
                discordia. Sono le caratteristiche del grado inferiore della natura. Sono i peccati del mondo, i frutti
                di quell’albero che Adamo mangiò. Dobbiamo liberarci da queste imperfezioni mediante l’educazione. I
                Profeti di Dio sono stati mandati, i Libri sacri sono stati scritti, affinché l’uomo possa rendersi
                libero. Precisamente come nasce in questo mondo d’imperfezione dal seno della madre, così nasce nel
                mondo dello spirito grazie all’educazione divina. Quando l’uomo nasce nel mondo dei fenomeni, trova
                l’universo. Quando da questo mondo rinasce nel mondo dello spirito, trova il Regno.</p>

        </Wrapper>
    </div>

)
