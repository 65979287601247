import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero14 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>14. I BENEFICI DI DIO ALL’UOMO
            <br/>Avenue de Camoëns 4
            <br/>27 ottobre 1911
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1386996655&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p>Dio solo comanda a tutte le cose ed è onnipotente. Perché, allora, manda delle prove ai servi Suoi?</p>
            <p>Le prove dell’uomo sono di due tipi. <br/>(a) Le conseguenze delle proprie azioni. Per esempio, quando un
                uomo mangia troppo, si rovina la digestione. Se prende un veleno, si ammala o muore. Se gioca, perde il
                suo denaro. Se beve troppo, perde l’equilibrio. Tutte queste sofferenze sono causate dall’uomo stesso,
                perciò è chiaro che alcuni dolori sono la conseguenza delle proprie azioni.
                <br/>(b) Vi sono altre sofferenze che piombano sui fedeli di Dio. Pensate ai grandi dolori sofferti da
                Cristo e dai Suoi apostoli.</p>
            <p>Coloro che soffrono di più raggiungono la più alta perfezione.</p>
            <p>Coloro che esprimono il desiderio di soffrire per amore di Cristo, devono dar prova della loro sincerità.
                Coloro che si proclamano pronti ad affrontare gravi sacrifici possono provare la verità delle loro
                parole soltanto con le loro opere. Giobbe dette prova della fedeltà del suo amore per Dio serbandosi
                fedele tanto nella sventura quanto nella prosperità. Gli apostoli di Cristo che sopportarono con
                fermezza ogni prova e ogni sofferenza non hanno dimostrato la loro fedeltà? Non fu la loro pazienza la
                migliore prova?</p>
            <p>Questi dolori sono ora finiti.</p>
            <p>Caifa visse una vita comoda e felice, mentre la vita di Pietro fu piena di sofferenze e di prove. Quale
                dei due è più invidiabile? Senza dubbio sceglieremo lo stato presente di Pietro, perché egli ha la vita
                eterna, mentre Caifa si è procurato eterna vergogna. Le sofferenze di Pietro hanno messo alla prova la
                sua fedeltà. Le prove sono benefici che ci vengono da Dio, dei quali dobbiamo ringraziarLo. L’afflizione
                e il dolore non vengono sempre per caso, ce li manda la Misericordia divina per il nostro
                perfezionamento.</p>
            <p>Mentre è felice l’uomo può dimenticare Dio, ma quando vengono le disgrazie ed egli è affranto dal dolore,
                si ricorda del Padre Che è nei Cieli e può liberarlo dalle sue tribolazioni.</p>
            <p>Gli uomini che non soffrono non raggiungono la perfezione. La pianta che il giardiniere ha potato di più
                è quella che, quando viene l’estate, ha i fiori più belli e i frutti più abbondanti.</p>
            <p>L’agricoltore rompe la terra con l’aratro e da quella terra viene il raccolto più ricco e copioso. Quanto
                più un uomo ha sofferto, tanto più grande è il raccolto delle sue virtù spirituali. Un soldato non sarà
                un buon generale fino a che non sarà stato in prima linea, nella più aspra battaglia, e non avrà
                ricevuto le più profonde ferite.</p>
            <p>La preghiera dei Profeti di Dio è sempre stata ed è questa: O Dio! Bramo donare la vita sul sentiero che
                conduce a Te! Voglio dare il sangue mio per Te e compiere il sacrificio supremo!</p>
        </Wrapper>
    </div>

)
