import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero33 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>33. LA CALUNNIA
            <br/>20 novembre 1911
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1408991905&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p>Dal principio del mondo fino ad oggi ogni Manifestazione di Dio ha trovato opposizione in un’incarnazione
                del «potere delle tenebre».</p>
            <p>Questo potere oscuro ha sempre tentato di estinguere la luce. La tirannia ha sempre provato a sopraffare
                la giustizia. L’ignoranza ha sempre voluto calpestare la sapienza. Questo è stato fin dai tempi più
                remoti il metodo del mondo materiale.</p>
            <p>Ai tempi di Mosè, il Faraone si adoperò di impedire che la Luce di Mosè si diffondesse.</p>
            <p>All’epoca di Gesù, Annas e Caifa incitarono gli ebrei contro di Lui e i sapienti dottori d’Israele si
                unirono per resistere al Suo Potere. Contro di Lui si disseminò ogni specie di calunnia. Gli scribi e i
                farisei cospirarono per far credere al popolo che Gesù era un mentitore, un apostata e un bestemmiatore.
                Divulgarono queste menzogne contro Gesù in tutto il mondo orientale e Lo fecero condannare a una morte
                ignominiosa.</p>
            <p>Anche nel caso di Muḥammad, accadde che i sapienti dottori dei Suoi tempi decidessero di estinguere la
                luce della Sua influenza. Essi tentarono d’impedire che i Suoi insegnamenti si diffondessero perfino con
                le armi.</p>
            <p>Ad onta di tutti i loro sforzi, il Sole della Verità brillò all’orizzonte. L’esercito della luce ha
                sempre sconfitto il potere delle tenebre sul campo di battaglia del mondo e lo splendore degli
                Insegnamenti divini ha illuminato la terra. Coloro che accettarono gli Insegnamenti e lavorarono per la
                Causa di Dio divennero stelle luminose nel firmamento dell’umanità.</p>
            <p>Ora la storia si ripete anche ai nostri giorni.</p>
            <p>Ancora una volta coloro che vorrebbero far credere ai popoli che la religione è una loro proprietà
                privata uniscono i loro sforzi per combattere il Sole della Verità, resistono al comando di Dio e
                inventano calunnie, non avendo né argomenti né prove. Attaccano con la maschera sul volto non avendo il
                coraggio di mostrarsi alla luce del giorno.</p>
            <p>Non si turbino i vostri cuori per questi scritti diffamatori. Obbedite alle parole di Bahá’u’lláh e non
                rispondete. Rallegratevi piuttosto, perché anche queste falsità serviranno alla diffusione della verità.
                Quando si spargono calunnie, si fanno indagini e coloro che indagano sono così portati a conoscere la
                Fede.</p>
            <p>Se un uomo dicesse: «Nella stanza accanto c’è una lampada che non dà luce», alcuni, udendolo, potrebbero
                accontentarsi delle sue parole e credergli. Ma altri più accorti andrebbero nella stanza per vedere coi
                propri occhi e certamente, trovando che la luce brilla luminosa nella lampada, conoscerebbero la
                verità.</p>
            <p>Se poi un uomo dichiarasse: «Vi è un giardino nel quale gli alberi hanno i rami rotti e senza frutti e le
                foglie sono appassite e gialle. In quel giardino vi sono anche piante da fiori senza fiori e rosai
                secchi e morenti. Non andateci». Udendo ciò, una persona saggia non sarebbe soddisfatta se non andasse a
                vedere personalmente se è vero o falso. Ella perciò entrerebbe nel giardino e troverebbe che è ben
                coltivato. I rami degli alberi sono vigorosi e carichi dei più dolci frutti maturi fra il rigoglio del
                bel fogliame verde. Le piante sono adorne di fiori variopinti, i rosai sono coperti di belle rose
                profumate e tutto è verdeggiante e ben curato. Quando la bellezza del giardino si stende davanti agli
                occhi di quella persona saggia, ella ringrazierà Dio di essere stata condotta da una spregevole calunnia
                in un luogo di tale meravigliosa bellezza.</p>
            <p>Questo è il risultato dell’opera di un calunniatore: guidare gli uomini verso la scoperta della
                verità!</p>P
            <p>Sappiamo che tutte le menzogne divulgate su Gesù e sui Suoi apostoli e tutti i libri scritti contro di
                Lui ebbero il solo effetto di indurre il popolo a studiare la Sua dottrina. E allora, avendone visto la
                bellezza e aspirato la fragranza, essi camminarono per sempre fra le rose e i frutti di quel Giardino
                celeste.</p>
            <p>Perciò, io vi dico, divulgate la Verità divina con tutta la vostra forza, perché l’intelligenza degli
                uomini ne sia illuminata. Questa è la migliore risposta da dare ai calunniatori. Non voglio parlare di
                loro né dirne male. Voglio soltanto dirvi che la calunnia non ha alcuna importanza!</p>
            <p>Le nuvole possono velare il sole, ma per quanto dense siano, i suoi raggi le trapasseranno! Niente può
                impedire allo splendore del sole di scaldare e vivificare il Giardino divino.</p>
            <p>Niente può impedire che la pioggia cada dal cielo.</p>
            <p>Niente può impedire l’adempimento della Parola di Dio.</p>
            <p>Pertanto, quando vedete libri e documenti scritti contro la Rivelazione, non ne siate addolorati, ma
                confortatevi nella sicurezza che la Causa ne trarrà forza.</p>
            <p>Non si lanciano sassi contro un albero che non ha frutti! Non si cerca di spegnere una lampada che non dà
                luce!</p>
            <p>Pensate ai tempi passati. Ebbero qualche effetto le calunnie del Faraone? Egli affermava che Mosè era un
                assassino, che aveva ucciso un uomo e che meritava di essere giustiziato! Il Faraone diceva anche che
                Mosè e Aronne erano fomentatori di discordia, che volevano distruggere la religione dell’Egitto e che
                perciò dovevano essere messi a morte. Il Faraone parlò invano. La luce di Mosè brillò; lo splendore
                della Legge di Dio ha avviluppato il mondo!</p>
            <p>I farisei dicevano che Gesù Cristo aveva violato il sabato, che aveva sfidato la legge di Mosè, che aveva
                minacciato di distruggere il Tempio e la sacra città di Gerusalemme e che quindi meritava di essere
                crocifisso. Ma sappiamo che tutti questi calunniosi attacchi non impedirono la diffusione del
                Vangelo!</p>
            <p>Il sole di Gesù Cristo brillò fulgido nel cielo e l’alito dello Spirito Santo spirò su tutta la
                terra!</p>
            <p>Io vi dico che nessuna calunnia può prevalere contro la Luce di Dio. La calunnia non può fare altro che
                renderla ancora più nota a tutti. Quando una causa non ha alcuna importanza, chi si dà la pena di
                lavorare contro di essa?</p>
            <p>Ma accade sempre che più importante è la causa, più il numero dei nemici si fa grande per tentarne la
                rovina! Più splendente è la luce, più oscura è l’ombra! Il nostro compito è quello di agire secondo gli
                insegnamenti di Bahá’u’lláh con umiltà e incrollabile fermezza.</p>

        </Wrapper>
    </div>

)
