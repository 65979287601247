import React from 'react';
// import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes} from "react-router-dom";
import {Home} from './components/Home';
import {Default} from './components/Default';
import {Contact} from './components/Contact';
import {About} from './components/About';
import {NavigationBar} from "./components/NavigationBar";
import {SaggezzaIndex} from "./components/Saggezza/SaggezzaIndex";
import {Numero1} from "./components/Saggezza/Numero1";
import {Numero2} from "./components/Saggezza/Numero2";
import {Numero3} from "./components/Saggezza/Numero3";
import {Numero4} from "./components/Saggezza/Numero4";
import {Numero5} from "./components/Saggezza/Numero5";
import {Numero6} from "./components/Saggezza/Numero6";
import {Numero7} from "./components/Saggezza/Numero7";
import {Numero8} from "./components/Saggezza/Numero8";
import {Numero9} from "./components/Saggezza/Numero9";
import {Numero10} from "./components/Saggezza/Numero10";
import {Numero11} from "./components/Saggezza/Numero11";
import {Numero12} from "./components/Saggezza/Numero12";
import {Numero13} from "./components/Saggezza/Numero13";
import {Numero14} from "./components/Saggezza/Numero14";
import {Numero15} from "./components/Saggezza/Numero15";
import {Numero16} from "./components/Saggezza/Numero16";
import {Numero17} from "./components/Saggezza/Numero17";
import {Numero18} from "./components/Saggezza/Numero18";
import {Numero19} from "./components/Saggezza/Numero19";
import {Numero20} from "./components/Saggezza/Numero20";
import {Numero21} from "./components/Saggezza/Numero21";
import {Numero22} from "./components/Saggezza/Numero22";
import {Numero23} from "./components/Saggezza/Numero23";
import {Numero24} from "./components/Saggezza/Numero24";
import {Numero25} from "./components/Saggezza/Numero25";
import {Numero26} from "./components/Saggezza/Numero26";
import {Numero27} from "./components/Saggezza/Numero27";
import {Numero28} from "./components/Saggezza/Numero28";
import {Numero29} from "./components/Saggezza/Numero29";
import {Numero30} from "./components/Saggezza/Numero30";
import {Numero31} from "./components/Saggezza/Numero31";
import {Numero32} from "./components/Saggezza/Numero32";
import {Numero33} from "./components/Saggezza/Numero33";
import {Numero34} from "./components/Saggezza/Numero34";
import {Numero35} from "./components/Saggezza/Numero35";
import {Numero36} from "./components/Saggezza/Numero36";
import {Numero37} from "./components/Saggezza/Numero37";
import {Numero38} from "./components/Saggezza/Numero38";
import {Numero39} from "./components/Saggezza/Numero39";
import {Numero40} from "./components/Saggezza/Numero40";
import {Numero41} from "./components/Saggezza/Numero41";
import {Numero42} from "./components/Saggezza/Numero42";
import {Numero43} from "./components/Saggezza/Numero43";
import {Numero44} from "./components/Saggezza/Numero44";
import {Numero45} from "./components/Saggezza/Numero45";
import {Numero46} from "./components/Saggezza/Numero46";
import {Numero47} from "./components/Saggezza/Numero47";
import {Numero48} from "./components/Saggezza/Numero48";
import {Numero49} from "./components/Saggezza/Numero49";
import {Numero50} from "./components/Saggezza/Numero50";
import {Numero51} from "./components/Saggezza/Numero51";
import {Numero52} from "./components/Saggezza/Numero52";
import {Numero53} from "./components/Saggezza/Numero53";
import {Numero54} from "./components/Saggezza/Numero54";
import {Numero55} from "./components/Saggezza/Numero55";
import {Numero56} from "./components/Saggezza/Numero56";
import {Numero57} from "./components/Saggezza/Numero57";
import {Numero58} from "./components/Saggezza/Numero58";
import {Numero59} from "./components/Saggezza/Numero59";


const App: React.FC = () => {
  // @ts-ignore
  return (
      <React.Fragment>
        <NavigationBar/>
        {/*<Layout>*/}
        <BrowserRouter basename={"/"}>
          <Routes>
            <Route path={"/"} element={<Home/>}/>;
            <Route path={"contact"} element={<Contact/>}/>;
            <Route path={"about"} element={<About/>}/>;
              <Route path={"/Saggezza/SaggezzaIndex"} element={<SaggezzaIndex/>}/>;
              <Route path={"/Saggezza/Numero1"} element={<Numero1/>}/>;
              <Route path={"/Saggezza/Numero2"} element={<Numero2/>}/>;
              <Route path={"/Saggezza/Numero3"} element={<Numero3/>}/>;
              <Route path={"/Saggezza/Numero4"} element={<Numero4/>}/>;
              <Route path={"/Saggezza/Numero5"} element={<Numero5/>}/>;
              <Route path={"/Saggezza/Numero6"} element={<Numero6/>}/>;
              <Route path={"/Saggezza/Numero7"} element={<Numero7/>}/>;
              <Route path={"/Saggezza/Numero8"} element={<Numero8/>}/>;
              <Route path={"/Saggezza/Numero9"} element={<Numero9/>}/>;
              <Route path={"/Saggezza/Numero10"} element={<Numero10/>}/>;
              <Route path={"/Saggezza/Numero11"} element={<Numero11/>}/>;
              <Route path={"/Saggezza/Numero12"} element={<Numero12/>}/>;
              <Route path={"/Saggezza/Numero13"} element={<Numero13/>}/>;
              <Route path={"/Saggezza/Numero14"} element={<Numero14/>}/>;
              <Route path={"/Saggezza/Numero15"} element={<Numero15/>}/>;
              <Route path={"/Saggezza/Numero16"} element={<Numero16/>}/>;
              <Route path={"/Saggezza/Numero17"} element={<Numero17/>}/>;
              <Route path={"/Saggezza/Numero18"} element={<Numero18/>}/>;
              <Route path={"/Saggezza/Numero19"} element={<Numero19/>}/>;
              <Route path={"/Saggezza/Numero20"} element={<Numero20/>}/>;
              <Route path={"/Saggezza/Numero21"} element={<Numero21/>}/>;
              <Route path={"/Saggezza/Numero22"} element={<Numero22/>}/>;
              <Route path={"/Saggezza/Numero23"} element={<Numero23/>}/>;
              <Route path={"/Saggezza/Numero24"} element={<Numero24/>}/>;
              <Route path={"/Saggezza/Numero25"} element={<Numero25/>}/>;
              <Route path={"/Saggezza/Numero26"} element={<Numero26/>}/>;
              <Route path={"/Saggezza/Numero27"} element={<Numero27/>}/>;
              <Route path={"/Saggezza/Numero28"} element={<Numero28/>}/>;
              <Route path={"/Saggezza/Numero29"} element={<Numero29/>}/>;
              <Route path={"/Saggezza/Numero30"} element={<Numero30/>}/>;
              <Route path={"/Saggezza/Numero31"} element={<Numero31/>}/>;
              <Route path={"/Saggezza/Numero32"} element={<Numero32/>}/>;
              <Route path={"/Saggezza/Numero33"} element={<Numero33/>}/>;
              <Route path={"/Saggezza/Numero34"} element={<Numero34/>}/>;
              <Route path={"/Saggezza/Numero35"} element={<Numero35/>}/>;
              <Route path={"/Saggezza/Numero36"} element={<Numero36/>}/>;
              <Route path={"/Saggezza/Numero37"} element={<Numero37/>}/>;
              <Route path={"/Saggezza/Numero38"} element={<Numero38/>}/>;
              <Route path={"/Saggezza/Numero39"} element={<Numero39/>}/>;
              <Route path={"/Saggezza/Numero40"} element={<Numero40/>}/>;
              <Route path={"/Saggezza/Numero41"} element={<Numero41/>}/>;
              <Route path={"/Saggezza/Numero42"} element={<Numero42/>}/>;
              <Route path={"/Saggezza/Numero43"} element={<Numero43/>}/>;
              <Route path={"/Saggezza/Numero44"} element={<Numero44/>}/>;
              <Route path={"/Saggezza/Numero45"} element={<Numero45/>}/>;
              <Route path={"/Saggezza/Numero46"} element={<Numero46/>}/>;
              <Route path={"/Saggezza/Numero47"} element={<Numero47/>}/>;
              <Route path={"/Saggezza/Numero48"} element={<Numero48/>}/>;
              <Route path={"/Saggezza/Numero49"} element={<Numero49/>}/>;
              <Route path={"/Saggezza/Numero50"} element={<Numero50/>}/>;
              <Route path={"/Saggezza/Numero51"} element={<Numero51/>}/>;
              <Route path={"/Saggezza/Numero52"} element={<Numero52/>}/>;
              <Route path={"/Saggezza/Numero53"} element={<Numero53/>}/>;
              <Route path={"/Saggezza/Numero54"} element={<Numero54/>}/>;
              <Route path={"/Saggezza/Numero55"} element={<Numero55/>}/>;
              <Route path={"/Saggezza/Numero56"} element={<Numero56/>}/>;
              <Route path={"/Saggezza/Numero57"} element={<Numero57/>}/>;
              <Route path={"/Saggezza/Numero58"} element={<Numero58/>}/>;
              <Route path={"/Saggezza/Numero59"} element={<Numero59/>}/>;
            <Route path={"*"} element={<Default/>}/>;
          </Routes>
        </BrowserRouter>

        {/*</Layout>*/}
      </React.Fragment>
  );
};
/*function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}*/

export default App;
