import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";

// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero1 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>1. IL DOVERE DELLA GENTILEZZA E DELLA BENEVOLENZA VERSO GLI ESTRANEI E GLI STRANIERI
            <br/>16-17 ottobre 1911
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1375821226&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p>
                Quando volge lo sguardo verso Dio l’uomo trova tutt’intorno lo splendore del sole. Tutti gli uomini sono
                suoi fratelli. Quando incontrate strane persone di altri paesi, non permettete che le convenzioni
                sociali vi facciano apparire freddi e poco cordiali. Non guardateli con diffidenza come se aveste il
                sospetto che siano malvagi, disonesti e volgari. Credete che sia necessario essere molto cauti, non
                esporvi al rischio di fare conoscenza con questa gente forse indesiderabile.
                Io vi chiedo di non pensare solo a voi stessi! Siate benevoli con gli stranieri, vengano essi dalla
                Turchia, dal Giappone, dalla Persia, dalla Russia, dalla Cina o da qualunque altro paese del mondo.
                Aiutateli a sentirsi a casa loro. Scoprite dove stanno. Domandate se potete servirli. Cercate di
                rallegrare la loro vita.
                In questo modo, anche se talvolta ciò che avete sospettato è vero, sforzatevi ugualmente di cambiare
                modi e di essere gentili con loro. Questa vostra gentilezza li aiuterà a diventare migliori.
                Dopo tutto, perché trattare gli stranieri come estranei?
            </p>
            <p>
                A chi vi incontra fate sapere, senza proclamarlo, che siete un vero bahá’í.
                Mettete in pratica l’Insegnamento di Bahá’u’lláh: quello della gentilezza verso tutte le nazioni. Non vi
                accontentate di mostrare amicizia solamente a parole. Fate che il vostro cuore arda di amorevole
                gentilezza per tutti quelli che incontrate sul vostro cammino.
                Voi delle nazioni occidentali, siate gentili con quelli che vengono dall’Oriente ad abitare fra voi!
                Dimenticate le vostre convenzioni sociali quando parlate con loro. Essi non vi sono abituati. Agli
                orientali questo contegno convenzionale sembra freddo, ostile. Siate affabili. Fate vedere che siete
                pieni d’amore universale. Quando incontrate un persiano, o qualunque altro straniero, parlategli come a
                un amico. Se sembra sentirsi solo, cercate di aiutarlo, offritegli il vostro buon servigio. Se è triste,
                consolatelo, se è povero, siate caritatevoli con lui, se è oppresso, liberatelo, se è scoraggiato,
                confortatelo. In questo modo manifesterete che non a parole soltanto, ma a fatti e in verità, voi
                considerate tutti gli uomini come fratelli vostri.
                A che cosa serve riconoscere la bontà dell’amicizia universale e parlare della solidarietà della razza
                umana come d’un grande ideale? Se non sono messi in pratica, questi pensieri sono vani.
                Il male continua a esistere nel mondo appunto perché dei propri ideali si parla soltanto, senza mai fare
                uno sforzo per attuarli. Se le azioni sostituissero le parole, la miseria del mondo si muterebbe assai
                presto in benessere.

            </p>
            <p>
                L’uomo che pratica il bene e non ne parla è sulla via della perfezione.
            </p>
            <p> L’uomo, che avendo fatto un po’ di bene, se ne vanta magnificandolo, vale assai poco.
            </p>
            <p>
                Se vi amo non occorre che ve lo dica continuamente. Lo saprete senza bisogno di parole. Ma se non vi
                amassi, ve ne accorgereste e, se anche vi dicessi mille volte che vi amo, non mi credereste.
            </p>
            <p>
                Alcuni fanno professione di bontà dicendo e ripetendo molte belle parole, ma in verità desiderano
                soltanto essere ritenuti più grandi e più buoni degli altri e cercano fama agli occhi del mondo. Chi fa
                molto bene, meno spende parole sulle proprie azioni.
            </p>
            <p>
                I figlioli di Dio fanno le buone opere senza vantarsi, in obbedienza alle Sue leggi.
            </p>
            <p>
                La mia speranza per voi è che evitiate sempre la tirannia e l’oppressione, che non cessiate di lavorare
                finché la giustizia non regni in ogni terra, che manteniate puri i vostri cuori e le mani vostre lontane
                dall’iniquità.
            </p>
            <p>
                Questo dovete fare per avvicinarvi a Dio e questo mi aspetto da voi.
            </p>
        </Wrapper>
    </div>

)
