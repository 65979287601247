import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero58 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>58. LE QUATTRO SPECIE D’AMORE
            <br/>Cadogan Gardens 97, Londra
            <br/>Sabato, 4 gennaio 1912
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1420710283&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p>Quale forza è l’amore! È il più bello, il più grande di tutte le forze.</p>
            <p>L’amore dà vita agli inerti. L’amore accende una fiamma nel cuore freddo. L’amore porta la speranza a chi
                non l’ha e rallegra i cuori degli afflitti.</p>
            <p>Nel mondo dell’esistenza non c’è in verità una forza più grande di quella dell’amore. Quando la fiamma
                dell’amore è accesa nel suo cuore, l’uomo è pronto a sacrificare tutto, anche la vita. Nel Vangelo è
                detto che Dio è amore.</p>
            <p>Vi sono quattro specie d’amore. È della prima specie l’amore che viene da Dio all’uomo. Quest’amore
                consiste nelle grazie inesauribili, nello splendore divino, nell’illuminazione celeste. Da quest’amore
                il mondo dell’essere riceve la vita. Per esso l’uomo è dotato dell’esistenza fisica, finché per mezzo
                dell’alito dello Spirito Santo (che è poi questo stesso amore), riceve la vita eterna e diventa
                l’immagine del Dio Vivente. Quest’amore è l’origine di tutto l’amore nel mondo della creazione.</p>
            <p>Appartiene alla seconda specie l’amore che va dall’uomo a Dio. Esso è fede, attrazione al Divino, ardore,
                progresso, ingresso nel Regno di Dio, ricezione delle Munificenze di Dio, illuminazione con le luci del
                Regno. Quest’amore è l’origine di tutta la filantropia e fa sì che i cuori umani riflettano i raggi del
                Sole della Realtà.</p>
            <p>È della terza specie l’amore di Dio verso l’Io o l’Identità di Dio. Esso è la trasfigurazione della Sua
                bellezza, la riflessione di Se stesso nello specchio della Sua creazione. Esso è la realtà dell’amore,
                l’antico Amore, l’eterno Amore. Da un raggio di quest’Amore trae esistenza ogni altro amore.</p>
            <p>Alla quarta specie appartiene l’amore dell’uomo per l’uomo. L’amore che esiste fra i cuori dei credenti è
                prodotto dall’ideale dell’unità degli spiriti. Quest’amore si ottiene attraverso la conoscenza di Dio,
                così che gli uomini vedono l’amore divino riflesso nei cuori. Ognuno vede nell’altro la Bellezza di Dio
                riflessa nell’anima e, trovando questo punto di somiglianza, essi sono attratti l’uno verso l’altro con
                amore. Quest’amore rende tutti gli uomini onde dello stesso mare, stelle dello stesso cielo e frutti
                dello stesso albero. Quest’amore porta la realizzazione del vero accordo, la base della vera unità.</p>
            <p>Ma l’amore che spesso esiste fra amici non è vero amore, perché è soggetto a trasmutazione, non è che
                infatuazione. Quando soffia la brezza, gli alberi sottili si piegano. Se il vento viene da Oriente,
                l’albero si piega verso Occidente e se il vento cambia e soffia da Occidente, l’albero si piega verso
                Oriente. Questa specie d’amore ha origine dalle condizioni accidentali della vita. Non è vero amore, è
                semplice dimestichezza e può cambiare.</p>
            <p>Oggi vedete due anime apparentemente legate da stretta amicizia. Domani tutto ciò può cambiare. Ieri quei
                due esseri erano pronti a morire l’uno per l’altro, oggi non si vogliono nemmeno vedere. Questo non è
                amore, è cedimento dei cuori ai casi della vita. Quando ciò che ha prodotto questo «amore» è passato,
                anche l’amore passa. Dunque questo non è in verità amore.</p>
            <p>L’amore è soltanto delle quattro specie che ho indicato. (a) L’amore di Dio per la Sua identità. Gesù ha
                detto che Dio è Amore. (b) L’amore di Dio per i Suoi figliuoli, per i Suoi servi. (c) L’amore dell’uomo
                per Dio. (d) L’amore dell’uomo per l’uomo. Queste quattro specie d’amore hanno origine da Dio, sono i
                raggi del Sole della Realtà, sono gli Aliti dello Spirito Santo, sono i segni della Realtà di Dio.</p>

        </Wrapper>
    </div>

)
