import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero22 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>22. I DUE GENERI DI LUCE
            <br/>5 novembre 1911
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1388416927&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p>Oggi il tempo è buio e uggioso. Nell’Oriente il sole brilla sempre, le stelle non sono mai velate, ci
                sono pochissime nuvole. La luce sorge sempre dall’Oriente e manda il suo splendore in Occidente.</p>
            <p>Vi sono due generi di luce: C’è la luce visibile del sole, per la quale possiamo vedere la bellezza del
                mondo intorno a noi. Senza questa luce non potremmo vedere niente.</p>
            <p>Tuttavia, benché la sua funzione sia di renderci visibile ogni cosa, questa luce non può darci il potere
                di vedere le cose e di comprenderne le diverse bellezze, perché questa luce non ha né intelligenza né
                coscienza. È la luce dell’intelletto che ci dà modo di comprendere e di sapere. Senza questa luce
                l’occhio fisico sarebbe inutile.</p>
            <p>La luce dell’intelletto è la luce suprema, perché nasce dalla Luce divina.</p>
            <p>La luce dell’intelletto ci permette di comprendere e concepire tutto ciò che esiste. Ma è soltanto la
                luce divina che può farci vedere le cose invisibili e che ci permette di vedere quelle verità che
                saranno visibili al mondo soltanto fra migliaia di anni.</p>
            <p>Fu la Luce divina che permise ai profeti di vedere duemila anni fa ciò che sarebbe accaduto e oggi
                vediamo che la loro visione si è avverata. È dunque questa Luce che dobbiamo cercare, perché è più
                potente di qualsiasi altra.</p>
            <p>Fu per mezzo di questa Luce che Mosè poté comprendere l’Apparizione divina e udire la Voce celeste che
                Gli parlò dal Roveto ardente (Esodo III, 2).</p>
            <p>È di quella luce che parla Muḥammad quando dice: «Alláh è la luce dei cieli e della terra».</p>
            <p>Cercate con tutto il cuore questa Luce celeste, affinché possiate comprendere le verità divine e
                conoscere i misteri di Dio, affinché le vie nascoste possano aprirsi davanti ai vostri occhi.</p>
            <p>Questa Luce si può paragonare a uno specchio. Come uno specchio riflette tutto ciò che gli sta davanti,
                così questa Luce mostra agli occhi del nostro spirito tutto ciò che esiste nel Regno di Dio e ci fa
                conoscere la realtà delle cose. Con l’aiuto di questa Luce fulgente, l’interpretazione spirituale di
                tutte le Scritture sacre è diventata chiara, le cose nascoste dell’Universo di Dio sono state rese
                manifeste e ci è stato permesso di comprendere i propositi divini riguardo per l’uomo.</p>
            <p>Prego che Dio, nella Sua misericordia, voglia illuminare i vostri cuori e le vostre anime con la Sua Luce
                gloriosa. Allora ciascuno di voi brillerà come una stella raggiante negli oscuri luoghi del mondo.</p>
        </Wrapper>
    </div>

)
