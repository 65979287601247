import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero19 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>19. IL PROGRESSO MATERIALE E SPIRITUALE
            <br/>2 novembre 1911
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1388414932&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p>‘Abdu’l-Bahá disse:<br/>
                Oggi il tempo è bello, il cielo è limpido, il sole risplende e il cuore dell’uomo ne è rallegrato!</p>
            <p>Un’atmosfera così chiara e bella dà nuova vita e forza all’uomo e, pur ammalato, egli risente nel cuore
                la dolce speranza della guarigione. Tutti questi doni della natura riguardano soltanto il lato fisico
                dell’uomo, perché solo il corpo può ricevere benefici materiali.</p>
            <p>Se riesce nei suoi affari, nella sua arte o nella sua professione, un uomo può aumentare il proprio
                benessere fisico e dare al proprio corpo agi e comodità nei quali si compiace. Vediamo oggi intorno a
                noi che l’uomo si circonda di ogni lusso e di tutte le comodità moderne e che non nega niente al lato
                fisico e materiale della sua natura. Ma state attenti che pensando troppo al benessere del corpo non vi
                accada di dimenticare le cose dell’anima, perché i vantaggi materiali non elevano lo spirito dell’uomo.
                La perfezione nelle cose del mondo è una gioia per il corpo dell’uomo, ma non può in alcun modo elevare
                la sua anima.</p>
            <p>Può darsi che ad un uomo che gode di ogni beneficio materiale e che vive circondato da tutte le comodità
                che la civiltà moderna può dare, sia invece negato il vero e importantissimo dono dello Spirito
                Santo.</p>
            <p>È cosa bella e lodevole progredire materialmente, ma ciò facendo non bisogna trascurare il progresso più
                importante, quello spirituale, e non bisogna chiudere gli occhi alla luce divina che brilla fra noi.</p>
            <p>Soltanto migliorandoci tanto spiritualmente quanto materialmente potremo fare un vero progresso e
                diventare esseri perfetti. Fu per portare la luce e la vita spirituale al mondo che apparvero tutti i
                grandi Maestri. Essi vennero affinché il Sole della Verità potesse manifestarsi e brillare nel cuore
                degli uomini e affinché grazie al suo potere meraviglioso gli uomini potessero giungere alla Luce
                eterna.</p>
            <p>Quando venne, nostro Signore Gesù Cristo diffuse la Luce dello Spirito Santo sopra tutti coloro che erano
                intorno a Lui e i Suoi discepoli e tutti quelli che ricevettero il Suo splendore diventarono esseri
                illuminati, spirituali.</p>
            <p>È per manifestare questa luce che Bahá’u’lláh è nato ed è venuto in questo mondo. Egli insegnò agli
                uomini la Verità eterna e diffuse i raggi della Luce divina su tutta la terra.</p>
            <p>Ahimè! Guardate come l’uomo disprezza questa Luce! Egli continua a camminare sulla sua via tenebrosa e la
                discordia, le liti e le guerre feroci continuano a prevalere.</p>
            <p>L’uomo adopera il suo progresso materiale per soddisfare la sua smania di guerra e costruisce strumenti
                distruttivi e li usa per sterminare i suoi fratelli.</p>
            <p>Facciamo invece ogni sforzo per conseguire vantaggi spirituali, perché questa è la sola via del vero
                progresso, un progresso che viene da Dio ed è solo buono.</p>
            <p>Io prego affinché voi tutti possiate ricevere i doni dello Spirito Santo. Così sarete in verità
                illuminati e progredirete andando sempre avanti e sempre più in alto verso il Regno di Dio. Allora i
                vostri cuori saranno pronti a ricevere le buone novelle, i vostri occhi si apriranno e vedrete la Gloria
                di Dio, i vostri orecchi non saranno sordi e udirete l’appello del Regno e con parola divenuta eloquente
                inviterete gli uomini a riconoscere il Potere divino e l’Amore di Dio.</p>
        </Wrapper>
    </div>

)
