import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero12 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>12. LE NUVOLE CHE VELANO IL SOLE DELLA VERITÀ
            <br/>Avenue de Camoëns 4
            <br/>venerdì mattina, 27 ottobre 1911
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1386993283&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p>È una bella giornata; l’aria è pura, il sole risplende, la nebbia e le nuvole non offuscano
                l’orizzonte.</p>
            <p>Questi raggi brillanti penetrano in ogni parte della città. Così possa il Sole della Verità illuminare le
                menti degli uomini.</p>
            <p>Cristo disse: «Vedrete il Figliuol dell’uomo venire nelle nuvole del Cielo» (Marco XIV, 62.). Bahá’u’lláh
                disse: «Quando venne la prima volta, Cristo venne sopra le nuvole» (Giovanni III, 13). Gesù disse che
                veniva dal cielo, che veniva da Dio, mentre era nato da Maria, Sua madre. Ma quando dichiarò di essere
                venuto dal Cielo, non volle certo parlare dell’azzurro firmamento, parlava del Cielo del Regno di Dio.
                Da quel cielo Egli discese «sopra le nuvole». Come le nuvole sono un ostacolo al fulgore del sole, così
                le nuvole del mondo dell’umanità nascosero agli occhi degli uomini lo splendore della Divinità di
                Cristo.</p>
            <p>Gli uomini dissero: «Egli è di Nazareth, è nato da Maria, Lo conosciamo e conosciamo i Suoi fratelli. Che
                cosa vuol farci credere? Che cosa dice? Che è venuto da Dio?» (Giovanni VI, 42).</p>
            <p>Il corpo di Gesù nacque da Maria di Nazareth, ma lo spirito venne da Dio. Il potere del Suo corpo umano
                era limitato, ma la forza dello spirito Suo era vasta, incommensurabile, infinita.</p>
            <p>Gli uomini si domandarono «Perché dice di essere venuto da Dio?». Se avessero compreso la realtà di
                Cristo, avrebbero saputo che il Suo corpo di uomo era una nuvola che celava la Sua divinità. Il mondo
                vide soltanto la Sua forma umana e perciò si domandò come avesse potuto «discendere dal Cielo».</p>
            <p>Bahá’u’lláh disse: «Come le nuvole nascondono il sole e il cielo alla nostra vista, così la forma umana
                di Cristo nascose agli uomini la Sua versa divina essenza».</p>
            <p>Spero che vi volgiate verso il Sole della Verità con occhi sgombri, che non guardiate alle cose della
                terra, affinché i vostri cuori non siano attratti dai vani ed effimeri piaceri del mondo. Lasciate che
                quel Sole vi dia della Sua forza, allora le nuvole del pregiudizio non nasconderanno ai vostri occhi lo
                splendore Suo! Allora il Sole sarà senza nuvole per voi.</p>
            <p>Respirate aria pura. Possa ognuno di voi avere la propria parte dei doni divini del Regno dei cieli.
                Possa il mondo non essere per voi un ostacolo che nasconde la verità alla vostra vista, come il corpo
                umano di Cristo nascose la Sua divinità alla gente dei Suoi giorni. Possiate ricevere la chiara visione
                dello Spirito Santo, così che i vostri cuori siano illuminati e riconoscano il Sole della Verità che
                penetra attraverso tutte le nubi materiali, che inonda col suo splendore tutto l’universo.</p>
            <p>Non permettete che le cose del corpo oscurino la luce celestiale dello Spirito, così che con l’aiuto
                della Provvidenza divina possiate entrare coi figliuoli di Dio nel Suo Regno Eterno.</p>
            <p>Questa è la mia preghiera per voi tutti.</p>
        </Wrapper>
    </div>

)
