import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero51 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>51. L’UNDICESIMO PRINCIPIO: IL POTERE DELLO SPIRITO SANTO
            <br/>Avenue de Camoëns 4, Parigi
            <br/>18 novembre 1911
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1420705498&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p>Negli insegnamenti di Bahá’u’lláh è scritto: «Soltanto con il potere dello Spirito Santo l’uomo può
                progredire, perché la Potenza divina è infinita». La lettura della storia ci porta al concludere che
                tutti gli uomini veramente grandi, i benefattori della razza umana, coloro che hanno spinto gli uomini
                ad amare la giustizia e odiare l’ingiustizia e sono stati causa di un reale progresso sono stati tutti
                ispirati dalla forza dello Spirito Santo.</p>
            <p>Nessuno dei Profeti di Dio si è diplomato nelle scuole di una dotta filosofia. Anzi spesso essi sono
                stati uomini di umile nascita, apparentemente ignoranti, uomini sconosciuti e di nessuna importanza agli
                occhi del mondo, talvolta incapaci persino di leggere e scrivere.</p>
            <p>Ciò che innalzò questi grandi al di sopra degli uomini e per cui essi poterono diventare Maestri di
                verità fu il potere dello Spirito Santo. La loro influenza sull’umanità, in virtù di questa possente
                ispirazione, fu grande e penetrante.</p>
            <p>L’influenza dei più saggi filosofi, privi di questo Spirito divino, è stata relativamente di poca
                importanza malgrado la vastità della loro dottrina e la profondità della loro erudizione.</p>
            <p>Per esempio, gli eccezionali intelletti di Platone, Aristotele, Plinio e Socrate non hanno influenzato
                gli uomini a tal punto da renderli ansiosi di sacrificare la vita per i loro insegnamenti. E invece
                alcuni di quegli uomini semplici hanno scosso l’umanità al punto che migliaia di persone sono divenute
                martiri volontari per difendere le loro Parole, perché queste parole erano ispirate dal divino Spirito
                di Dio. I profeti di Giuda e d’Israele, Elia, Geremia, Isaia, Ezechiele, erano uomini umili come lo
                erano anche gli Apostoli di Gesù Cristo.</p>
            <p>Pietro, il capo degli Apostoli, usava dividere i frutti della pesca in sette parti e quando, dopo aver
                preso una parte per l’uso quotidiano, arrivava alla settima razione, sapeva che era sabato. Riflettete e
                pensate alla sua futura posizione e alla gloria che egli conseguì perché lo Spirito Santo operò grandi
                cose per mezzo suo.</p>
            <p>Ne deduciamo che lo Spirito Santo è il fattore dinamico della vita umana. Chiunque riceve questo potere
                può influenzare tutti coloro con cui viene a contatto.</p>
            <p>I massimi filosofi privi di questo Spirito sono impotenti, le anime senza vita, i cuori inerti! Se lo
                Spirito Santo non alita nelle loro anime, essi non possono operare bene. Nessun sistema di filosofia è
                stato mai capace di cambiare in meglio gli usi e i costumi di un popolo. Dotti filosofi, non illuminati
                dallo Spirito divino, sono stati uomini spesso di moralità inferiore, non hanno manifestato nelle azioni
                la realtà dei loro bei discorsi.</p>
            <p>La differenza fra i filosofi spirituali e gli altri è dimostrata dalle loro vite. Il Maestro spirituale
                mostra la sua fede nei suoi insegnamenti, essendo lui stesso ciò che raccomanda gli altri.</p>
            <p>Un uomo umile, incolto, ma colmo dello Spirito Santo, è più potente di un profondo dotto di nobili natali
                privo di quella ispirazione. Colui che è istruito dallo Spirito divino può nella sua vita guidare altri
                a ricevere lo stesso Spirito.</p>
            <p>Prego che siate imbevuti della vita dello Spirito divino, in modo che diveniate strumenti per educare
                altri. La vita e la moralità di un nomo spirituale sono esse stesse un’educazione per quelli che lo
                conoscono.</p>
            <p>Non pensate alle vostre limitazioni, soffermatevi soltanto sul benessere del Regno della gloria.
                Considerate l’influenza di Gesù Cristo sui Suoi apostoli e pensate poi al loro effetto sul mondo. Questi
                uomini semplici furono resi capaci di diffondere la buona novella dal potere dello Spirito Santo!</p>
            <p>Possiate così voi tutti ricevere l’aiuto divino! Nessuna capacità è limitata se è guidata dallo Spirito
                di Dio!</p>
            <p>a terra in se stessa non ha proprietà di vita, è desolata e arida, finché non sia fertilizzata dal sole e
                dalla pioggia. Ma la terra non ha bisogno di lamentare la sue limitazioni.</p>
            <p>Possa esservi donata la vita! Possano la pioggia della grazia divina e il calore del Sole della Verità
                rendere fruttuosi i vostri giardini, così che vi sboccio in abbondanza molti bei fiori di squisita
                fragranza e d’amore. Distogliete il volto dalla contemplazione del vostro io finito e fissate gli occhi
                sulla Rdiosità eterna. Allora le vostre anime riceveranno in piena misura il divino Potere dello Spirito
                e le Benedizioni della Grazia infinita.</p>
            <p>Se vi terrete così pronti, diverrete per il mondo dell’umanità una fiamma ardente, una stella che guida e
                un albero fecondo, mutando ogni oscurità e afflizione in luce e gioia per lo splendore del Sole della
                Misericordia e le infinite benedizioni della Buona Novella.</p>
            <p>Questo è il significato del potere dello Spirito Santo, che io prego sia generosamente profuso su
                voi.</p>
        </Wrapper>
    </div>

)
