import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero34 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>34. NON VI POSSONO ESSERE VERA FELICITÀ E PROGRESSO SENZA SPIRITUALITÀ
            <br/>21 novembre 1911
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1408992877&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p>La crudeltà e la ferocia sono naturali per gli animali, ma gli uomini dovrebbero mostrare amore e
                affetto. Dio mandò nel mondo i Suoi Profeti con uno scopo: seminare amore e benevolenza nei cuori degli
                uomini e per questo ideale essi furono pronti a soffrire e morire. Tutti i Libri sacri furono scritti
                per guidare l’uomo nelle vie dell’amore e dell’umiltà. Eppure malgrado tutto ciò, abbiamo fra noi il
                triste spettacolo della guerra e dello spargimento di sangue.</p>
            <p>Quando guardiamo alle pagine della storia, passata e presente, vediamo la nera terra arrossata dal sangue
                umano. Gli uomini si ammazzano fra loro come lupi feroci e dimenticano le leggi dell’amore e della
                tolleranza.</p>
            <p>Ora è venuta quest’era luminosa che ha portato con sé una meravigliosa civiltà e un progresso materiale.
                L’intelletto umano si è ampliato, le sue percezioni si sono sviluppate, ma nonostante tutto questo, ogni
                giorno si versa nuovo sangue. Pensate alla presente guerra italoturca. Considerate un momento la sorte
                di quei popoli infelici! Quante vite sono state stroncate in questi tristi giorni! Quanti focolari
                rovinati, quante mogli desolate e quanti orfani! E che cosa si guadagna in cambio di tanta angoscia e di
                tanto cordoglio? Soltanto un pezzo di terra!</p>
            <p>Questo stato di cose dimostra che il progresso materiale non tende a elevare l’uomo. Al contrario più
                egli si immerge nel progresso materiale, più la sua natura spirituale si oscura.</p>
            <p>Nei tempi passati il progresso materiale non era cosi rapido né vi erano spargimenti di sangue in tale
                profusione. Nelle guerre dell’antichità non c’erano cannoni, fucili, dinamite, bombe. Non c’erano
                torpediniere, corazzate, sottomarini. Ora grazie alla civiltà materiale, abbiamo tutte queste invenzioni
                e la guerra va di male in peggio. L’Europa è diventata come un immenso arsenale pieno di esplosivi e
                voglia Iddio impedire che vada a fuoco, perché se questo dovesse avvenire tutto il mondo vi sarebbe
                coinvolto.</p>
            <p>Desidero farvi capire che il progresso materiale e il progresso spirituale sono due cose del tutto
                diverse e che soltanto se il progresso materiale va di pari passo con la spiritualità si può avere il
                vero progresso e la Più Grande Pace può regnare nel mondo. Se gli uomini seguissero i Consigli divini e
                gli Insegnamenti dei Profeti, se la luce divina splendesse in tutti i cuori e gli uomini fossero
                veramente religiosi, vedremmo presto la pace sulla terra e il Regno di Dio fra gli uomini. Le leggi di
                Dio possono paragonarsi all’anima e il progresso materiale al corpo. Se gli viene a mancare l’anima, il
                corpo cessa di esistere. Prego ardentemente che la spiritualità cresca sempre e si espanda nel mondo,
                cosicché i costumi migliorino e regnino la concordia e la pace.</p>
            <p>La guerra e la rapina, con le crudeltà che le accompagnano, sono abominate da Dio e portano con sé una
                loro punizione, perché l’Iddio dell’amore è anche l’Iddio della giustizia e ogni uomo deve
                inevitabilmente raccogliere ciò che semina. Cerchiamo di comprendere i comandamenti dell’Altissimo e di
                ordinare le nostre vite secondo la Sua volontà. La vera felicità dipende dai beni spirituali e dall’aver
                sempre il cuore aperto per ricevere la Munificenza divina.</p>
            <p>Se il cuore si chiude alle benedizioni che Dio offre, come può sperare di ottenere la felicità? Se il
                cuore non pone la sua speranza e la sua fede nella misericordia di Dio, dove può trovare riposo? Abbiamo
                fede in Dio! La Sua munificenza è eterna e la Sua grazia è infinita. Abbiate fede nell’Onnipotente! Egli
                non manca mai e la Sua bontà dura per sempre. Il Suo Sole dà continuamente luce e le Nuvole della Sua
                misericordia sono gravide delle Acque della Compassione con le quali Egli irrora i cuori di tutti coloro
                che hanno fede in Lui. L’aura Sua vivificante porta sempre sulle sue ali la guarigione alle inaridite
                anime degli uomini. È dunque saggio allontanarsi da un Padre cosi amoroso, che fa piovere su di noi le
                Sue benedizioni e preferire invece di essere schiavi della materia?</p>
            <p>Dio nella Sua bontà infinita ci ha innalzati a tanto onore, facendoci signori del mondo della materia.
                Vorremo invece diventarne schiavi? No, rivendichiamo piuttosto il nostro diritto e cerchiamo di vivere
                la vita dei figli spirituali di Dio. Il glorioso Sole della Verità è risorto in Oriente. Dal lontano
                orizzonte della Persia il Suo splendore si diffonde dappertutto, disperdendo le dense nuvole della
                superstizione. La luce dell’unità del genere umano incomincia a illuminare il mondo e presto il vessillo
                dell’armonia divina e della solidarietà delle nazioni sventolerà in alto nei cieli. L’alito dello
                Spirito Santo ispirerà tutto il mondo.</p>
            <p>O popoli e nazioni! Sorgete e lavorate e siate felici! Adunatevi sotto il baldacchino dell’unità del
                genere umano!</p>

        </Wrapper>
    </div>

)
