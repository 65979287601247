import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero35 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>35. IL DOLORE E LA TRISTEZZA
            <br/>22 novembre 1911
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1408993483&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p>In questo mondo siamo influenzati da due sentimenti: la gioia e il dolore.</p>
            <p>La gioia ci dà le ali! Nelle ore di gioia le forze sono più vive, l’intelletto è più acuto, la
                comprensione più chiara. Sembriamo saper meglio trattare col mondo e trovare la nostra sfera d’utilità.
                Ma quando la tristezza ci invade, diventiamo deboli, le forze ci abbandonano. La nostra comprensione è
                offuscata e l’intelligenza è velata. Le cose della vita sembrano sfuggirci, gli occhi del nostro spirito
                non riescono a scoprire i sacri misteri e diventiamo come esseri morti.</p>
            <p>Non c’è essere umano che non sia toccato da queste due influenze. Ma tutta la tristezza e il dolore che
                esistono provengono dal mondo della materia, il mondo spirituale dà soltanto la gioia!</p>
            <p>Se soffriamo, la sofferenza è il risultato delle cose materiali. Tutte le prove e tutte le difficoltà
                vengono da questo mondo d’illusione.</p>
            <p>Per esempio, un commerciante può perdere la clientela e questo fatto produce in lui uno stato di
                depressione. Un operaio è licenziato ed è colto dall’incubo della fame. Un agricoltore ha un cattivo
                raccolto e vive in grande ansia. Un uomo costruisce una casa che un incendio rade al suolo. Ed eccolo
                senza tetto, rovinato e disperato.</p>
            <p>Tutti questi esempi servono a dimostrarvi che le difficoltà che incontriamo ad ogni passo, il dolore, il
                dispiacere, la vergogna e la tristezza si generano nel mondo della materia. Dal Regno spirituale non
                viene mai tristezza. Un uomo che vive concentrando i propri pensieri su quel Regno conosce un’eterna
                gioia. I mali cui è soggetta la carne non lo toccano, ma sfiorano soltanto la superficie della sua vita,
                che in fondo rimane calma e serena.</p>
            <p>Oggi l’umanità è prostrata dalle difficoltà, dalla tristezza e dal dolore e nessuno sfugge. Il mondo è
                intriso di lagrime, ma, grazie a Dio, il rimedio è alle porte. Allontaniamo il cuore dal mondo della
                materia e viviamo nel mondo dello spirito! È l’unico mezzo per ottenere la libertà! Se siamo incalzati
                da difficoltà, non dobbiamo fare altro che rivolgerci a Dio e dalla Sua grande misericordia riceveremo
                aiuto.</p>
            <p>Nella tristezza e nell’avversità volgiamo il viso verso il Regno di Dio e la consolazione celeste
                scenderà su di noi.</p>
            <p>Se siamo ammalati e soffriamo, chiediamo a Dio la guarigione ed Egli esaudirà la nostra preghiera.</p>
            <p>Quando i nostri pensieri sono invasi dalle amarezze di questo mondo, volgiamo lo sguardo alla dolcezza
                della compassione di Dio ed Egli ci manderà la calma celeste. Se siamo imprigionati nel mondo della
                materia, i nostri spiriti possono volare al Cielo e allora saremo veramente liberi!</p>
            <p>Quando la vita si avvicina alla fine, pensiamo ai mondi eterni e ci riempiremo di gioia!</p>
            <p>Vedete ovunque prove dell’insufficienza delle cose materiali. Vedete che la gioia, il benessere, la pace,
                la consolazione non si trovano nelle cose transitorie del mondo. Non è dunque stoltezza rifiutarsi di
                cercare questi tesori là dove è possibile trovarli? Le porte del Regno spirituale sono aperte a tutti e
                fuori di esse vi sono le tenebre assolute.</p>
            <p>Grazie a Dio, voi, in questa assemblea, ne siete consapevoli e potete così in ogni dolorosa circostanza
                della vita ottenere il conforto supremo. Se i vostri giorni sulla terra sono contati, sapete che la vita
                eterna vi aspetta. Se le ansietà materiali vi avvolgono in una nube oscura, lo splendore dello spirito
                illumina la vostra via. In verità, coloro che hanno la mente rischiarata dallo Spirito dell’Altissimo
                hanno la suprema consolazione.</p>
            <p>Io rimasi in carcere per quarant’anni, un anno solo sarebbe stato già troppo da sopportare, nessuno
                sopravvisse più di un anno a tale prigionia. Ma, grazie a Dio, in quei quarant’anni sono stato
                supremamente felice! Ogni giorno, svegliandomi, era come se ricevessi una lieta notizia e ogni notte
                sentivo una gioia infinita. La spiritualità era il mio conforto e la mia più grande gioia era volgermi a
                Dio. Se non fosse stato così, credete che avrei potuto sopportare quei quarant’anni di prigionia?</p>
            <p>La spiritualità è il più grande dono di Dio, e «vita eterna» significa «volgersi a Dio». Possa ognuno di
                voi crescere ogni giorno nella spiritualità, essere rafforzato nella bontà, aiutato sempre più dalla
                consolazione divina, essere reso libero dal Santo Spirito di Dio e possa il potere del Regno celeste
                vivere e operare fra voi.</p>
            <p>Questo è il mio ardente desiderio e prego Dio di concedervi questi favori.</p>

        </Wrapper>
    </div>

)
