import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero53 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>53. L’ULTIMA RIUNIONE
            <br/>Rue Greuze, 15, Parigi
            <br/>1° dicembre 1911
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1420706764&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p>La prima volta che arrivai a Parigi, qualche tempo fa, mi guardai attorno con grande interesse e nella
                mia mente paragonai questa bella città a un grande giardino.</p>
            <p>Con amorosa cura e profondo pensiero, esaminai il terreno e lo trovai ottimo e molto adatto per produrre
                una salda fede e ferme convinzioni, perché un seme dell’amore di Dio vi è stato gettato.</p>
            <p>Le nuvole della Misericordia celeste vi hanno riversato la loro pioggia e il Sole della Verità ha
                irradiato i suoi caldi raggi sui giovani semi, cosicché oggi si può vedere fra voi nascere la fede. Il
                seme gettato nel terreno ha incominciato a germogliare e di giorno in giorno vedrete crescerne i
                germogli. Le munificenze del Regno di Bahá’u’lláh porteranno un raccolto meraviglioso.</p>
            <p>State attenti! Io vi porto liete novelle! Parigi diventerà un magnifico giardino di rose! Vi spunteranno
                e sbocceranno tutti i fiori più belli e la fama della loro bellezza e della loro fragranza si spanderà
                dappertutto. Quando penso alla futura Parigi la vedo avvolta nella luce dello Spirito Santo. In verità è
                già sorta l’alba del giorno in cui Parigi sarà illuminata e ogni creatura potrà vedere la Bontà e la
                Misericordia di Dio.</p>
            <p>Non lasciate che le vostre menti si soffermino sul presente. Fate che guardino con occhi fiduciosi al
                futuro, perché in verità lo Spirito di Dio sta operando fra voi.</p>
            <p>Da quando sono arrivato, poche settimane fa, la spiritualità, lo vedo, è cresciuta. Da principio soltanto
                poche anime sono venute da me a cercare la luce. Ma durante la mia breve permanenza fra voi il loro
                numero s’è moltiplicato. Questa è una promessa per il futuro.</p>
            <p>Quando Cristo fu crocifisso e lasciò questo mondo, aveva soltanto undici discepoli e pochissimi seguaci.
                Ma siccome Egli serviva la Causa della Verità, guardate oggi il risultato del lavoro della Sua vita!
                Egli ha illuminato il mondo e ridato vita all’umanità morta. Dopo la Sua ascensione, a poco a poco, la
                Sua Causa si è ingrandita, le anime dei Suoi seguaci sono diventate sempre più luminose e lo squisito
                profumo della loro vita santa si è sparsa dappertutto.</p>
            <p>Oggi, grazie a Dio, una situazione analoga incomincia a prodursi a Parigi. Molte anime si sono volte
                verso il Regno di Dio e sono attratte verso l’unità, l’amore e la verità.</p>
            <p>Cercate dunque di far sì che la bontà e la misericordia di Abhá abbraccino tutta Parigi. L’Alito dello
                Spirito Santo vi aiuterà, la luce celeste del Regno brillerà nei vostri cuori e gli angeli benedetti di
                Dio vi porteranno aiuto e forza dal Cielo. Ringraziate allora Dio di tutto cuore per aver ricevuto
                questo beneficio supremo. Gran parte del mondo è immersa nel sonno, ma voi siete stati risvegliati.
                Molti sono ciechi, ma voi vedete!</p>
            <p>Fra voi si ode l’appello del Regno. Sia lode a Dio, siete rinati, siete stati battezzati col fuoco
                dell’Amore di Dio, siete stati immersi nel Mare della Vita e rigenerati dallo Spirito dell’Amore!</p>
            <p>Siate grati a Dio per aver ricevuto questi favori e non dubitate mai della Sua Bontà e del Suo Amore, ma
                abbiate incrollabile fede nelle Munificenze del Regno. Unitevi in fraterno amore, siate pronti a dare la
                vita l’uno per l’altro e non soltanto per quelli che vi sono cari, ma per tutta l’umanità. Considerate
                tutti gli esseri umani come membri di una sola famiglia, tutti figliuoli di Dio. Così facendo non
                vedrete alcuna differenza fra loro.</p>
            <p>L’umanità si può paragonare a un albero. Questo albero ha rami, foglie e frutti. Considerate tutti gli
                uomini come fiori e foglie o germogli di questo albero e cercate di aiutare tutti a comprendere e godere
                le grazie di Dio. Dio non trascura nessuno. Ama tutti.</p>
            <p>La sola vera differenza che esiste fra gli uomini è che essi si trovano in stadi diversi di sviluppo.
                Alcuni sono imperfetti, bisogna portarli alla perfezione. Alcuni sono addormentati, bisogna svegliarli.
                Altri sono negligenti, bisogna scuoterli. Ma tutti sono figli di Dio. Amateli con tutto il cuore.
                Nessuno dev’essere un estraneo per l’altro, tutti devono essere amici. Stasera vengo a dirvi addio, ma
                tenete bene a mente che per quanto lontani possano essere i nostri corpi, in spirito saremo sempre
                assieme.</p>
            <p>Vi porto tutti nel mio cuore e non dimenticherò nessuno di voi e spero che nessuno di voi mi
                dimenticherà.</p>
            <p>Io in Oriente e voi in Occidente facciamo ogni sforzo, affinché l’unità regni nel mondo, affinché tutti i
                popoli divengano un popolo solo e tutta la terra un solo paese, perché il Sole della Verità brilla
                ugualmente su tutti.</p>
            <p>Tutti i profeti di Dio sono venuti per amore di questo grande scopo.</p>
            <p>Guardate come Abramo Si sforzò di portare la fede e l’amore fra il popolo, come Mosè cercò di unire il
                popolo con giuste leggi, come nostro Signore Gesù Cristo morì per portare la luce dell’amore e della
                verità in un mondo ottenebrato, come Muḥammad Si adoperò per portare l’unità e la pace fra le tribù
                incivili tra le quali viveva. E da ultimo, Bahá’u’lláh ha sofferto per quarant’anni per la stessa causa,
                il solo nobile fine di diffondere l’amore fra i figli degli uomini. E per la pace e l’unità del mondo il
                Báb dette la vita.</p>
            <p>Seguite dunque l’esempio di questi Esseri divini, bevete alla Loro sorgente, illuminatevi con la Loro
                luce e siate per il mondo simboli della Misericordia e dell’Amore di Dio; siate come pioggia e nuvole di
                misericordia, come soli di verità, siate un esercito celeste e in verità conquisterete la città dei
                cuori.</p>
            <p>Ringraziamo Dio che Bahá’u’lláh ci ha dato una solida base. Egli non ha lasciato posto per la tristezza
                nei cuori e gli scritti della Sua sacra penna contengono consolazioni per il mondo intero. Egli usava le
                parole della verità e tutto ciò che è contrario agli insegnamenti Suoi è falso. Lo scopo principale di
                tutto il Suo lavoro fu di far sparire ogni divisione nel mondo.</p>
            <p>Il testamento di Bahá’u’lláh è Pioggia di Bontà, Sole di Verità, Acqua di Vita, Spirito Santo. Così,
                aprite il cuore per ricevere tutto il potere della Sua bellezza e io pregherò che a voi tutti sia data
                questa gioia.</p>
            <p>Ora vi dico «addio».</p>
            <p>Ma lo dico soltanto alla vostra forma esteriore, non alle vostre anime, perché le nostre anime sono
                sempre insieme.</p>
            <p>Consolatevi e siate certi che giorno e notte mi rivolgerò al Regno di Abhá, supplicando per voi che di
                giorno in giorno possiate divenire migliori, più puri, più vicini a Dio e sempre più illuminati dallo
                splendore del Suo amore.</p>
        </Wrapper>
    </div>

)
