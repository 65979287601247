export const times:number[] = [
    12000,
    10000,
    10000,
    25000,
    29000,
    25000,
    20000,
    23000,
    30000,
    25000,
    15000,
    25000,
    17000,
    18000,
    23000,
    20000,
    20000,
    20000,
    25000,
    22000,
    33000,
    26000,
    30000,
    30000,
    30000,
    20000,
    20000,
    35000,
    30000,
    30000,
    30000,
    27000,
    20000,
    30000,
    20000,
    30000,
    30000,
    30000,
    20000,
    20000,
    20000,
    27000,
    27000,
    20000,
    20000,
    20000,
    20000,
    27000,
    27000,
    20000,
    25000,
    28000,
    20000,
    29000,
    20000,
    20000,
    30000,
    30000,
    20000
];
