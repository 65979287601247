import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero31 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>31. IL CORPO, L’ANIMA E LO SPIRITO
            <br/>Avenue de Camoëns 4, Parigi
            <br/>17 novembre 1911
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1408990606&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p>Nel mondo dell’umanità vi sono tre stadi: del corpo, dell’anima e dello spirito.</p>
            <p>Il corpo è lo stadio fisico o animale dell’uomo. Dal punto di vista del corpo, l’uomo fa parte del regno
                animale. I corpi degli uomini e degli animali sono ugualmente composti di elementi tenuti insieme dalla
                legge dell’attrazione.</p>
            <p>Come l’animale, l’uomo ha le facoltà dei sensi, è soggetto al caldo, al freddo, alla fame, alla sete
                eccetera. Ma diversamente dall’animale, egli ha un’anima ragionevole, l’intelligenza umana.</p>
            <p>L’intelligenza dell’uomo è l’intermediario fra il corpo e lo spirito.</p>
            <p>Quando l’uomo permette allo spirito d’illuminare l’intelletto attraverso l’anima, allora egli contiene
                tutta la creazione, perché, essendo il punto culminante di tutto ciò che è venuto prima e quindi
                superiore a ogni precedente evoluzione, contiene in sé tutto il mondo inferiore. Illuminata dallo
                spirito per mezzo dell’anima, la radiosa intelligenza dell’uomo fa di lui il coronamento della
                Creazione.</p>
            <p>Ma d’altra parte, quando l’uomo non apre la mente e il cuore alla benedizione dello spirito, ma volge
                l’anima verso il lato materiale, la parte fisica della sua natura, allora egli decade del suo alto rango
                e diventa inferiore agli animali nel loro regno inferiore. In tal caso l’uomo si trova in una triste
                condizione! Poiché le qualità spirituali dell’anima, aperta all’alito dello Spirito divino, se non sono
                mai adoperate, finiscono per atrofizzarsi, indebolirsi e divenire infine impotenti. Mentre se sono
                esercitate soltanto le qualità materiali dell’anima, queste diventano paurosamente potenti e quell’uomo
                infelice e traviato diviene più selvaggio, più ingiusto, più abbietto, più crudele, più malvagio degli
                animali bruti. Dato che tutte le sue aspirazioni e tutti i suoi desideri ricevono forza dalla parte
                inferiore della natura dell’anima, egli diventa sempre più brutale, fino a che tutto l’essere suo viene
                a non essere in alcun modo superiore a quello delle bestie che periscono. Uomini siffatti pensano solo a
                compiere perfide azioni, a nuocere e a distruggere. Mancano completamente dello spirito della
                Compassione divina, perché la qualità celestiale dell’anima è dominata da quella della materialità. Se
                al contrario la natura spirituale dell’anima è stata tanto rinvigorita da tener sottomessa la parte
                materiale, allora l’uomo si avvicina alla Divinità, la sua natura umana si innalza talmente che le virtù
                delle Coorti celesti si manifestano in lui, egli irradia la Misericordia di Dio, stimola il progresso
                spirituale del genere umano, perché diventa la luce che illumina la via agli uomini.</p>
            <p>Capite ora che l’anima è l’intermediario fra il corpo e lo spirito. Alla stessa guisa questo albero è
                l’intermediario fra il seme e il frutto (Indicava un alberello d’arancio posto su di un tavolo accanto).
                Quando il frutto dell’albero appare e si matura, allora sappiamo che l’albero è perfetto. Se l’albero
                non producesse frutti, sarebbe una pianta inutile, che non serve a nulla!</p>
            <p>Quando ha in sé la vita dello spirito, l’anima produce buoni frutti e diviene un albero divino. Vorrei
                che cercaste di capire questo esempio. Spero che l’ineffabile bontà di Dio vi dia tanta forza da far sì
                che la qualità celestiale delle anime vostre, che le congiunge allo spirito, domini sempre la parte
                materiale, regolando i sensi a tal punto che le vostre anime si avvicinino alle perfezioni del Regno
                divino. Possano i vostri visi volgersi saldamente verso la Luce divina e diventare così luminosi che
                tutti i vostri pensieri, le vostre parole e i vostri atti risplendano della radiosa Luce spirituale che
                domina le vostre anime, cosicché in qualunque assemblea del mondo vi troviate mostriate la perfezione
                della vostra vita.</p>
            <p>Alcuni uomini si occupano solamente delle cose di questo mondo. Le loro menti sono limitate dalle maniere
                esteriori e dai tradizionali interessi e non vedono nessun altro regno dell’esistenza, non capiscono il
                significato spirituale di tutte le cose. I loro pensieri e i loro sogni hanno per oggetto la fama
                mondana e il progresso materiale. Il loro orizzonte è limitato dai piaceri sensuali e dalle comodità
                della vita. Le loro più alte ambizioni si concentrano sui successi mondani. Essi non frenano le loro
                tendenze basse, mangiano, bevono e dormono! Come gli animali, non pensano ad altro che al benessere
                fisico. È vero che a queste necessità bisogna pure adempiere. La vita è un peso che bisogna portare
                mentre siamo sulla terra; ma non si deve lasciare che le cure delle cose materiali della vita prendano
                tutti i pensieri e le aspirazioni di un essere umano. Le ambizioni del cuore dovrebbero innalzarsi verso
                una meta più luminosa e l’attività mentale dovrebbe sollevarsi a livelli più alti. L’uomo dovrebbe
                tenere nell’anima la visione della perfezione celeste e preparare una dimora per l’inesauribile
                munificenza dello Spirito divino.</p>
            <p>Fate che la vostra ambizione sia la realizzazione sulla terra di una civiltà celeste! Invoco per voi la
                benedizione suprema, che siate dotati della vitalità dello Spirito celeste così da dare al mondo la vera
                vita.</p>
        </Wrapper>
    </div>

)
