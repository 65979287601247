import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero23 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>23. L’ASPIRAZIONE SPIRITUALE NELL’OCCIDENTE
            <br/>6 novembre 1911
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1388417521&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p>‘Abdu’l-Bahá disse:<br/>
                Siate benvenuti! Dalla terra d’Oriente sono venuto in Occidente per fermarmi tra voi per qualche tempo.
                Spesso in Oriente si dice che il popolo d’Occidente manca di spiritualità. Ma io non trovo che sia vero.
                Grazie a Dio, vedo e sento che c’è una forte aspirazione spirituale fra i popoli occidentali e noto che
                in qualche caso la loro percezione spirituale è anche più acuta di quella dei loro fratelli orientali.
                Se gli insegnamenti dati in Oriente fossero stati coscienziosamente diffusi in Occidente, il mondo oggi
                sarebbe un luogo più illuminato.</p>
            <p>Benché nel passato tutti i grandi Maestri spirituali siano sorti in Oriente, vi sono tuttavia ancora colà
                molti uomini assolutamente privi di spiritualità. Quanto alle cose dello spirito sono inanimati come
                pietre e non desiderano essere diversi, perché ritengono che l’uomo sia soltanto una più alta specie
                d’animale e che le cose di Dio non lo riguardino.</p>
            <p>Ma l’ambizione dell’uomo dovrebbe mirare più in alto. Egli dovrebbe guardare sempre al di sopra di se
                stesso, sempre in alto e in avanti, finché, grazie alla Misericordia di Dio, egli possa entrare nel
                Regno dei Cieli. Vi sono uomini che guardano soltanto al progresso fisico e all’evoluzione nel mondo
                della materia. Questi uomini preferiscono studiare la somiglianza fra il loro corpo fisico e quello
                delle scimmie piuttosto che contemplare la meravigliosa dipendenza del loro spirito da quello di Dio. Ed
                è strano perché è nel fisico soltanto che l’uomo assomiglia alle creature più basse, mentre per
                l’intelletto egli ne è totalmente diverso.</p>
            <p>L’uomo progredisce sempre. Il cerchio delle sue cognizioni si allarga sempre più e la sua attività
                mentale si diffonde in molti e diversi rami. Guardate che cosa l’uomo ha potuto fare nel campo della
                scienza! Pensate alle sue tante scoperte e innumerevoli invenzioni e alla sua profonda conoscenza delle
                leggi della natura!</p>
            <p>Nel campo dell’arte è lo stesso. E questo meraviglioso sviluppo delle facoltà dell’uomo diventa sempre
                più rapido col tempo. Se le scoperte, le invenzioni e i miglioramenti materiali degli ultimi
                millecinquecento anni potessero esser messi insieme, vedreste che i progressi fatti da cento anni ad
                oggi sono assai superiori a quelli ottenuti nei precedenti quattordici secoli. La rapidità con cui
                l’uomo progredisce aumenta di secolo in secolo.</p>
            <p>Il potere dell’intelletto è uno dei più grandi doni di Dio all’uomo. È quello che fa di lui una creatura
                superiore all’animale. Perché mentre di secolo in secolo l’intelletto dell’uomo cresce e si fa più
                acuto, quello degli animali rimane lo stesso. Essi non sono oggi più intelligenti di mille anni fa! Vi
                può essere prova migliore di questa per dimostrare la dissimiglianza dell’uomo dall’animale? Ciò è
                certamente chiaro come la luce del giorno.</p>
            <p>Quanto alle perfezioni spirituali, esse sono un diritto connaturale dell’uomo e appartengono a lui solo
                in tutta la creazione. L’uomo è, in realtà, un essere spirituale e solo quando vive nello spirito è
                veramente felice. Questo anelito e questa percezione spirituale sono di tutti ed è mia ferma convinzione
                che i popoli occidentali hanno un’alta aspirazione spirituale.</p>
            <p>La mia fervida preghiera è che la stella dell’Oriente diffonda i suoi raggi luminosi sul mondo
                occidentale e che le genti dell’Occidente si levino con forza, sincerità e coraggio in aiuto dei loro
                fratelli d’Oriente.</p>
        </Wrapper>
    </div>

)
