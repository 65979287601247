import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero42 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>42. IL SECONDO PRINCIPIO: L’UNITÀ DELLA RAZZA UMANA
            <br/>11 novembre 1911
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1410101212&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p>Ieri vi ho parlato del primo principio degli Insegnamenti di Bahá’u’lláh, «la ricerca della verità», e di
                come sia necessario mettere da parte tutto ciò che ha la natura della superstizione e ogni tradizione
                che acceca l’occhio all’esistenza della verità in tutte le religioni. L’uomo non deve permettersi di
                detestare le altre religioni mentre ne ama una sola e si attacca ad una soltanto. È essenziale chi
                cerchi la verità in tutte le religioni e se la sua ricerca sarà onesta, avrà sicuramente successo.</p>
            <p>La prima scoperta che facciamo nella «ricerca della verità» ci porta al secondo principio, che è l’«unità
                della razza umana». Tutti gli uomini sono servi di un Dio Unico. Un Unico Dio regna su tutte le nazioni
                del mondo e Si compiace in tutti i Suoi figli. Tutti gli uomini appartengono a una sola famiglia, la
                corona dell’umanità è posata sulla testa d’ogni essere umano.</p>
            <p>Agli occhi del Creatore tutti i Suoi figli sono uguali, la Sua bontà si riversa su tutti. Egli non
                favorisce questa o quella nazione, tutti sono parimenti Sue creature. Se è così, perché creiamo
                divisioni separando una razza dall’altra? Perché creiamo barriere di superstizioni e tradizioni,
                disseminando disaccordo e odio fra i popoli?</p>
            <p>L’unica differenza fra i membri della famiglia umana è la differenza di stadio. Alcuni sono come bimbi
                ignoranti e abbisognano d’essere educati fino a raggiungere la maturità. Altri sono come ammalati e
                devono essere curati con tenerezza e premura. Nessuno è cattivo o malvagio! Non dobbiamo sentire
                ripugnanza verso questi poveri figli. Dobbiamo trattarli con molta gentilezza istruendo gli ignoranti e
                curando teneramente gli ammalati.</p>
            <p>Pensate. L’unità è essenziale all’esistenza. L’amore è la vera causa della vita. Invece la separazione
                porta alla morte. Nel mondo della materia, per esempio, tutte le cose devono la vita all’unità. Gli
                elementi che compongono il legno, i minerali e le pietre, sono tenuti assieme dalla legge
                dell’attrazione. Se questa legge dovesse cessare di operare per un solo istante, questi elementi non
                resterebbero insieme e, separandosi, quell’oggetto cesserebbe di esistere in quella specifica forma. La
                legge dell’attrazione ha messo insieme gli elementi che costituiscono questo bel fiore, ma quando questa
                attrazione cessa di operare, il fiore si decompone e smette di esistere.</p>
            <p>Così accade all’immenso corpo dell’umanità. La meravigliosa Legge dell’Attrazione, dell’Armonia e
                dell’Unità tiene insieme questa mirabile creazione.</p>
            <p>Ciò che accade al tutto accade anche alle parti. Si tratti di un fiore o di un essere umano, quando il
                principio dell’attrazione viene a mancare, il fiore o l’uomo muoiono. È quindi evidente che
                l’attrazione, l’armonia, l’unità e l’amore portano la vita, la repulsione, la discordia, l’odio e la
                separazione portano la morte.</p>
            <p>Abbiamo visto che nel mondo della materia tutto ciò che porta separazione porta morte. La stessa legge
                opera anche nel mondo dello spirito.</p>
            <p>Perciò ogni servo dell’Unico Dio deve obbedire alla legge dell’amore evitando l’odio, la discordia e le
                contese. Nell’osservare la natura, constatiamo che gli animali più mansueti si riuniscono in branchi e
                greggi, mentre i più selvaggi e feroci come il leone, la tigre e il lupo, vivono nella foresta
                selvaggia, lontani dalla civiltà. Due lupi e due leoni possono anche vivere insieme amichevolmente, ma
                mille pecore vivono nello stesso ovile e un gran numero di daini forma un gregge. Due aquile possono
                vivere nello stesso nido, ma mille tortorelle possono radunarsi nello stesso rifugio.</p>
            <p>L’uomo dovrebbe almeno rientrare nel novero degli animali mansueti, ma quando diviene feroce, è più
                crudele e perfido del più selvaggio animale della creazione!</p>
            <p>Ora Bahá’u’lláh ha proclamato «l’unità della mondo umano». Tutti i popoli e tutte le nazioni appartengono
                a un’unica famiglia, sono figlie di un solo Padre e devono essere come fratelli e sorelle. Spero che
                cerchiate di manifestare e diffondere questo insegnamento nelle vostre vite.</p>
            <p>Bahá’u’lláh ha detto che dobbiamo amare anche i nemici e trattarli da amici. Se tutti gli uomini fossero
                obbedienti a questo principio, nei cuori umani si stabilirebbero la massima unità e comprensione.</p>

        </Wrapper>
    </div>

)
