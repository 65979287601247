import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero40 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>40. LA SOCIETÀ TEOSOFICA, PARIGI
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1421835637&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p>Da quando sono arrivato a Parigi, mi è stato parlato della Società teosofica e so che è composta di
                uomini rispettati e onorati. Siete uomini di intelletto e di pensiero, uomini di ideali spirituali e mi
                fa molto piacere essere qui fra voi.</p>
            <p>Ringraziamo Dio di averci riuniti qui questa sera. Mi dà grande gioia, perché vedo che siete ricercatori
                della verità. Non siete avvinti dalle catene del pregiudizio e il vostro più grande desiderio è di
                conoscere la Verità. La verità si può paragonare al sole. Il sole è un corpo luminoso che disperde ogni
                ombra. Nella stessa maniera la verità dissipa le ombre dell’immaginazione. Come il sole dà vita al corpo
                dell’uomo, così la verità dà vita alla sua anima. La verità è un sole che sorge da punti diversi
                sull’orizzonte.</p>
            <p>Il sole sorge sempre dall’orizzonte, però in estate si alza più a nord, in inverno più a sud. Ma, pur
                sorgendo da punti diversi, è sempre lo stesso sole.</p>
            <p>Similmente la verità è una, quantunque varie siano le sue manifestazioni. Alcuni hanno occhi e vedono.
                Costoro adorano il Sole, da qualunque punto sorga sull’orizzonte e quando il Sole, abbandonato il suo
                invernale, sorge da quello estivo, lo riconoscono. Altri adorano soltanto il punto dal quale il Sole
                sorge e quando il Sole si alza nella sua gloria da un altro punto, restano a guardare il punto dal quale
                sorse in precedenza. Ahimè, essi si privano delle benedizioni del Sole! Coloro che davvero adorano il
                sole, lo riconoscono da qualunque punto si levi e subito volgono gli occhi verso il suo splendore.</p>
            <p>Dobbiamo adorare il sole e non il punto dal quale esso appare. Nella stessa maniera gli uomini che hanno
                cuori illuminati adorano la verità da qualunque orizzonte sorga. Non sono legati a personalità, ma
                seguono la verità e sono capaci di riconoscerla dovunque essa appaia. È questa Verità che aiuta
                l’umanità a progredire, che dà vita a tutti gli esseri, poiché essa è l’Albero della Vita.</p>
            <p>Nei Suoi insegnamenti Bahá’u’lláh ci spiega la verità e io voglio parlarvene brevemente, perché vedo che
                siete capaci di comprendere.</p>
            <p>Il primo principio di Bahá’u’lláh è:<br/><i>La ricerca della Verità</i></p>
            <p>L’uomo deve liberarsi da tutti i pregiudizi e dalle fantasie dell’immaginazione, per poter cercare la
                Verità senza trovare ostacoli. La verità è una in tutte le religioni e per suo mezzo si può realizzare
                l’unità del mondo.</p>
            <p>Tutti i popoli hanno un credo fondamentale in comune. Essendo una, la verità non può essere divisa e le
                differenze che apparentemente esistono fra le nazioni sono soltanto il risultato del loro attaccamento
                ai pregiudizi. Se gli uomini scoprissero la verità, si troverebbero uniti.</p>
            <p>Il secondo principio di Bahá’u’lláh è:<br/><i>L’unità del genere umano.</i></p>
            <p>L’unico amorevolissimo Dio dona la Sua divina Grazia e i Suoi Favori a tutta l’umanità. Tutti sono servi
                dell’Altissimo e la Sua Bontà, la Sua Misericordia e la Sua amorosa Premura si riversano su tutte le Sue
                creature. La gloria dell’umanità è il diritto di ciascuna d’esse.</p>
            <p>Tutti gli uomini sono foglie e frutti di uno stesso albero, sono rami dell’albero di Adamo e hanno tutti
                la medesima origine. La stessa pioggia cade su di essi, lo stesso calore del sole li fa crescere, sono
                tutti ristorati dalla stessa brezza. Le sole differenze che esistono e che li tengono separati sono
                queste: vi sono bimbi che abbisognano di guida, ignoranti che abbisognano d’istruzione e ammalati che
                abbisognano di cure e guarigione. Così, io dico, l’umanità intera è circondata dalla Misericordia e
                dalla Grazia di Dio. Come dicono le sacre Scritture. Tutti gli uomini sono uguali davanti a Dio. Egli
                non ha riguardo per le persone.</p>
            <p>Il terzo principio di Bahá’u’lláh è:<br/><i>La Religione dev’essere causa d’amore e d’affetto.</i></p>
            <p>La religione deve unire i cuori, far sparire dalla faccia della terra le guerre e le lotte, rinvigorire
                la spiritualità, infondere vita e luce nei cuori. Se la religione diviene causa di contrasti, odio e
                dispute, è meglio non averla e il separarsi da una simile religione sarebbe invero un’azione pia. È
                chiaro che lo scopo di un rimedio è di curare, ma se il rimedio aggrava il male è meglio abbandonarlo.
                Una religione che non sia causa di amore e di unità non è una religione. Tutti i santi profeti sono
                stati medici dell’anima, hanno prescritto il rimedio per la guarigione dell’umanità. Pertanto qualunque
                rimedio aggravi il male non viene dall’eccelso e supremo Medico!</p>
            <p>Il quarto principio di Bahá’u’lláh è:<br/><i>Unità della religione e della scienza.</i></p>
            <p>Possiamo paragonare la scienza a un’ala e la religione all’altra. Un uccello ha bisogno di due ali per
                volare, una soltanto sarebbe inutile. Qualunque religione contraddica la scienza o le si opponga non è
                che ignoranza, poiché l’ignoranza e l’opposto della sapienza.</p>
            <p>Una religione che consista soltanto in riti, cerimonie e pregiudizi non può essere la verità. Cerchiamo
                sinceramente d’essere la causa dell’unione della scienza e della religione.</p>
            <p>‘Alí, il genero di Muḥammad, disse: «Ciò che si conforma alla scienza si conforma pure alla religione».
                Tutto ciò che l’intelligenza umana non può accettare, la religione non dovrebbe accettarlo. La religione
                e la scienza camminano a braccetto e una religione contraria alla scienza non è la verità.</p>
            <p>Il quinto principio di Bahá’u’lláh è:<br/><i>I pregiudizi di religione, razza o sètta distruggono le
                fondamenta dell’umanità.</i></p>
            <p>Nel mondo, tutte le discordie, gli odi, le guerre e le stragi sono causati da uno di questi
                pregiudizi.</p>
            <p>Il mondo intero dev’essere considerato un solo paese, tutte le nazioni una sola nazione e tutti gli
                uomini membri di una sola razza. Le religioni, le razze e le nazioni sono divisioni create unicamente
                dall’uomo e necessarie soltanto al suo pensiero. Davanti a Dio non ci sono persiani, arabi, francesi o
                inglesi. Dio è il Dio di tutti e per Lui la creazione è una sola. Dobbiamo obbedire Iddio e cercare di
                seguirLo abbandonando tutti i pregiudizi e apportando la pace sulla terra.</p>
            <p>Il sesto principio di Bahá’u’lláh è:<br/><i>Pari opportunità dei mezzi di sussistenza.</i></p>
            <p>Ogni essere umano ha diritto a vivere, diritto al riposo e a un certo ammontare di benessere. Come il
                ricco vive in un palazzo circondato da lussi e agi, così il povero deve avere il necessario per vivere.
                Nessuno deve morire di fame, tutti devono possedere vestiario sufficiente e nessuno deve vivere
                nell’abbondanza mentre altri non hanno alcun mezzo di sussistenza.</p>
            <p>Cerchiamo, con tutta la forza che abbiamo, di creare condizioni più felici, in modo che nessuno si trovi
                nell’indigenza.</p>
            <p>Il settimo principio di Bahá’u’lláh è:<br/><i>L’uguaglianza degli uomini – l’uguaglianza di fronte alla
                Legge.</i></p>
            <p>Deve regnare la Legge e non l’individuo. Il mondo diventerà, così, attraente e si realizzerà la vera
                fratellanza. Se conseguono la solidarietà, gli uomini trovano la verità.</p>
            <p>L’ottavo principio di Bahá’u’lláh è:<br/><i>La pace universale.</i></p>
            <p>I popoli e i governi di tutte le nazioni eleggeranno un Tribunale supremo e i suoi membri, d’ogni paese e
                governo, si riuniranno in armonia. Tutte le dispute saranno portate davanti a questo Tribunale che ha la
                missione di prevenire le guerre.</p>
            <p>Il nono principio di Bahá’u’lláh è:<br/><i>La Religione non deve interessarsi di questioni politiche.</i>
            </p>
            <p>La Religione si interessa delle cose dello spirito, la politica delle cose del mondo. La Religione deve
                lavorare nel mondo del pensiero, mentre il campo della politica è il mondo esteriore.</p>
            <p>È compito del clero educare il popolo, istruirlo, dargli buoni consigli e insegnamenti tali da farlo
                progredire spiritualmente. Con le questioni politiche il clero non ha nulla a che fare.</p>
            <p>Il decimo principio di Bahá’u’lláh è:<br/><i>L’educazione e l’istruzione delle donne.</i></p>
            <p>Le donne hanno sulla terra gli stessi diritti degli uomini. Nella religione e nella società umana esse
                sono un importantissimo elemento. Finché si impedirà alle donne di conseguire le loro massime
                possibilità, l’uomo non potrà conseguire la grandezza che potrebbe esser sua.</p>
            <p>L’undicesimo principio di Bahá’u’lláh è:<br/><i>Il potere dello Spirito Santo, l’unico strumento per
                ottenere lo sviluppo spirituale.</i></p>
            <p>È solo per opera dell’alito dello Spirito Santo che si può ottenere lo sviluppo spirituale. Il mondo
                materiale, per quanto progredisca, per quanto splendidamente si adorni, non è mai altro che un corpo
                senza vita, se in esso non c’è un’anima, perché è l’anima che dà vita al corpo. Il corpo da solo non ha
                alcuna valore. Privo delle benedizioni dello Spirito Santo il corpo fisico rimarrebbe inerte.</p>
            <p>Questi sono in breve alcuni dei principi di Bahá’u’lláh.</p>
            <p>In breve, dobbiamo tutti essere amanti della verità. Cerchiamola in ogni stagione e in ogni paese,
                evitiamo con cura di attaccarci alle personalità. Vediamo la luce ovunque essa brilli e cerchiamo di
                riconoscere la luce della verità ovunque essa sorga. Aspiriamo il profumo dalla rosa fra le spine che la
                circondano. Beviamo le acque che sgorgano da ogni sorgente pura.</p>
            <p>Dacché giunsi a Parigi, ho provato immenso piacere a incontrare parigini come voi, poiché, sia lode a
                Dio, siete intelligenti, senza pregiudizi e bramate conoscere la verità. Avete nel cuore amore per
                l’umanità e, per quanto vi è possibile, vi adoperate nelle opere di carità e nella promozione
                dell’unità. Questo è ciò che Bahá’u’lláh voleva.</p>
            <p>Ecco perché sono così felice d’essere fra voi e prego che siate oggetto delle Benedizioni di Dio e
                strumenti per diffondere la spiritualità in tutto il paese.</p>
            <p>Già avete una splendida civiltà materiale e ne avrete anche una spirituale.</p>
            <p>Il signor Bleck ringraziò ‘Abdu’l-Bahá, che rispose:<br/>«Le sono molto grato per le gentili espressioni
                che ha ora pronunziato. Spero che questi due movimenti si diffondano fra non molto su tutta la terra.
                Allora l’unità della razza umana pianterà le tende nel centro del mondo».</p>

        </Wrapper>
    </div>

)
