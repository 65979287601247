import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero9 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>9. L’AMORE UNIVERSALE
            <br/>24 ottobre 1911
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1376608432&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p>
                Un indiano disse a ‘Abdu’l-Bahá: «Lo scopo della mia vita è di fare tutto ciò che posso per propagare
                nel mondo il messaggio di Krishna».
            </p>
            <p>
                ‘Abdu’l-Bahá rispose: Il Messaggio di Krishna è il messaggio dell’Amore. Tutti i profeti di Dio hanno
                portato il messaggio dell’amore. Nessuno di loro ha mai detto che la guerra e l’odio sono una cosa
                buona. Tutti sono d’accordo nel dire che l’amore e la benevolenza sono molto meglio.
            </p>
            <p>
                L’amore manifesta la propria realtà non soltanto con le parole, ma con le azioni. Le sole parole non
                producono alcun effetto. Perché l’amore possa manifestare il suo potere è necessario che vi siano un
                oggetto, un mezzo e una causa.
            </p>
            <p>
                Vari sono i modi in cui può esprimersi il principio dell’amore. C’è l’amore per la famiglia, per la
                patria, per la razza. C’è l’entusiasmo politico. C’è l’amore dell’interesse comune al servizio. Tutti
                questi sono modi e mezzi per dimostrare il potere dell’amore. Senza di essi l’amore non si vedrebbe, non
                si sentirebbe, non si percepirebbe. Sarebbe assolutamente privo di espressione e di manifestazione.
                L’acqua dimostra il suo potere in varie maniere: calma la sete, fa sviluppare i semi, eccetera. Il
                carbone manifesta uno dei suoi principi nell’illuminazione a gas, mentre uno dei poteri dell’elettricità
                si manifesta nella luce elettrica. Se non ci fossero né il gas né l’elettricità, le notti del mondo
                sarebbero oscure. Così è necessario avere un mezzo e una causa per manifestare l’amore, avere un oggetto
                e un modo per esprimerlo.
            </p>
            <p>
                Dobbiamo trovare la maniera di diffondere l’amore tra i figli del genere umano.
            </p>
            <p>
                L’amore è illimitato, infinito, sconfinato! Le cose materiali sono limitate, circoscritte, finite. Non
                si può adeguatamente esprimere l’amore infinito con mezzi limitati.
            </p>
            <p>
                L’amore perfetto ha bisogno d’un mezzo disinteressato, assolutamente libero da legami di qualsiasi
                genere. L’amore della famiglia è limitato, il vincolo del sangue non è il più forte dei legami. Spesso i
                componenti di una medesima famiglia non vanno d’accordo e talvolta perfino si odiano.
            </p>
            <p>
                L’amor di patria è limitato. L’amore del proprio paese, che fa odiare tutti gli altri paesi, non è
                l’amore perfetto! E fra compatrioti non mancano le liti.
            </p>
            <p>
                L’amore della razza è limitato. C’è, è vero, una certa unione, ma non è sufficiente. L’amore non deve
                aver confini.
            </p>
            <p>
                Amare la propria razza può significare odiare tutte le altre e le persone della stessa razza spesso non
                vanno d’accordo.
            </p>
            <p>
                Anche l’amore della politica è molto legato all’odio fra un partito e l’altro. Quest’amore è molto
                limitato e incerto.
            </p>
            <p>
                L’amore dell’interesse comune al servizio non è stabile. Spesso sorgono competizioni che portano la
                gelosia e alla fine l’amore è rimpiazzato dall’odio.
            </p>
            <p>
                Pochi anni fa esisteva fra la Turchia e l’Italia un’amichevole intesa politica. Ora sono in guerra!
            </p>
            <p>
                Tutti questi legami d’amore sono imperfetti. È chiaro che i legami materiali sono limitati,
                insufficienti a esprimere adeguatamente l’amore universale.
            </p>
            <p>
                Il grande altruistico amore per l’umanità non è limitato da nessuno di questi legami imperfetti e
                semiegoistici. L’amore universale è l’unico amore perfetto, possibile a tutta l’umanità, ma si può
                acquisirlo soltanto col potere dello Spirito divino. Nessun potere al mondo può creare l’amore
                universale.
            </p>
            <p>
                Possano tutti essere uniti in questo divino potere d’amore! Possano tutti fare ogni sforzo per crescere
                nella luce del Sole della Verità e riflettere quest’amore luminoso su tutti gli uomini! Possano tutti i
                cuori unirsi in modo da poter vivere sempre nello splendore dell’amore senza limiti!
            </p>
            <p>
                Ricordate queste parole che vi dico in questi pochi giorni che trascorro fra voi a Parigi. Vi esorto
                caldamente: non lasciate che le cose materiali di questo mondo si impadroniscano dei vostri cuori, vi
                raccomando di non restare inerti, cullandovi nella negligenza, prigionieri della materia, ma di alzarvi
                e di liberarvi dalle sue catene.
            </p>
            <p>
                Il mondo animale è schiavo della materia. Dio ha dato all’uomo la libertà. L’animale non può sfuggire
                alle leggi naturali, mentre l’uomo può controllarla, perché, avendola in sé, può innalzarsi al di sopra
                di essa.
            </p>
            <p>
                Il potere dello Spirito Santo, illuminando l’intelligenza umana, ha permesso all’uomo di scoprire i
                mezzi per piegare alla sua volontà molte leggi della natura. Egli vola nell’aria, galleggia sul mare e
                può anche viaggiare sott’acqua.
            </p>
            <p>
                Tutti questi fatti dimostrano che all’intelligenza umana è stato dato di liberare l’uomo dalle
                limitazioni della natura e di svelarne molti misteri. L’uomo, entro certi limiti, ha spezzato le catene
                della materia.
            </p>
            <p>
                Lo Spirito Santo gli darà poteri ancora più grandi, se egli perseguirà con tenacia le cose dello spirito
                e cercherà di accordare il cuore con l’infinito amore divino.
            </p>
            <p>
                Quando amate una persona di famiglia o un compatriota, fate che quest’amore sia un raggio dell’Amore
                infinito! Fate che sia in Dio e per Dio! Amate ogni persona nella quale trovate gli attributi di Dio,
                sia essa della vostra famiglia o di un’altra. Diffondete la luce d’un amore infinito su ogni creatura
                umana che incontrate, sia essa del vostro paese, della vostra razza, del vostro partito politico, o di
                un’altra qualunque nazione, colore o opinione politica. Il Cielo vi aiuterà mentre lavorerete per
                riunire sotto l’ombra dell’onnipotente baldacchino dell’unità i popoli sparsi nel mondo.
            </p>
            <p>
                Sarete servi di Dio, vicini a Lui, Suoi assistenti divini al servizio dell’Umanità, di tutta l’Umanità,
                di ogni creatura umana! Non dimenticatelo mai!
            </p>
            <p>
                Non dite: è italiano, francese, americano, inglese. Ricordatevi soltanto che è un figlio di Dio, un
                servo dell’Altissimo, un essere umano! Tutti sono essere umani. Dimenticate le nazionalità, tutti sono
                uguali agli occhi di Dio.
            </p>
            <p>
                Dimenticate le vostre limitazioni. Vi giungerà l’aiuto di Dio. Dimenticate voi stessi. L’aiuto di Dio
                verrà sicuramente!
            </p>
            <p>
                Quando invocherete la Misericordia di Dio, Che è sempre pronto a darvi aiuto, la vostra forza si
                decuplicherà.
            </p>
            <p>
                Guardate me. Sono molto debole, eppure mi è stata data la forza di venire fra voi. Sono un povero servo
                di Dio cui è stato concesso di portarvi questo messaggio! Non rimarrò a lungo con voi! Non si deve mai
                considerare la propria debolezza. È la forza dello Spirito Santo dell’Amore che dà all’uomo il potere di
                insegnare. Il pensiero della nostra debolezza può soltanto portare smarrimento. Dobbiamo guardare più in
                altro di ogni pensiero terreno, distaccarci da ogni idea materiale e bramare le qualità dello Spirito,
                mirare sempre all’eterna, generosa misericordia dell’Onnipotente, che riempirà le nostre anime di gioia
                se obbediremo al Suo comando «Amatevi!».
            </p>
        </Wrapper>
    </div>

)
