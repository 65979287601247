import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero15 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>15. LA BELLEZZA E L’ARMONIA DELLA DISSIMIGLIANZA
            <br/>28 ottobre 1911
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1386998002&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p>Il Creatore di tutto ciò che esiste è Iddio Unico.</p>
            <p>Da questo stesso Dio tutta la creazione ha avuto origine ed Egli è il solo fine, al quale ogni cosa nella
                natura agogna. Questo concetto fu espresso dalle parole di Cristo quando disse: «Io sono l’Alfa e
                l’Omega, il principio e la fine» (Apocalisse I, 11). L’uomo è il totale della Creazione e l’Uomo
                perfetto è l’espressione del pensiero completo del Creatore, il Verbo di Dio.</p>
            <p>Esaminate il mondo degli esseri creati, come sono diverse e varie le loro specie, benché abbiano una sola
                origine. Tutte le differenze che vediamo sono nella forma e nel colore. Questa diversità di tipi è
                evidente in tutti i regni della natura.</p>
            <p>Guardate un bel giardino pieno di fiori, alberi e arbusti. Ogni fiore ha un differente fascino, una
                grazia speciale, un suo profumo delizioso e un suo bel colore. E anche gli alberi, quanto differiscono
                in grandezza, sviluppo, fogliame e quanto diversi sono i loro frutti! Ma tutti questi fiori, questi
                arbusti, questi alberi hanno radici nella stessa terra, il medesimo sole risplende su di loro e le
                medesime nuvole li irrorano di pioggia.</p>
            <p>Altrettanto avviene per l’umanità! Essa è costituita da diverse razze. I suoi popoli sono di colori
                diversi; bianchi, neri, gialli, bruni e rossi, ma tutti vengono dallo stesso Dio e tutti sono Suoi
                servi. Questa diversità fra i figli dell’uomo sfortunatamente non ha lo stesso effetto che ha nel regno
                vegetale, ove ne deriva uno spirito più armonioso. Fra gli uomini esistono molte specie di rancore e
                questo è causa di odio e guerra fra le diverse nazioni del mondo.</p>
            <p>Spesso gli uomini si distruggono e si uccidono soltanto perché sono di sangue diverso. E come è doloroso
                che questo avvenga tuttora! Dovremmo invece ammirare la bellezza nella dissimiglianza, la bellezza
                nell’armonia e imparare dal regno vegetale. Se vedeste un giardino nel quale tutte le piante avessero la
                stessa forma, lo stesso colore e lo stesso profumo, non vi sembrerebbe affatto bello, ma monotono e
                triste. Il giardino che piace all’occhio e rallegra il cuore è quello nel quale nascono fiori di tutti i
                colori, di tutte le forme e di tutti i profumi e il giocondo contrasto dei colori è appunto ciò che
                produce l’incanto e la bellezza. Così è degli alberi. Un giardino pieno di alberi da frutto e una
                piantagione che contiene diverse specie di piante sono deliziosi. È appunto la dissimiglianza che ne
                costituisce il fascino. Ogni fiore, ogni albero e ogni frutto, oltre ad essere bello di per sé, fa
                spiccare per contrasto le qualità degli altri e torna a vantaggio della speciale avvenenza di tutti gli
                altri.</p>
            <p>Così dovrebbe essere tra i figli dell’uomo. La dissimiglianza nella famiglia umana dovrebbe essere causa
                di concordia e armonia, come nella musica quando varie note differenti si uniscono per formare un
                accordo perfetto. Se incontrate gente di razza e colore diversi dai vostri, non diffidate di loro e non
                barricatevi dietro gli usi convenzionali. Siate felici e benevoli. Considerateli come fossero rose di
                diverso colore, che crescono nel bellissimo giardino dell’umanità e rallegratevi di essere fra loro.</p>
            <p>Allo stesso modo quando incontrate persone d’opinione diversa dalla vostra, non volgete loro le spalle.
                Tutti cerchiamo la verità e vi sono molte vie che conducono ad essa. La verità ha vari aspetti, ma
                rimane sempre verità.</p>
            <p>Non permettete che la differenza di opinione o la diversità dei pensieri vi separino dal prossimo o siano
                causa di controversie, odio e dispute nel vostro cuore.</p>
            <p>Cercate invece diligentemente la verità e procurate di farvi amici tutti gli uomini.</p>
            <p>Ogni edificio è fatto di molte pietre differenti. Eppure ognuna si appoggia in tal modo sull’altra che,
                se una sola fosse spostata, l’intero edificio ne soffrirebbe. Se una pietra è difettosa l’edificio non è
                perfetto.</p>
            <p>Bahá’u’lláh ha disegnato il cerchio dell’unità, ha fatto un progetto per unire tutti i popoli e per
                raccoglierli sotto l’ombra del baldacchino dell’unità universale. Questa è opera della divina
                Provvidenza e dobbiamo sforzarci con tutto il cuore e con tutta l’anima finché non regni fra noi la vera
                unità. Quanto più lavoreremo, tanta più forza ci sarà data. Non pensate affatto a voi stessi, sforzatevi
                soltanto di essere obbedienti e sottomessi alla Volontà di Dio. Solo così potrete diventare cittadini
                del Regno di Dio e ottenere la vita eterna.</p>
        </Wrapper>
    </div>

)
