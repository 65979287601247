import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero24 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>24. CONFERENZA IN UNO STUDIO A PARIGI
            <br/>6 novembre 1911
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1388418202&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p>In verità questa è una casa bahá’í. Ogni volta che si fonda una casa, o un luogo d’incontro, di questo
                genere si dà un aiuto immenso allo sviluppo della città e del paese in cui si trova. Una casa come
                questa incoraggia la crescita del sapere e della scienza e diventa nota per la sua intensa spiritualità
                e per l’amore che diffonde fra le genti.</p>
            <p>La fondazione di un luogo d’incontro come questo è sempre seguita dalla massima prosperità. La prima
                Assemblea bahá’í di Teheran fu specialmente benedetta! In un anno crebbe così rapidamente che il numero
                dei suoi membri aumentò di nove volte. Oggi, nella lontana Persia, esistono molte assemblee come quella
                nelle quali gli amici di Dio s’incontrano nella gioia, nell’amore, nell’unità. Essi insegnano la Causa
                di Dio, educano gli ignoranti e avvicinano i cuori in una fraterna benevolenza. Aiutano i poveri e i
                bisognosi e danno loro il pane quotidiano. Offrono amore e cure agli ammalati e sono messaggeri di
                speranza e di consolazione ai desolati ed agli oppressi.</p>
            <p>Voi, a Parigi, fate che le vostre assemblee siano simili a quelle e diano frutti anche migliori!</p>
            <p>O amici di Dio! Se avrete fede nella Parola di Dio e sarete forti, se, seguendo i precetti di Bahá’u’lláh
                vorrete curare gli ammalati, risollevare i caduti, aiutare i poveri e i bisognosi, dare asilo i
                derelitti, proteggere gli oppressi, confortare gli addolorati e amare l’umanità con tutto il cuore,
                allora, io vi dico, questo luogo d’incontro vedrà presto un raccolto meraviglioso. Di giorno in giorno
                ogni suo membro farà progressi e diventerà sempre più spirituale. Ma dovrete acquisire solide fondamenta
                e ogni membro deve comprendere chiaramente i vostri scopi e desideri. Essi devono essere i seguenti:
                <br/>1.Mostrare compassione e benevolenza a tutta l’umanità.
                <br/>2. Servire l’umanità.
                <br/>3. Cercare di guidare e illuminare coloro che sono nelle tenebre.
                <br/>4. Essere gentili con tutti e mostrare affetto per ogni creatura.
                <br/>5. Avere un atteggiamento umile davanti a Dio, essere costanti nella preghiera, così da avvicinarsi
                a Dio ogni giorno di più.
                <br/>6. Essere così fedeli e sinceri in tutte le azioni che ogni membro possa farsi conoscere come
                personificazione di onestà, amore, fedeltà, gentilezza, generosità e coraggio. Essere distaccati da
                tutto ciò che non appartiene a Dio, essere attratti dall’Alito celeste, essere un’anima divina; così che
                il mondo sappia che un bahá’í è un essere perfetto.</p>
            <p>Sforzatevi di raggiungere tutto ciò in queste riunioni. Allora in verità voi, amici di Dio, vi riunirete
                con grande gioia. Aiutatevi l’un l’altro, diventate come una persona sola nell’unità perfetta.</p>
            <p>Prego Dio che ogni giorno possiate progredire nella spiritualità, che l’amore di Dio si manifesti in voi
                sempre di più, che i sentimenti dei vostri cuori siano purificati e che i vostri visi siano sempre
                rivolti verso di Lui. Possa ognuno di voi avvicinarsi alla soglia dell’unità ed entrare nel Regno. Possa
                ognuno di voi essere come una fiaccola ardente accesa col fuoco dell’Amore di Dio.</p>
        </Wrapper>
    </div>

)
