import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @tsignore
export const Numero41 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>41. IL PRIMO PRINCIPIO: LA RICERCA DELLA VERITÀ
            <br/>Avenue de Camoëns 4
            <br/>10 novembre 1911
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1410100672&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p>Il primo principio degli Insegnamenti di Bahá’u’lláh è:
                <br/><h2>La ricerca della verità.</h2></p>
            .
            <p>Chi desidera aver successo nella ricerca della Verità deve in primo luogo chiudere gli occhi su tutte le
                superstizioni tradizionali del passato.</p>
            <p>Gli ebrei hanno superstizioni tradizionali, i buddisti e gli zoroastriani non ne sono privi e così dicasi
                dei cristiani. Tutte le religioni sono state gradualmente incatenate da tradizioni e dogmi.</p>
            <p>Tutte considerano se stesse le sole custodi della verità e accusano le altre di essere in errore! Esse
                sono nel giusto, mentre le altre sono nell’errore! Gli ebrei credono di possedere soltanto loro la
                verità e condannano tutte le altre religioni. I cristiani affermano che la loro religione soltanto è la
                vera e che tutte le altre sono false e così dicono anche i buddisti e i musulmani. Tutti si limitano a
                se stessi! Se tutti si condannano a vicenda, dove potremo trovare la verità? Se tutti si contraddicono a
                vicenda, nessuno può aver ragione! Se ciascuno crede che la propria religione è l’unica vera, chiude gli
                occhi alla verità delle altre. Se, per esempio, un ebreo è legato alle pratiche esteriori della
                religione d’Israele, egli impedisce a se stesso di comprendere che la verità può esistere anche in altre
                religioni. Tutta la verità deve essere contenuta nella sua!</p>
            <p>Dobbiamo quindi distaccarci dalle forme e dalle pratiche esteriori della religione. Dobbiamo renderci
                conto che queste forme e queste pratiche, pur bellissime, sono indumenti che ricoprono il cuore radioso
                e le membra viventi della verità divina. Se desideriamo scoprire la verità nell’intimo d’ogni religione,
                dobbiamo abbandonare i pregiudizi della tradizione. Se uno zoroastriano crede che il Sole è Dio, come
                potrà unirsi alle altre religioni? Gli idolatri che credono nei loro idoli come possono capire l’unità
                di Dio?</p>
            <p>È dunque chiaro che per progredire nella ricerca della verità dobbiamo abbandonare le superstizioni. Se
                tutti i ricercatori seguissero questo principio, otterrebbero una visione chiara della verità.</p>
            <p>Se cinque persone si riuniscono per cercare la verità, dovrebbero incominciare col separarsi dalle
                proprie condizioni speciali e rinunciare a tutte le idee preconcette. Per trovare la Verità dobbiamo
                abbandonare i nostri pregiudizi, le nostre idee meschine. È essenziale avere la mente aperta. Se il
                nostro calice è colmo d’egoismo, non v’è più spazio per l’Acqua della Vita. Il fatto stesso di
                considerare noi stessi nel vero e tutti gli altri in errore è il più grave ostacolo sulla via dell’unità
                e l’unità è indispensabile per conseguire la verità, poiché la verità è una.</p>
            <p>È dunque imperativo rinunziare ai nostri pregiudizi specifici e alle superstizioni, se desideriamo
                sinceramente cercare la verità. Finché non faremo nella mente una distinzione netta fra i dogmi, le
                superstizioni e i pregiudizi, da una parte, e la verità dall’altra, non potremo avere successo. Se
                cerchiamo qualcosa ardentemente, la cerchiamo dappertutto. Questo principio deve essere tenuto presente
                nella ricerca della verità.</p>
            <p>Dobbiamo accettare la scienza. Nessuna verità può contraddirne un’altra. La luce è utile in qualsiasi
                lampada brilli. Una rosa è bella in qualsiasi giardino fiorisca. Una stella ha lo stesso splendore,
                brilli in Oriente o in Occidente. Liberatevi dai pregiudizi, così amerete il Sole della Verità da
                qualsiasi punto dell’orizzonte sorga. Capirete allora che la divina luce che brillò in Gesù Cristo
                brillò anche in Mosè e Buddha. Il ricercatore solerte perverrà a questa verità. Questo è quel che
                s’intende per «ricerca della verità».</p>
            <p>Ciò significa anche che dobbiamo essere pronti a liberarci di tutto quello che abbiamo appreso prima, di
                tutto ciò che ci ostacola sulla via della verità. Se necessario, non dobbiamo rifuggire dal ricominciare
                daccapo la nostra educazione. L’amore per una religione o una persona non deve accecarci al punto da
                impastoiarci nelle superstizioni! Quando ci saremo liberati da questi legami, cercando con mente libera,
                potremo allora giungere alla meta.</p>
            <p>«Cercate la verità e la verità vi renderà liberi». Così vedremo la verità in tutte le religioni, poiché
                la verità è in tutte le religioni e la verità è una.</p>

        </Wrapper>
    </div>

)
