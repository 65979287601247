import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero45 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>45. L’AMORE UNIVERSALE
            <br/>24 ottobre 191145. IL QUINTO PRINCIPIO: L’ABOLIZIONE DEI PREGIUDIZI
            <br/>Avenue de Camoëns 4, Parigi
            <br/>10 novembre 1911
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1414328851&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p>Tutti i pregiudizi, siano essi religiosi, razziali, politici o nazionalistici, devono essere abbandonati
                perché hanno causato la rovina del mondo. È una malattia gravissima che, se non è fermata a tempo, può
                causare la distruzione dell’intera razza umana. Ogni rovinosa guerra, con i suoi terribili spargimenti
                di sangue e le sue sofferenze, è stata causata dall’uno o dall’altro di questi pregiudizi.</p>
            <p>Le deplorevoli guerre che si combattono in questi giorni sono originate dal fanatico odio religioso di un
                popolo per un altro o da pregiudizi di razza o di colore.</p>
            <p>Finché queste barriere, erette dai pregiudizi, non saranno spazzate via, l’umanità non potrà trovare
                pace. Per questa ragione Bahá’u’lláh ha detto: «Questi pregiudizi distruggono l’umanità».</p>
            <p>Esaminiamo per primo il pregiudizio religioso. Considerate le nazioni dei cosiddetti popoli religiosi. Se
                veramente adorassero Dio, obbedirebbero alla Sua Legge che vieta di uccidere.</p>
            <p>Se i sacerdoti delle religioni adorassero veramente il Dio dell’amore e servissero la Luce divina,
                insegnerebbero ai popoli di attenersi al primo dei comandamenti: «Nutrire amore e carità per tutti».
                Purtroppo però notiamo il contrario, perché, spesso, sono i sacerdoti stessi ad incoraggiare le nazioni
                a combattere. L’odio religioso è sempre il più crudele!</p>
            <p>Tutte le religioni insegnano che dobbiamo amarci l’un l’altro, che dobbiamo anzitutto riconoscere le
                nostre manchevolezze prima di condannare i falli degli altri e che non dobbiamo mai considerarci
                superiori al prossimo! Dobbiamo aver cura di non esaltare noi stessi, perché ci potrebbe accadere
                d’essere umiliati.</p>
            <p>Chi siamo noi da poter giudicare? Come potremmo sapere chi agli occhi di Dio è l’uomo più retto? I
                pensieri di Dio non sono come i nostri! Quante persone, che sembravano sante ai loro amici, sono poi
                cadute nella massima umiliazione! Rammentate Giuda Iscariota. Ha incominciato bene, ma ricordate la sua
                fine! D’altro canto l’Apostolo Paolo in gioventù fu nemico di Cristo, ma poi Ne divenne il più fedele
                servitore. Com’è possibile quindi lodare se stessi e disprezzare gli altri?</p>
            <p>Cerchiamo allora di essere umili, senza pregiudizi, preferendo la bontà degli altri alla nostra. Non
                dobbiamo mai dire: «Io sono un credente, ma lui è un infedele», «Io sono vicino a Dio e lui è un
                reietto». Non potremo mai sapere quale sarà il giudizio finale! Aiutiamo quindi chiunque abbia bisogno
                di aiuto.</p>
            <p>Istruiamo l’ignorante e curiamo il fanciullo finché raggiunga la maturità. Quando troviamo una persona
                caduta nei più profondi baratri della sventura e del peccato, dobbiamo essere gentili verso di lei,
                prenderla per mano, aiutarla a rimettersi in piedi e a riprendere le forze. Dobbiamo guidarla con amore
                e tenerezza e trattarla da amica e non da nemica.</p>
            <p>Non abbiamo il diritto di considerare un altro essere mortale un peccatore.</p>
            <p>Quanto al pregiudizio di razza, esso è un’illusione, una superstizione pura e semplice! Dio ci ha creati
                tutti di una sola razza. Al principio non c’erano differenze, perché tutti discendiamo da Adamo. Al
                principio non v’erano neppure limiti e frontiere fra le varie terre. Nessuna parte della terra
                apparteneva a un popolo piuttosto che a un altro. Al cospetto di Dio non esistono differenze fra le
                varie razze. Perché inventare questo pregiudizio? Come si può sostenere una guerra basata su
                un’illusione?</p>
            <p>Dio non ha creato gli uomini perché si distruggano a vicenda! Tutte le razze, le tribù, le sètte e le
                classi condividono in ugual misura la Munificenza del Padre Celeste.</p>
            <p>La sola differenza consiste nel grado di fedeltà e di obbedienza alle leggi di Dio. Alcuni sono come
                fiaccole accese, altri brillano come stelle nel firmamento dell’umanità. Gli esseri superiori sono
                coloro che amano il genere umano, a qualsiasi nazione, credo o colore appartengano. Essi sono coloro ai
                quali Dio rivolgerà queste benedette parole: «Ben fatto, o Miei fedeli servitori». E quel giorno Egli
                non chiederà: «Sei inglese, francese o persiano? Vieni dall’Oriente o dall’Occidente?».</p>
            <p>L’unica reale differenza è questa: vi sono uomini celestiali e uomini terreni. I primi si sacrificano per
                servire l’umanità per amore dell’Altissimo, apportando armonia e unità e insegnano agli uomini le vie
                della pace e della buona volontà. Dall’altro canto, esistono gli egoisti, che odiano i fratelli, nei cui
                cuori i pregiudizi hanno rimpiazzato l’amore e la gentilezza e la cui influenza fomenta la discordia e
                la lotta.
                <p>A quale razza o colore appartengono questi due differenti settori dell’umanità? Ai bianchi, ai
                    gialli, ai neri, all’Oriente, all’Occidente, al Nord, al Sud? Se queste sono le differenze create da
                    Dio perché dovremmo inventarne delle altre? Anche il pregiudizio politico è pernicioso, una delle
                    massime causa di accanite lotte fra i figli degli uomini. V’è gente che trova godimento nel
                    fomentare la discordia, che incita costantemente il proprio paese a far guerre contro altre nazioni.
                    E perché? Credono di avvantaggiarlo a detrimento di tutti gli altri. Mandano eserciti a tormentare e
                    a distruggere, per diventare famosi nel mondo e per la gioia della conquista, perché si possa dire:
                    «Tale paese ne ha sconfitto un altro ponendolo sotto il giogo del suo più forte, superiore governo».
                    La vittoria conseguita a prezzo di tanto sangue non è duratura! Un giorno i conquistatori saranno
                    conquistati e i vinti saranno vittoriosi! Rammentate la storia passata. Non fu la Francia a vincere
                    la Germania più d’una volta? E non fu poi la nazione tedesca a sopraffare la Francia?</p>
                <p>Sappiamo che la Francia vinse l’Inghilterra e che quest’ultima a sua volta batté la Francia.</p>
                <p>Queste gloriose conquiste sono così effimere! Perché tenere loro e la loro fama in tale
                    considerazione da voler spargere il sangue del popolo per conseguirle? Vale una vittoria
                    l’inevitabile catena di mali prodotti dalla carneficina umana, il dolore, l’angoscia e la rovina che
                    devono sopraffare tante case in entrambe le nazioni? Non è possibile che soffra una sola
                    nazione.</p>
                <p>Ohimè, perché l’uomo, il disobbediente figlio di Dio, che dovrebbe essere l’esempio del potere della
                    legge spirituale, distoglie il viso dagli Insegnamenti divini, ponendo ogni sforzo nella distruzione
                    della guerra?</p>
                <p>Spero che in questo secolo illuminato, la divina Luce dell’amore diffonda la sua radiosità sul mondo
                    intero, affinando la sensibilità dell’intelletto e del cuore d’ogni essere umano, che la luce del
                    Sole della Verità guidi i politici a sciogliersi dai legami di tutti i pregiudizi e le superstizioni
                    per seguire con mente libera la Politica di Dio, poiché essa è possente, mentre la politica umana è
                    debole. Dio ha creato il mondo intero ed elargisce la Sua divina Munificenza ad ogni creatura!</p>
                <p>Non siamo forse servi di Dio? Dimenticheremo di seguire l’esempio del nostro Maestro, ignoreremo i
                    Suoi Comandamenti?</p>
                <p>Prego che il Regno venga sulla terra e che le tenebre siano spazzate via dalla radiosità del Sole
                    celeste.</p>

            </p>
        </Wrapper>
    </div>

)
