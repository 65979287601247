import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero55 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>55. LA PREGHIERA
            <br/>Cadogan Gardens 99, Londra
            <br/>26 dicembre 1912
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1420708237&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p>«Deve la preghiera prender forma di azione?».</p>
            <p>‘Abdu’l-Bahá: «Sì, nella Causa bahá’í le arti, le scienze e tutti i mestieri sono (considerati) atti di
                culto. L’uomo che fabbrica un pezzo di carta da scrivere, mettendo nel suo lavoro il meglio della sua
                abilità, eseguendolo coscienziosamente, concentrando tutte le forze per perfezionarlo, rende lode a Dio.
                In breve, ogni sforzo e ogni attività che vengono dal profondo del cuore dell’uomo devono considerarsi
                culto, se sono ispirati da elevate ragioni e dalla volontà di servire l’umanità. Questo è culto: servire
                l’umanità e provvedere ai bisogni del prossimo. Servire è pregare. Un medico che assiste un ammalato con
                gentilezza, con amore, senza pregiudizi e con fede nella solidarietà della razza umana rende lode a
                Dio.</p>
            <p>«Qual è lo scopo della vita?».</p>
            <p>‘Abdu’l-Bahá: «Acquisire virtù. Noi veniamo dalla terra. Perché siamo passati dal regno minerale al regno
                vegetale e da questo al regno animale? Per conseguire la perfezione in ciascuno di quei regni, per
                possedere le migliori qualità del minerale, acquisire il potere della crescita della pianta, avere gli
                istinti dell’animale e possedere i sensi della vista, dell’udito, dell’odorato, del tatto e del gusto,
                per passare finalmente dal regno animale al mondo dell’umanità ed esser dotati della ragione, del potere
                d’invenzione e delle forze dello spirito.</p>

        </Wrapper>
    </div>

)
