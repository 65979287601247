import React from 'react';
import styled from 'styled-components';
import {Nav, Navbar, NavDropdown} from 'react-bootstrap';


const Styles = styled.div`
    .navbar {
        background-color : green;
    }

    .navbar-brand, .navbar-nav .nav-link {
    color : #bbb;

    &:hover
    {
      color: white;
    }
  }`;

 export const NavigationBar = () => (
     <Styles>
         <Navbar expand="lg" bg="dark" variant="dark">
             <Navbar.Brand href="/">
                 <img
                     alt=""
                     src={"/favicon.ico"}
                     width="30"
                     height="30"
                     className="d-inline-block align-top"
                 />{' '}
                 Valtellina Bahá'i
             </Navbar.Brand>
             <Navbar.Toggle aria-controls="basic-navbar-nav"/>
             <Nav className="me-auto">
                 <NavDropdown title="Audio" id="audio-links">
                     <NavDropdown.Item href="/Saggezza/SaggezzaIndex">Saggezza di Abdu'l-Bahá</NavDropdown.Item>
                 </NavDropdown>
             </Nav>
             <Navbar.Collapse id="basic-navbar-nav">
                 <Nav.Item><Nav.Link href="/contact">Contattaci</Nav.Link></Nav.Item>
            </Navbar.Collapse>
        </Navbar>
     </Styles>
 )








