import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero49 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>49. IL NONO PRINCIPIO: LA NON INTERFERENZA DELLA RELIGIONE NELLA POLITICA
            <br/>Avenue de Camoëns 4 Parigi
            <br/>17 novembre 1911
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1414333780&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p>Nella condotta della sua vita l’uomo è motivato da due scopi essenziali: «la speranza di una ricompensa»
                e «il timore di una punizione».</p>
            <p>Questa speranza e questo timore devono essere tenuti in grande considerazione da coloro che occupano
                importanti cariche nei governi. La loro occupazione nella vita consiste nel consultarsi per redigere
                leggi e provvedere alla loro giusta amministrazione.</p>
            <p>La tenda dell’ordine nel mondo è sorretta da due colonne, «ricompensa e retribuzione».</p>
            <p>Nei governi dispotici, fatti da uomini senza fede divina e nei quali non c’è il timore della retribuzione
                spirituale, l’esecuzione delle leggi è tirannica e ingiusta.</p>
            <p>Non v’è migliore prevenzione dell’oppressione che questi due sentimenti, speranza e timore. Entrambi
                hanno conseguenze politiche e spirituali.</p>
            <p>Se gli amministratori della legge tenessero conto delle conseguenze spirituali delle loro decisioni e
                seguissero i dettami della religione, «diverrebbero agenti divini nel mondo dell’azione, rappresentanti
                di Dio per coloro che sono sulla terra e difenderebbero, per amore di Dio, gli interessi dei Suoi servi,
                così come fanno con i propri». Se un governante è cosciente della sua responsabilità e teme di sfidare
                la legge divina, i suoi giudizi sono equi. Soprattutto se crede che le conseguenze delle sue azioni lo
                seguiranno oltre la vita terrena e che «raccoglierà quel che avrà seminato», egli sicuramente eviterà
                l’ingiustizia e la tirannia.</p>
            <p>Al contrario se un amministratore pensasse che la sua responsabilità delle sue azioni finisce con la sua
                vita terrena, ignorando e misconoscendo i favori divini e il regno spirituale della gioia, gli
                mancherebbero l’incentivo per un giusto agire e l’ispirazione per distruggere l’oppressione e
                l’ingiustizia.</p>
            <p>Quando un sovrano sa che i suoi giudizi saranno pesati su una bilancia dal Giudice divino e che se non
                sarà trovato in difetto perverrà nel Regno celeste e sarà inondato dalla luce della Munificenza divina,
                quel sovrano agisce sicuramente con giustizia ed equità. Vedete com’è importante che i ministri di stato
                siano illuminati dalla religione!</p>
            <p>Con le questioni politiche, però, il clero non ha nulla a che fare! Le cose religiose non devono essere
                mai mescolate con la politica nel presente stato del mondo, poiché i loro interessi non sono
                identici.</p>
            <p>La religione s’interessa delle cose del cuore, dello spirito e della morale.</p>
            <p>La politica si occupa delle cose materiali della vita. I maestri delle religioni non devono invadere il
                regno della politica. Devono occuparsi dell’educazione spirituale dei popoli, consigliandoli bene e
                cercando di servire Dio e l’umanità. Devono cercare di risvegliare le aspirazioni spirituali e di
                accrescere la comprensione e il sapere dell’umanità, di migliorare la morale e di aumentare l’amore per
                la giustizia.</p>
            <p>Questo è in accordo con gli Insegnamenti di Bahá’u’lláh. Nei Vangeli è scritto: «Rendete dunque a Cesare
                le cose di Cesare e a Dio le cose di Dio» (Luca XX, 25).</p>
            <p>In Persia alcuni dei più importanti ministri dello stato sono religiosi, esemplari, venerano Iddio,
                temono di disobbedire alle Sue Leggi, giudicano secondo giustizia e governano il popolo con equità.
                Altri governanti di quella terra, invece, non hanno alcun timore di Dio, non pensano alle conseguenze
                delle proprie azioni, lavorano per soddisfare i propri desideri e hanno portato la Persia nei problemi e
                nelle difficoltà.</p>
            <p>Oh amici di Dio! Siate esempi viventi di giustizia! Così che per la grazia di Dio il mondo veda nelle
                vostre azioni che voi manifestate gli attributi della giustizia e della misericordia.</p>
            <p>La giustizia non ha limiti, è una qualità universale. Essa deve agire in tutte le classi dalle più umili
                alle più elevate. La giustizia dev’essere sacra e gli interessi dei popoli devono essere tenuti in
                considerazione. Desiderate per gli altri solo ciò che desiderate per voi stessi. Allora potremo gioire
                nel Sole della Giustizia che brilla sull’orizzonte di Dio.</p>
            <p>Ogni uomo è stato posto in una posizione d’onore e non deve abbandonarla. Un umile lavoratore che
                commette un’ingiustizia è tanto da biasimare quanto un famoso tiranno. Così tutti possiamo scegliere fra
                la giustizia e l’ingiustizia.</p>
            <p>Spero che ciascuno di voi divenga giusto e indirizzi i propri pensieri verso l’unità dell’umanità, che
                non facciate male al prossimo né parliate male di alcuno, che rispettiate i diritti di tutti gli uomini
                e vi preoccupiate degli interessi degli altri più che dei vostri. Così diverrete fiaccole di giustizia
                divina, agendo secondo gli insegnamenti di Bahá’u’lláh, che, durante la Sua vita, sopportò innumerevoli
                tribolazioni e persecuzioni per mostrare sulla terra le virtù del Mondo divino, permettendovi di
                comprendere la Supremazia dello Spirito e di gioire nella Giustizia di Dio.</p>
            <p>Per opera della Sua Misericordia, la Munificenza divina si riverserà su di voi e per questo io prego.</p>

        </Wrapper>
    </div>

)
