import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero13 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>13. I PREGIUDIZI RELIGIOSI
            <br/>27 ottobre 1911
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1386995011&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p>La base degli insegnamenti di Bahá’u’lláh è l’unità del genere umano. Il Suo più grande desiderio fu che
                la benevolenza e l’amore fossero sempre nel cuore degli uomini.</p>
            <p>Come Bahá’u’lláh esortò la gente a metter fine alla discordia e alla guerra, così io voglio esporvi la
                causa principale della discordia fra le nazioni. La causa primaria è che i capi e i maestri della
                religione mal la espongono. Essi insegnano ai loro seguaci che la religione da essi professata è la sola
                che piaccia a Dio e che i seguaci di qualsiasi altra confessione sono condannati dal Padre Amoroso e
                privati della Sua grazia e della Sua misericordia. Perciò tra gli uomini nascono la riprovazione, il
                disprezzo, l’odio e le liti. Se si potessero spazzar via questi pregiudizi religiosi, le nazioni presto
                troverebbero la concordia e la pace.</p>
            <p>Una volta andai a Tiberiade, dove gli ebrei hanno un tempio. Io abitavo di fronte a questo tempio e
                potevo così udire il rabbino parlare alla sua congregazione di ebrei. Diceva:<br/>
                O ebrei, voi siete il vero popolo di Dio, tutte le altre razze e religioni sono di Satana. Dio ha eletto
                voi, discendenti d’Abramo, e ha profuso le Sue benedizioni su di voi. Dio vi ha mandato Mosè, Giacobbe,
                Giuseppe e molti altri grandi profeti. Questi profeti furono tutti della vostra razza.</p>
            <p>«Fu per voi che Dio spezzò la potenza del Faraone e fece sì che il Mar Rosso si disseccasse. Per voi Egli
                mandò la manna dal cielo perché ve ne cibaste e dalla pietra fece scaturire l’acqua per spegnere la
                vostra sete. Siete veramente il popolo eletto di Dio, siete superiori a tutte le razze esistenti sulla
                terra! Perciò tutte quelle razze sono aborrite da Dio e da Lui condannate. In verità voi sottometterete
                e governerete il mondo e tutti gli uomini diverranno vostri schiavi.</p>
            <p>«Non vi contaminate praticando coloro che non sono della vostra religione e non ne diventate amici».</p>
            <p>Quando il rabbino ebbe finito il suo eloquente discorso, i suoi ascoltatori erano contenti e soddisfatti.
                Non mi è possibile descrivervi la loro gioia!</p>
            <p>Ahimé! Sono le persone indotte in errore come costoro che cagionano divisione e odio sulla terra. Ancora
                oggi milioni di persone adorano gli idoli e i seguaci delle grandi religioni del mondo sono in guerra
                fra loro. Da 1300 anni cristiani e musulmani sono in lotta, mentre con un po’ di buona volontà le loro
                questioni si potrebbero risolvere e fra loro potrebbero regnare l’armonia e la pace e il mondo potrebbe
                essere tranquillo.</p>
            <p>Nel Corano leggiamo che Muḥammad disse ai Suoi discepoli:<br/>
                «Perché non credete in Cristo e nel Vangelo? Perché non accettate Mosè e i profeti, giacché sicuramente
                la Bibbia è il libro di Dio? In verità Mosè fu un sublime profeta e Gesù ebbe in sé lo Spirito Santo.
                Egli venne al mondo pel Potere di Dio, nato dallo Spirito Santo e dalla beata vergine Maria. Maria, Sua
                madre, fu una santa venuta dal cielo. Ella trascorse i suoi giorni nel tempio a pregare e le fu mandato
                cibo dal cielo. Zaccaria venne a lei e le chiese da dove veniva il cibo e Maria rispose: “Dal cielo”.
                Certamente Dio esaltò Maria sopra tutte le donne» (Corano III, 32).</p>
            <p>Così Muḥammad insegnò al suo popolo di Mosè e di Gesù, lo rimproverò di non aver fede in questi grandi
                Maestri e gli impartì lezioni di tolleranza e verità. Muḥammad fu mandato da Dio per educare un popolo
                selvaggio come gli animali selvatici. Quel popolo era senza intelletto, non aveva sentimenti d’amore, di
                simpatia e di pietà. Le donne erano tenute in così poco conto e disprezzate che un padre poteva
                seppellire una figlia viva e un uomo poteva avere quante mogli voleva e trattarle da schiave.</p>
            <p>Tra questa gente così selvaggia Muḥammad fu mandato col Suo Messaggio divino. Egli insegnò che
                l’adorazione degli idoli era un grande errore e che si doveva invece venerare Cristo, Mosè e i Profeti.
                Sotto la Sua influenza quel popolo diventò man mano più civile e illuminato e si sollevò dalla
                degradazione in cui Muḥammad lo trovò. Non fu questa una grande opera, degna di ogni lode, di rispetto e
                d’amore?</p>
            <p>Guardate il Vangelo di nostro Signore Gesù Cristo e ammiratene la gloria! Eppure ancora oggi vi sono
                uomini che non ne capiscono la sublime bellezza e non ne comprendono le parole di saggezza.</p>
            <p>Cristo proibì la guerra. Quando il discepolo Pietro, volendo difendere il suo Signore, tagliò l’orecchio
                al servo del Grande Sacerdote Gesù gli disse: «Riponi la spada nella guaina». Tuttavia, contro
                l’esplicito comando del Signore, che essi pretendono di servire, gli uomini disputano, fanno la guerra e
                si ammazzano. I consigli e gli insegnamenti di Gesù sembrano completamente dimenticati.</p>
            <p>Dunque non bisogna attribuire ai Maestri e ai Profeti le cattive azioni dei loro seguaci. Se sacerdoti,
                maestri e popolo agiscono contrariamente alla religione che professano, ne hanno forse colpa Gesù o gli
                altri Maestri?</p>
            <p>Al popolo musulmano fu insegnato a credere che Cristo venne da Dio, che nacque dallo Spirito e che perciò
                Egli dev’essere glorificato tra tutti gli uomini. Mosè fu un profeta di Dio e rivelò nel Suo tempo e al
                popolo al quale fu mandato, il Libro di Dio.</p>
            <p>Muḥammad riconobbe il sublime splendore di Cristo e la grandezza di Mosè e dei profeti. Se il mondo
                intero riconoscesse la grandezza di Muḥammad e di tutti gli altri Maestri divini, la discordia e le
                lotte sparirebbero dal mondo e il Regno di Dio verrebbe fra gli uomini.</p>
            <p>I seguaci dell’Islam che glorificano Cristo non sono per questo umiliati.</p>
            <p>Cristo fu il profeta dei cristiani, Mosè degli ebrei. Perché non possono i seguaci di ciascun profeta
                riconoscere e onorare anche gli altri profeti? Se gli uomini imparassero la lezione della tolleranza,
                della comprensione e dell’amore fraterno, l’Unità del mondo sarebbe un fatto compiuto.</p>
            <p>Bahá’u’lláh passò tutta la vita predicando l’Amore e l’Unità. Scacciamo dunque da noi ogni pregiudizio e
                intolleranza e sforziamoci con tutto il cuore e con tutta l’anima di instaurare accordo e unità fra i
                cristiani e i musulmani.</p>
        </Wrapper>
    </div>

)
