import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero10 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>10. LA PRIGIONIA DI ‘ABDU’L-BAHÁ
            <br/>Avenue de Camoëns 4
            <br/>Mercoledì, 25 ottobre 1911
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1384253116&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p>Mi rincresce molto di avervi fatto attendere questa mattina, ma ho tanto da fare, in così poco tempo, per
                la Causa dell’amore di Dio!</p>
            <p>Non vi dispiacerà di avermi aspettato un po’. Io ho passato anni e anni in prigione aspettando di poter
                venire qui a vedervi.</p>
            <p>Quel che più importa è che, grazie a Dio, i nostri cuori battono sempre all’unisono e con una medesima
                aspirazione sono portati verso l’amore di Dio. Non ha forse la generosità del Cielo unito in un solo
                vincolo i nostri desideri, i nostri cuori e i nostri spiriti? Le nostre preghiere non sono forse dirette
                a ottenere l’unione e l’armonia di tutti gli uomini? E perciò non siamo sempre insieme?</p>
            <p>Ieri sera quando sono ritornato a casa dopo essere stato dal signor Dreyfus ero molto stanco, ma non ho
                dormito, sono stato sveglio a pensare.</p>
            <p>Mi dicevo: O Dio, sono qui a Parigi! Che cos’è Parigi e chi sono io? Non avrei mai potuto immaginare che
                dall’oscurità della mia prigione sarei potuto venir qui a vedervi, sebbene quando mi fu letta la
                sentenza, non vi credetti.</p>
            <p>Mi fu detto che ‘Abdu’l-Ḥamíd (Il Sultano di Turchia) mi aveva condannato alla reclusione a vita e io
                dissi: «Non è possibile! Non resterò sempre in carcere! Se ‘Abdu’l-Ḥamíd fosse stato immortale, la sua
                sentenza avrebbe potuto essere eseguita. È certo invece che un giorno sarò libero. Il mio corpo sarà
                prigioniero per un certo tempo, ma ‘Abdu’l-Ḥamíd non ha potere sul mio spirito. Esso rimarrà libero,
                nessuno potrà imprigionarlo».</p>
            <p>Liberato dalla prigionia con l’aiuto di Dio, eccomi fra gli amici di Dio e a Lui sono grato.</p>
            <p>Diffondiamo la Causa di Dio, per la quale sono stato perseguitato.</p>
            <p>Quale privilegio poterci incontrare qui in libertà! Che felicità che Dio abbia voluto che lavorassimo
                insieme per l’avvento del Regno divino!</p>
            <p>Siete contenti di ricevere un ospite liberato dalla prigione per portarvi questo glorioso Messaggio? Chi
                avrebbe pensato a un simile incontro? Adesso, per Grazia di Dio, per il Suo Potere meraviglioso, io,
                condannato alla reclusione a vita in una lontana città dell’Oriente, sono qui a Parigi e parlo con
                voi.</p>
            <p>Da ora in poi saremo sempre insieme, cuore, anima e spirito, e lavoreremo sempre alacremente finché tutti
                gli uomini siano uniti sotto il baldacchino del Regno divino, a cantare inni di pace.</p>
        </Wrapper>
    </div>
)
