import React from 'react';
import {SlideShow} from "./SlideShow";
import { quotes } from "./Quotes";
import { images } from "./Images";
import { times } from "./Times";
export const Home = () => {
    return (
        <SlideShow images={images} quotes={quotes} times={times}/>
    )
}





