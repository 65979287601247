import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero38 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>38. L’ESIGUITÀ DEL NOSTRO NUMERO NON CI DEVE SCORAGGIARE
            <br/>25 novembre 1911
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1410098980&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p>Quando Cristo apparve, Si manifestò a Gerusalemme. Egli invitò gli uomini al Regno di Dio, li chiamò alla
                Vita eterna e disse loro di acquisire le perfezioni umane. La Luce della guida irradiò da quella
                raggiante Stella e alla fine Egli dette la vita in sacrificio per l’umanità.</p>
            <p>Per tutta la Sua vita benedetta, Egli soffrì oppressioni e tribolazioni, ma nonostante tutto ciò,
                l’umanità Gli fu nemica!</p>
            <p>Fu rinnegato, deriso, maltrattato e maledetto. Non fu trattato come un uomo. Eppure, ad onta di ciò, fu
                la personificazione della pietà, della bontà suprema e dell’amore.</p>
            <p>Egli amò tutta l’umanità, ma essa Lo trattò come un nemico e non seppe apprezzarLo, non dette alcun
                valore alle Sue parole e non si illuminò alla fiamma del Suo amore.</p>
            <p>Solo più tardi gli uomini capirono chi Egli fosse, compresero che era la Luce sacra e divina e che le Sue
                parole portavano la Vita eterna.</p>
            <p>Il Suo cuore era pieno d’amore per tutto il mondo, la Sua bontà era destinata a tutti. E quando
                incominciarono ad accorgersi di tutto questo, gli uomini si pentirono, ma Egli era stato crocifisso!</p>
            <p>Solo molti anni dopo la Sua ascensione gli uomini capirono chi era. Al tempo della Sua ascensione, Egli
                aveva soltanto pochissimi discepoli. Soltanto un numero relativamente ristretto di seguaci credette nei
                Suoi precetti e osservò le Sue leggi. Gli ignoranti dicevano: «Chi è costui? Ha soltanto qualche
                discepolo». Ma chi sapeva diceva: «Egli è il Sole che risplenderà in Oriente e in Occidente, è la
                Manifestazione che darà vita al mondo».</p>
            <p>Ciò che i primi discepoli avevano visto, il mondo comprese più tardi.</p>
            <p>Perciò, voi che siete in Europa non scoraggiatevi perché siete pochi o perché la gente crede che la
                vostra Causa non abbia alcuna importanza. Se poche persone intervengono alle vostre riunioni, non
                perdetevi d’animo e se siete derisi e contraddetti, non affliggetevi, poiché gli Apostoli di Cristo
                ebbero a sopportare le stesse cose. Furono oltraggiati e perseguitati, maledetti e maltrattati, ma alla
                fine riuscirono vittoriosi e si scoprì che i loro nemici erano in errore.</p>
            <p>Se la storia dovesse ripetersi e tutte queste cose dovessero accadere anche a voi, non rattristatevi, ma
                gioite e ringraziate Dio d’essere stati chiamati a soffrire come soffrirono i santi del passato. Siate
                gentili con chi vi fa opposizione, rimanete saldi nella fede di fronte a chi vi contraddice, cercate e
                trattate con benevolenza chi vi abbandona o fugge da voi. Non fate torto ad alcuno, pregate per tutti,
                cercate di far brillare la vostra luce nel mondo e fate che il vostro vessillo sventoli in alto nei
                Cieli. Allora lo squisito profumo delle vostre nobili vite penetrerà dappertutto. La luce della verità
                accesa nei vostri cuori illuminerà il lontano orizzonte!</p>
            <p>L’indifferenza e il disprezzo del mondo non hanno la benché minima importanza, mentre le vostre vite
                saranno importantissime.</p>
            <p>Tutti coloro che cercano la verità nel Regno celeste brillano come stelle, sono come alberi carichi di
                frutti squisiti, come mari pieni di perle preziose.</p>
            <p>Abbiate fede nella misericordia di Dio e diffondete la Verità divina!</p>

        </Wrapper>
    </div>

)
