import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero57 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>57. IL PROGRESSO DELL’ANIMA
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1420709593&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p><i>«È col dolore o con la gioia che l’anima progredisce maggiormente in questo mondo?»</i>.</p>
            <p><i>‘Abdu’l-Bahá</i>: «La mente e lo spirito dell’uomo progrediscono quando egli è provato dalle
                sofferenze. Più la terra è arata, meglio si sviluppa il seme, più abbondante sarà il raccolto. Come
                l’aratro solca profondamente la terra, liberandola dalle erbacce e dai rovi, così la sofferenza e
                l’afflizione liberano l’uomo dalle meschinità di questa vita mondana e gradatamente egli perviene a uno
                stato di completo distacco. Allora il suo atteggiamento in questo mondo è quello della felicità divina.
                L’uomo è, per così dire, immaturo. Il calore del fuoco della sofferenza lo matura. Guardate il passato e
                vedrete che gli uomini più grandi hanno sofferto di più».</p>
            <p><i>«Chi ha raggiunto lo sviluppo attraverso la sofferenza, deve temere la felicità?»</i>.</p>
            <p><i>‘Abdu’l-Bahá</i>: «Con la sofferenza egli ottiene una felicità eterna, che niente potrà portargli via.
                Gli apostoli di Cristo soffrirono e arrivarono alla felicità eterna».</p>
            <p><i>«È allora impossibile di ottenere la felicità senza soffrire?».</i></p>
            <p><i>‘Abdu’l-Bahá</i>: «Per ottenere la felicità eterna si deve soffrire. Chi è giunto al punto di
                sacrificare se stesso ha la vera gioia. Le gioie del mondo svaniscono».</p>
            <p><i>«Può un’anima trapassata conversare con una ancora sulla terra?».</i></p>
            <p><i>‘Abdu’l-Bahá</i>: «Può conversare, ma non come conversiamo noi. Non c’è dubbio che le forze dei mondi
                superiori si intrecciano con quelle di questo mondo. Il cuore dell’uomo è aperto all’ispirazione. Questa
                è la comunicazione spirituale. Come si parla in sogno con un amico pur rimanendo in silenzio, così
                avviene nella conversazione dello spirito. Un uomo può conversare con se stesso, domandandosi: “Posso
                farlo? È consigliabile che faccia questo lavoro?”. Allo stesso modo si conversa con l’io più elevato».
            </p>

        </Wrapper>
    </div>

)
