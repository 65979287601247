import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero27 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>27. IL VERO SIGNIFICATO DEL BATTESIMO DELL’ACQUA E DEL FUOCO
            <br/>9 novembre 1911</Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1408987624&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p>Nel Vangelo secondo Giovanni, Gesù disse: «In verità vi dico che se alcuno non è nato d’acqua e di
                Spirito, non può entrare nel Regno di Dio» (Giovanni III, 5). I preti hanno interpretato questa frase
                nel senso che il battesimo sia necessario per la salvazione. In un altro Vangelo è detto: «Egli vi
                battezzerà con lo Spirito Santo e col fuoco» (Luca III, 16-17).</p>
            <p>Così l’acqua del battesimo e il fuoco sono una cosa sola! Non può intendersi che «l’acqua» di cui si
                parla sia l’acqua elemento fisico, poiché questa è precisamente l’opposto di «fuoco», l’una distrugge
                l’altro. Quando nel Vangelo parla di «acqua», Cristo intende riferirSi a ciò che produce la vita, perché
                senza acqua nessuna creatura al mondo potrebbe vivere. Minerali, vegetali, animali e uomini, tutti hanno
                necessità dell’acqua per la loro stessa esistenza. Sicuro, le ultime scoperte scientifiche ci dimostrano
                che anche il minerale ha una certa forma di vita e anch’esso ha bisogno dell’acqua per la propria
                esistenza.</p>
            <p>L’acqua è la causa della vita, e quando parla dell’acqua, Cristo simboleggia ciò che è la causa della
                Vita Eterna.</p>
            <p>Quest’acqua di cui Egli parla, quest’acqua che dà la vita, assomiglia al fuoco, che non è altro che
                l’Amor di Dio e questo amore è la vita delle nostre anime.</p>
            <p>Col fuoco dell’Amore di Dio si brucia il velo che ci separa dalle Realtà celesti e così, con chiara
                visione, possiamo spingerci in alto e in avanti, progredendo nelle vie della virtù e della santità e
                diventando apportatori di luce al mondo.</p>
            <p>Niente vi è di più grande o di più sacro che l’Amore di Dio. Esso risana l’ammalato, è un balsamo per il
                ferito, è gioia e consolazione a tutto il mondo e per suo mezzo soltanto l’uomo può ottenere la Vita
                eterna. L’essenza di tutte le religioni è l’amore di Dio ed esso è la base di tutti gli insegnamenti
                sacri.</p>
            <p>Fu l’Amore di Dio che guidò Abramo, Isacco e Giacobbe, che dette forza a Giuseppe in Egitto e coraggio e
                pazienza a Mosè.</p>
            <p>Grazie all’amore di Dio, Gesù fu mandato nel mondo a dare il Suo esempio ispiratore di una vita perfetta,
                di sacrificio di se stesso e di devozione, a portare agli uomini il messaggio della Vita eterna. Fu
                l’amore di Dio che dette a Muḥammad il potere di portare gli arabi da uno stato di animale degradazione
                a un più elevato stato di esistenza.</p>
            <p>Fu l’Amore di Dio che sostenne il Báb e Lo portò al Suo sacrificio supremo, facendo del Suo petto il
                volontario bersaglio di mille pallottole.</p>
            <p>Finalmente fu l’Amore di Dio che dette all’Oriente Bahá’u’lláh e che manda ora la luce dei Suoi
                insegnamenti al lontano Occidente e da un polo all’altro.</p>
            <p>Così esorto tutti voi, che avete una chiara idea del suo potere e della sua bellezza, a sacrificare tutti
                i vostri pensieri, le vostre parole e azioni per portare ad ogni cuore la conoscenza dell’Amore di
                Dio.</p>

        </Wrapper>
    </div>

)
