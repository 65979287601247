import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";

// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero3 = () => (
    <div>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>3. DIO È IL GRANDE MEDICO COMPASSIONEVOLE, CHE SOLO OPERA LA VERA GUARIGIONE
            <br/>19 ottobre 1911
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1376598082&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p>
                La guarigione vera viene da Dio! Vi sono due cause di malattia: una materiale, l’altra spirituale. Se la
                malattia è del corpo, è necessario un rimedio materiale. Se la malattia è dell’anima, occorre un rimedio
                spirituale.
            </p>
            <p>
                Se la benedizione del Cielo è su di noi mentre qualcuno ci cura, allora soltanto possiamo guarire,
                perché la medicina non è altro che il mezzo esteriore e visibile per il quale otteniamo dal Cielo la
                guarigione. Se lo spirito non è risanato, la cura del corpo non vale nulla. Tutto è nelle mani di Dio e
                senza di Lui non possiamo avere la salute.
            </p>
            <p>
                Molti uomini sono morti proprio della malattia sulla quale avevano fatto studi speciali. Aristotele, per
                esempio, che aveva fatto uno studio speciale sulla digestione, morì di una malattia di stomaco. Avicenna
                fu uno specialista del cuore, ma morì di mal di cuore. Dio è il grande Medico compassionevole, il solo
                che ha il potere di dare la vera guarigione.
            </p>
            <p>
                Tutte le creature dipendono da Dio, per quanto grandi sembrino la loro sapienza, il loro potere e la
                loro indipendenza.
            </p>
            <p>
                Guardate i potenti re della terra. Hanno tutta la potenza che gli uomini possono dare loro. Ma quando la
                morte li chiama, devono obbedire come il più umile dei loro sudditi.
            </p>
            <p>
                Guardate anche gli animali come sono deboli nella loro apparente forza! L’elefante, il più grande degli
                animali, è tormentato dalle mosche e il leone non può sfuggire una puntura d’insetto. E anche l’uomo, la
                forma più alta degli esseri creati, ha bisogno di tante cose per vivere. Prima di tutto ha bisogno
                dell’aria e se questa gli manca per qualche minuto, muore. Ha poi bisogno di acqua, di cibo, di abiti,
                del calore e di tante altre cose. È circondato da difficoltà e pericoli, ai quali il suo corpo da solo
                non può resistere. Se un uomo guarda il mondo attorno a sé, vede che tutte le cose create sono
                dipendenti e prigioniere delle leggi della Natura.
            </p>
            <p>
                L’uomo da solo, grazie al suo potere spirituale, ha potuto rendersi libero, elevarsi al di sopra del
                mondo della materia e asservirlo.
            </p>
            <p>
                Senza l’aiuto di Dio l’uomo è come una bestia che muore. Ma Dio gli ha elargito un tale meraviglioso
                potere affinché egli possa sempre guardare in alto e ricevere, assieme ad altri doni, la guarigione
                dalla Sua divina Munificenza.
            </p>
            <p>
                Ma purtroppo l’uomo non è riconoscente per questo bene supremo. Dorme il sonno della negligenza,
                incurante della grande misericordia che Dio ha mostrato verso di lui, il viso distolto della luce e
                prosegue sulla sua via nell’oscurità.
            </p>
            <p>
                La mia ardente preghiera è che voi non siate così, ma che teniate il viso sempre rivolto verso la luce,
                cosicché possiate essere come fiaccole luminose nelle tenebre di questa vita.
            </p>
        </Wrapper>
    </div>

)
