import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero50 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>50. IL DECIMO PRINCIPIO: LA PARITÀ DEI SESSI
            <br/>Avenue de Camoëns 4, Parigi
            <br/>14 Novembre 1911
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1414332685&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p>Il decimo principio degli insegnamenti di Bahá’u’lláh è la parità dei sessi.</p>
            <p>Dio ha creato tutte le creature a coppie. Uomini, animali o vegetali, tutte le cose di questi tre regni
                sono di due sessi e fra loro v’è parità assoluta.</p>
            <p>Nel regno vegetale vi sono piante maschi e piante femmine. Hanno pari diritti e possiedono in parti
                uguali la bellezza della loro specie, sebbene si possa dire che la pianta che produce frutti è superiore
                a quella che non ne produce.</p>
            <p>Nel regno animale vediamo che il maschio e la femmina hanno diritti uguali e che entrambi godo-no dei
                vantaggi della loro specie.</p>
            <p>Ora, nei due regni inferiori della natura non v’è questione di superiorità di un sesso sull’altro. Nel
                mondo degli uomini si osserva invece una grande differenza. Il sesso femminile è trattato da inferiore e
                non gli sono concessi pari diritti e privilegi. Questa condizione non è dovuta alla natura, ma
                all’educazione. Nella creazione divina non c’è questa distinzione. Un sesso non è superiore all’altro
                agli occhi di Dio. Perché dunque un sesso deve proclamare l’inferiorità dell’altro negandogli diritti e
                privilegi, come se Dio gliene avesse dato autorità? Se le donne ricevessero una educazione uguale a
                quella degli uomini, il risultato dimostrerebbe che entrambi i sessi hanno un’identica capacità di
                apprendere.</p>
            <p>Sotto certi aspetti la donna è superiore all’uomo. È di cuore più tenero, più ricettiva e la sua
                intuizione è più intensa.</p>
            <p>Non si può negare che in vari aspetti la donna è oggi più arretrata dell’uomo, ma questa temporanea
                inferiorità è dovuta alla mancanza di opportunità educative. Nelle necessità della vita, la donna ha
                poteri innati maggiori dell’uomo, perché è a lei che l’uomo deve la propria esistenza.</p>
            <p>Se la madre è istruita, i figli saranno ben educati. Quando la madre è saggia, i figli saranno guidati
                sulla via della saggezza. Se la madre è religiosa, mostrerà ai figli come devono amare Iddio. Se la
                madre è morale, guiderà i suoi piccoli sulle strade della rettitudine.</p>
            <p> chiaro perciò che la generazione futura dipende dalle madri d’oggi. Non è questa una responsabilità
                vitale per la donna? Non le spetta ogni possibile vantaggio per prepararla a questo compito?</p>
            <p>Perciò sicuramente Iddio non gradirà che uno strumento così importante come la donna debba soffrire per
                mancanza dell’educazione necessaria per raggiungere le perfezioni desiderabili e indispensabili per la
                grande opera della sua vita! La Giustizia divina richiede che i diritti di ambo i sessi siano ugualmente
                rispettati perché nessuno è superiore all’altro agli occhi del Cielo. La dignità al cospetto di Dio non
                dipende dal sesso, ma dalla purezza e dalla luminosità del cuore. Le virtù umane appartengono ugualmente
                a tutti!</p>
            <p>La donna deve quindi sforzarsi di raggiungere una maggiore perfezione, di esser pari all’uomo sotto ogni
                aspetto, di progredire in tutti quei campi in cui è rimasta indietro, così che l’uomo sia costretto a
                riconoscerne l’uguaglianza di capacità e di successo.</p>
            <p>In Europa le donne hanno fatto maggiore progresso che nell’Oriente, ma c’è ancora molto da fare! Quando
                gli studenti arrivano alla fine del corso scolastico, ha luogo un esame, il cui risultato ne determina
                la capacità e la conoscenza. Così avverrà con la donna. Le sue azioni dimostreranno il suo potere e non
                ci sarà più bisogno di proclamarlo a parole.</p>
            <p>È mia speranza che le donne dell’Oriente, come le loro sorelle dell’Occidente, progrediscano rapidamente
                finché l’umanità raggiungerà la perfezione.</p>
            <p>La generosità di Dio è per tutti e dà la capacità necessaria per ogni progresso. Quando gli uomini
                riconosceranno la parità delle donne, esse non avranno più bisogno di lottare per i loro diritti! Uno
                dei principi di Bahá’u’lláh è dunque la parità dei sessi.</p>
            <p>Le donne devono compiere il massimo sforzo per acquistare potere spirituale e crescere nella virtù della
                saggezza e della santità, finché la loro illuminazione e il loro sforzo riusciranno a realizzare l’unità
                del genere umano. Devono lavorare con ardente entusiasmo per diffondere l’Insegnamento di Bahá’u’lláh
                fra i popoli così che la luce radiosa della Grazia divina possa avviluppare le anime di tutte le nazioni
                del mondo!</p>

        </Wrapper>
    </div>

)
