import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero36 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>36. LE VIRTÙ E I SENTIMENTI UMANI PERFETTI
            <br/>23 novembre 1911
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1408994017&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p>‘Abdu’l-Bahá disse:
                <br/>Dovreste essere tutti felici e ringraziare Dio per il grande privilegio che avete.</p>
            <p>Questa è una riunione puramente spirituale! Sia lode a Dio che i vostri cuori sono rivolti a Lui e le
                vostre anime sono attratte verso il Regno divino, che avete aspirazioni spirituali e che i vostri
                pensieri si elevano al di sopra del mondo della polvere.</p>.
            <p>Voi appartenete al mondo della purezza e non vi accontentate di vivere la vita dell’animale e passare
                così i vostri giorni mangiando, bevendo e dormendo. Siete veramente uomini. I vostri pensieri e le
                vostre ambizioni tendono ad acquisire la perfezione umana. Vivete per far bene e dar felicità agli
                altri. La vostra grande aspirazione è dar conforto agli afflitti, forza ai deboli e speranza alle anime
                in angoscia. Giorno e notte i vostri pensieri sono rivolti verso il Regno, i vostri cuori sono pieni
                dell’amore di Dio.</p>
            <p>Così non conoscete né contrarietà, né avversione, né odio, perché ogni creatura vi è cara e cercate il
                bene di tutti.</p>
            <p>Questi sono le virtù e i perfetti sentimenti umani. Un uomo che ne fosse assolutamente privo sarebbe
                meglio che non esistesse. Se una lampada non può dare più luce, è meglio che sia distrutta. Se un albero
                non dà frutti, è meglio tagliarlo perché non fa altro che ingombrare il terreno.</p>
            <p>In verità, per un uomo è mille volte meglio morire che continuare a vivere senza virtù.</p>
            <p>Abbiamo gli occhi per vedere, ma se non li usiamo, che profitto ci danno? Abbiamo orecchi per udire, ma
                se siamo sordi, a che cosa ci servono?</p>
            <p>Abbiamo la lingua per lodare Dio e proclamare le liete novelle, ma se siamo muti, è del tutto
                inutile.</p>
            <p>L’Amorevolissimo Iddio creò l’uomo perché irradiasse la Luce divina e illuminasse il mondo con le parole,
                le azioni e la sua vita. Se è senza virtù, un uomo non vale più d’un semplice animale e un animale privo
                di intelligenza è cosa da poco.</p>
            <p>Il Padre Celeste dette all’uomo l’inestimabile dono dell’intelligenza perché potesse diventare una luce
                spirituale che penetra le tenebre della materialità e porta al mondo la bontà e la verità. Se seguirete
                scrupolosamente gli insegnamenti di Bahá’u’lláh, diventerete davvero la luce del mondo, l’anima del
                corpo del mondo, conforto e aiuto per l’umanità e fonte di salvezza per l’universo intero. Sforzatevi
                dunque con tutto il cuore e con tutta l’anima di seguire i precetti di Bahá’u’lláh e siate sicuri che,
                se riuscirete a vivere come Egli ci indica, avrete vita eterna e gioia perenne nel Regno dei cieli e un
                alimento celeste vi sarà mandato per darvi forza durante tutti i vostri giorni.</p>
            <p>La mia fervida preghiera è che ognuno di voi possa ottenere questa gioia perfetta!</p>

        </Wrapper>
    </div>

)
