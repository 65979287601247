import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero30 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>30. I DESIDERI E LE PREGHIERE DI ‘ABDU’L-BAHÁ
            <br/>15 novembre 1911
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1408989964&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p>‘Abdu’l-Bahá disse:
                <br/>Benvenuti voi tutti, vi amo con grande tenerezza!</p>
            <p>Giorno e notte prego il Cielo per voi affinché siate forti e possiate tutti partecipare alle benedizioni
                di Bahá’u’lláh ed entrare nel Regno.</p>
            <p>Supplico Iddio che diveniate esseri nuovi, illuminati dalla Luce divina, come lampade accese e che la
                conoscenza dell’Amore di Dio si diffonda da un capo all’altro dell’Europa.</p>
            <p>Possa questo amore infinito tanto colmare i vostri cuori e le vostre menti, da non lasciar posto alla
                tristezza da far involare i vostri cuori gioiosi come uccelli verso il divino Splendore.</p>
            <p>Possano i vostri cuori diventare nitidi e puri come specchi tersi, nei quali si rifletta la piena gloria
                del Sole della Verità.</p>
            <p>Possano i vostri occhi aprirsi per vedere i segni del Regno di Dio e i vostri orecchi udire, con perfetta
                comprensione, la Proclamazione celeste che risuona fra voi.</p>
            <p>Possano le vostre anime ricevere aiuto e conforto e, così rinforzate, vivere secondo gli insegnmenti di
                Bahá’u’lláh.</p>
            <p>Prego che ciascuno di voi sia come una fiamma d’amore nel mondo e che la chiarezza della vostra luce e il
                calore del vostro affetto raggiungano il cuore di ogni triste e addolorato figlio di Dio.</p>
            <p>Possiate essere come brillanti stelle, sempre chiare e luminose nel Regno.</p>
            <p>Vi consiglio di studiare attentamente gli insegnamenti di Bahá’u’lláh, così da potere, con l’aiuto di
                Dio, divenire bahá’í di fatto e in verità.</p>

        </Wrapper>
    </div>

)
