import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero21 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>21. LE CONFERENZE SPIRITUALI A PARIGI
            <br/>4 novembre 1911
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1388416348&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p>Oggi in tutta l’Europa si sente parlare di conferenze, assemblee e associazioni di tutti i tipi. Ve ne
                sono di quelle che si interessano di commercio, di scienza e di politica e molte altre. Tutte queste
                attività servono alla vita materiale, avendo di mira il progresso e l’illuminazione del mondo della
                materia. Raramente vi spira un alito del mondo spirituale. Coloro che vi prendono parte sembrano non
                sentire la Voce divina, non curarsi delle cose di Dio Ma questa nostra riunione a Parigi è veramente
                spirituale. L’Aura divina scende fra voi e la Luce del Regno risplende in ogni cuore. L’amore Divino è
                un potere fra voi e con anime assetate ricevete con gioia la lieta novella.</p>
            <p>Radunati qui concordi, con cuori affratellati e con anime piene d’amore divino, voi desiderate l’unità
                del mondo e lavorate per essa.</p>
            <p>In verità questa è un’assemblea spirituale! È come un bel giardino profumato. Il Sole celeste vi spande i
                suoi raggi dorati, infondendovi il suo calore e rallegrando ogni cuore in attesa. L’amore di Cristo che
                sopravanza ogni conoscenza è fra voi; lo Spirito Santo è l’aiuto vostro.</p>
            <p>Di giorno in giorno questa assemblea crescerà e diventerà sempre più potente finché a poco a poco il suo
                spirito conquisterà tutto il mondo.</p>
            <p>Adoperatevi di tutto cuore ad essere consenzienti apportatori della Munificenza di Dio. Io vi dico che
                Egli vi ha scelti quali Suoi messaggeri d’amore nel mondo, quali apportatori di doni spirituali
                all’uomo, quale mezzo di diffusione dell’unità e della concordia sulla terra. Ringraziate Dio con tutto
                il cuore ché un tal privilegio vi sia stato accordato. Una vita intera dedicata a glorificare Dio non
                basta ancora per ringraziarLo di un tale favore.</p>
            <p>Innalzate i cuori al di sopra del presente e guardate al futuro con occhio fiducioso. Oggi si getta il
                seme e il seme cade sulla terra. Verrà il giorno in cui spunterà una pianta rigogliosa con rami carichi
                di frutti. Rallegratevi e siate felici che sia sorta l’alba di questo giorno, cercate di comprenderne il
                potere, perché è, in verità, meraviglioso. Dio vi ha coronati di onori e nei vostri cuori Egli ha messo
                una stella radiosa. In verità la sua luce rischiarerà tutto il mondo.</p>
        </Wrapper>
    </div>

)
