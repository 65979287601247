import React from 'react';

export const Default = () => (
    <div>
        <p/>
        <h2>ERROR</h2>
    </div>
)


