import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero54 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>54. DISCORSO DI ‘ABDU’L-BAHÁ NELLA CASA DELLE RIUNIONI DEGLI AMICI
            <br/>St. Martin’s Lane, Londra
            <br/>12 gennaio 1913
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1420707334&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p>Circa mille anni or sono si formò in Persia una società chiamata la Società degli Amici. Essi si
                adunavano in silenziosa comunione con l’Onnipotente.</p>
            <p>Essi dividevano la filosofia divina in due parti. La prima comprendeva la filosofia che si apprende con
                letture e studi nelle scuole e nei collegi, la seconda quella cosiddetta degli Illuminati o seguaci
                della luce interiore. Le lezioni di quest’ultima si tenevano in silenzio. E là, nei cuori dei discepoli
                meditanti e rivolti alla Sorgente della Luce, si riflettevano da quella Luce centrale i misteri del
                Regno. Tutti i problemi divini furono risolti da questo potere illuminante.</p>
            <p>Questa Società degli Amici progredì molto in Persia ed esiste ancora. Molto fu scritto in libri e in
                epistole dai capi di quella società. Quando si adunano, i seguaci di quella filosofia rimangono in
                silenzio e in contemplazione. Il capo propone all’assemblea un problema e dice: «Dovete meditare su
                questo problema». Allora, liberata la mente da ogni altro pensiero, essi riflettono e la risoluzione del
                problema non tarda a rivelarsi. Molte astruse questioni divine sono risolte da questa illuminazione.</p>
            <p>Le principali fra le grandi questioni che si sprigionano dai raggi del Sole della Realtà e colpiscono la
                mente dell’uomo sono i temi della realtà dello spirito dell’uomo, della nascita dello spirito, della sua
                nascita da questo mondo in quello di Dio, della sua vita interiore e del suo destino dopo l’ascensione
                dal corpo.</p>
            <p>Essi meditano anche sugli argomenti scientifici del giorno e risolvono anche quelli.</p>
            <p>Queste persone, chiamate «seguaci della luce interiore», raggiungono un grado superlativo di potere e
                sono interamente libere da dogmi e cieche imitazioni. Molti si fidano delle loro parole. Da se stessi,
                in se stessi essi spiegano ogni mistero.</p>
            <p>Se con l’aiuto della luce interiore trovano la soluzione di un problema, la accettano e dopo la
                espongono. Altrimenti la considerano una questione di cieca imitazione. Arrivano al punto da riflettere
                sulla natura essenziale della Divinità, della rivelazione divina, della manifestazione di Dio in questo
                mondo. Grazie al potere dello spirito risolvono tutte le questioni divine e scientifiche.</p>
            <p>Bahá’u’lláh dice che in ogni fenomeno vi è un segno di Dio. Il segno dell’intelletto è la contemplazione
                e il segno della contemplazione è il silenzio, perché non è possibile che un uomo faccia due cose per
                volta. Non si può parlare e meditare allo stesso tempo.</p>
            <p>È un fatto assiomatico che mentre meditate, parlate col vostro spirito. In quello stato mentale rivolgete
                delle domande al vostro spirito ed esso risponde, la luce si accende e la verità si rivela.</p>
            <p>Non potete chiamare «uomo» un essere che non abbia questa facoltà di meditare. Senza di essa, un essere è
                un semplice animale, inferiore alle bestie.</p>
            <p>Grazie alla facoltà della meditazione l’uomo consegue la vita eterna. Grazie ad essa riceve l’alito dello
                Spirito Santo, il dono dello Spirito si rivela nella riflessione e nella meditazione.</p>
            <p>o spirito dell’uomo apprende e si rinforza durante la meditazione. Per essa, cose di cui l’uomo non aveva
                nozione alcuna si svelano alla sua vista. Con la meditazione l’uomo riceve l’ispirazione divina e il
                cibo celeste.</p>
            <p>La meditazione è la chiave per aprire le porte dei misteri. In tale stato l’uomo si astrae, la sua
                attenzione si distoglie da ogni oggetto esteriore, egli si immerge nell’oceano della vita spirituale e
                può svelare i segreti delle cose in se stesse. Per spiegarvelo, pensate che l’uomo è dotato di due
                specie di vista. Quando egli usa il potere della visione interiore, il potere della visione esteriore
                non agisce.</p>
            <p>La facoltà della meditazione libera l’uomo dalla natura animale, discerne la realtà delle cose e mette
                l’uomo in contatto con Dio.</p>
            <p>Questa facoltà trae dalla sfera invisibile le scienze e le arti, rende possibile le invenzioni, fa
                compiere colossali imprese e permette ai governi di operare senza impedimenti. Per mezzo della facoltà
                di meditazione l’uomo entra veramente nel Regno di Dio.</p>
            <p>ondimeno alcuni pensieri sono inutili all’uomo, sono come onde che si agitano nel mare senza alcun
                risultato. Ma se la facoltà della meditazione è immersa nella luce spirituale e caratterizzata dagli
                attributi divini, i risultati sono certi.</p>
            <p>La facoltà della meditazione è come uno specchio. Se la ponete davanti a oggetti terreni li riflette.
                Perciò se lo spirito dell’uomo contempla cose mondane, ha cognizione soltanto di esse.</p>
            <p>Ma se orientate lo specchio dello spirito verso il cielo, le costellazioni celesti e i raggi del Sole
                della Verità si rifletteranno nel vostro cuore e otterrete le virtù del Regno.</p>
            <p>Teniamo dunque ben diretta questa facoltà, volgendola non verso le cose del mondo, ma verso il Sole
                celeste, in modo da poter scoprire i segreti del Regno e comprendere le allegorie della Bibbia e i
                misteri dello spirito.</p>
            <p>Possiamo noi divenire specchi che riflettano le realtà divine e possiamo noi diventare così puri da
                riflettere le stelle del cielo!</p>

        </Wrapper>
    </div>

)
