import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero48 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>48. L’OTTAVO PRINCIPIO: LA PACE UNIVERSALE
            <br/>Avenue de Camoëns 4, Parigi
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1414330657&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p>I popoli e i governi di tutte le Nazioni dovranno fondare un Tribunale supremo composto di membri eletti
                da ogni paese e governo. I membri di questo grande Consesso dovranno riunirsi in perfetta unità. Tutte
                le dispute di carattere internazionale dovranno essere sottoposte a questo Tribunale il cui compito
                consisterà nell’arbitrare qualunque dissidio possa altrimenti essere causa di guerra. La missione di
                questo Tribunale sarà, quindi, quella di prevenire le guerre.</p>
            <p>Uno dei più grandi passi verso la pace universale sarebbe l’adozione di una lingua universale.
                Bahá’u’lláh ordina ai servi dell’umanità di riunirsi e scegliere una lingua esistente o formarne una
                nuova. Questo fu rivelato nel Kitáb-i-Aqdas quarant’anni fa (Dal 1911 (N.d.T.)). Vi si fa notare che la
                questione della diversità delle lingue è un problema molto difficile. Nel mondo vi sono più di ottocento
                lingue e non v’è persona che possa impararle tutte.</p>
            <p>Le varie razze non sono più così isolate come lo erano in passato. Ora per mantenere strette relazioni
                con tutti i paesi è necessario saper parlare le loro lingue.</p>
            <p>Una lingua universale renderebbe possibile le relazioni con tutte le nazioni. Così sarebbe necessario
                conoscere due lingue soltanto, la madre lingua e quella universale. Quest’ultima permetterebbe a tutti
                di parlare con chiunque nel mondo!</p>
            <p>Non occorrerebbe una terza lingua. Come sarebbe proficuo e riposante poter conversare con i membri di
                qualsiasi razza o paese senza dover ricorrere a un interprete!</p>
            <p>L’esperanto è stato creato in vista di questo scopo. È un’eccellente invenzione e un’ottima creazione, ma
                deve essere perfezionato. L’esperanto com’è adesso è molto difficile per alcune persone.</p>
            <p>Si dovrebbe formare un Congresso internazionale con delegati di ogni nazione del mondo, orientali e
                occidentali senza distinzione. Questo Congresso dovrebbe creare una lingua facile per tutti e ogni paese
                ne trarrebbe grande beneficio.</p>
            <p>Finché questa lingua non sarà in uso, il mondo continuerà a sentire il bisogno di un tale mezzo di
                comunicazione. La differenza di idioma è una delle maggiori cause di avversione e diffidenza fra le
                nazioni, che rimangono separate più per la loro incapacità di comprendere le rispettive lingue, che per
                qualsiasi altra ragione.</p>
            <p>Se tutti potessero parlare una sola lingua, come sarebbe più facile servire l’umanità!</p>
            <p>Perciò, apprezzate l’esperanto, poiché è l’inizio dell’applicazione di una delle più importanti leggi di
                Bahá’u’lláh, ma dev’essere migliorato e perfezionato.</p>

        </Wrapper>
    </div>

)
