export const quotes:string[] = [
    "1. Quando volge lo sguardo verso Dio l’uomo trova tutt’intorno lo splendore del sole. Tutti gli uomini sono suoi fratelli.",
    "2. La realtà dell’uomo è il suo pensiero, non il suo corpo fisico",
    "3. La guarigione vera viene da Dio! Vi sono due cause di malattia: una materiale, l’altra spirituale.",
    "4. L’Occidente e l’Oriente devono unirsi per darsi reciprocamente ciò di cui sono privi. Questa unione porterà una vera civiltà, nella quale l’elemento spirituale della vita si esprime e si realizza in quello materiale.",
    "5. Tutte queste conferenze vanno bene. Ma questa assemblea si è incontrata per rivolgere il viso verso Dio per imparare come meglio si possa lavorare per il bene dell’umanità, per cercare di abolire i pregiudizi e di seminare nel cuore umano i semi dell’amore e della fratellanza universale.",
    "6. Raccomando a ciascuno di voi di concentrare tutti i pensieri del suo cuore sull’amore e sull’unità. Quando viene un pensiero di guerra, opponetegli un più forte pensiero di pace.",
    "7. Un uomo può essere giunto a un alto livello di progresso materiale, ma senza la luce della verità la sua anima è gracile e malnutrita.",
    "8. L’Occidente ha sempre ricevuto la luce spirituale dall’Oriente. La Melodia del Regno celeste si sente prima in Oriente, ma in Occidente il volume del suono erompe più alto sulle orecchie che ascoltano.",
    "9. L’amore manifesta la propria realtà non soltanto con le parole, ma con le azioni. Le sole parole non producono alcun effetto. Perché l’amore possa manifestare il suo potere è necessario che vi siano un oggetto, un mezzo e una causa.",
    "10. Quel che più importa è che, grazie a Dio, i nostri cuori battono sempre all’unisono e con una medesima aspirazione sono portati verso l’amore di Dio.",
    "11. Il dono più grande che l’uomo ha ricevuto da Dio è quello dell’intelletto o della comprensione.",
    "12. È una bella giornata; l’aria è pura, il sole risplende, la nebbia e le nuvole non offuscano l’orizzonte. Questi raggi brillanti penetrano in ogni parte della città. Così possa il Sole della Verità illuminare lementi degli uomini.",
    "13. La base degli insegnamenti di Bahá’u’lláh è l’unità del genere umano. Il Suo più grande desiderio fu che la benevolenza e l’amore fossero sempre nel cuore degli uomini.",
    "14. Dio solo comanda a tutte le cose ed è onnipotente. Perché, allora, manda delle prove ai servi Suoi?",
    "15. Il Creatore di tutto ciò che esiste è Iddio Unico. Da questo stesso Dio tutta la creazione ha avuto origine ed Egli è il solo fine, al quale ogni cosa nella natura agogna.",
    "16. Nella Bibbia vi sono profezie sulla venuta di Cristo. Gli ebrei attendono ancora la venuta del Messia e pregano Dio giorno e notte per affrettarne l’avvento.",
    "17. La Realtà divina è Inconcepibile, Illimitata, Eterna, Immortale e Invisibile. Il mondo della creazione è limitato dalla legge naturale, è finito e mortale.",
    "18. Nell’uomo vi sono due nature: una elevata, quella spirituale, e una bassa, quella materiale. Con la prima egli si avvicina a Dio, con l’altra vive soltanto per il mondo. ",
    "19. Se riesce nei suoi affari, nella sua arte o nella sua professione, un uomo può aumentare il proprio benessere fisico e dare al proprio corpo agi e comodità nei quali si compiace.",
    "20. Dio, nella Sua Benevolenza, ce ne ha dato un’idea in questo mondo, ci ha fornito alcune prove della differenza che esiste fra il corpo, l’anima e lo spirito.",
    "21. Oggi in tutta l’Europa si sente parlare di conferenze, assemblee e associazioni di tutti i tipi. Ve ne sono di quelle che si interessano di commercio, di scienza e di politica e molte altre. Tutte queste attività servono alla vita materiale, avendo di mira il progresso e l’illuminazione del mondo della materia. Raramente vi spira un alito del mondo spirituale. ",
    "22. La luce dell’intelletto ci permette di comprendere e concepire tutto ciò che esiste. Ma è soltanto la luce divina che può farci vedere le cose invisibili e che ci permette di vedere quelle verità che saranno visibili al mondo soltanto fra migliaia di anni",
    "23. L’uomo progredisce sempre. Il cerchio delle sue cognizioni si allarga sempre più e la sua attività mentale si diffonde in molti e diversi rami. Guardate che cosa l’uomo ha potuto fare nel campo della scienza! Pensate alle sue tante scoperte e innumerevoli invenzioni e alla sua profonda conoscenza delle leggi della natura!",
    "24. Prego Dio che ogni giorno possiate progredire nella spiritualità, che l’amore di Dio si manifesti in voi sempre di più, che i sentimenti dei vostri cuori siano purificati e che i vostri visi siano sempre rivolti verso di Lui",
    "25. Oggi vi parlerò di Bahá’u’lláh. Tre anni dopo che il Báb ebbe dichiarato la Sua missione, Bahá’u’lláh, accusato dai fanatici mullá (Mullá, sacerdoti musulmani) , di credere alla nuova dottrina, fu arrestato e messo in carcere.",
    "26. In tutto il mondo si sentono magnificare le belle frasi e si ammirano i nobili precetti. Tutti dicono di amare ciò che è buono e odiare tutto ciò che è cattivo.",
    "27. Nel Vangelo secondo Giovanni, Gesù disse: «In verità vi dico che se alcuno non è nato d’acqua e di Spirito, non può entrare nel Regno di Dio» (Giovanni III, 5)",
    "28. Ogni cuore deve irradiare unità, perché la luce dell’unica Sorgente divina di tutti possa risplendere chiara e luminosa. Dobbiamo considerare il mare intero e non soltanto le onde separate. Dobbiamo salire dall’individuo alla collettività. Lo spirito è come un unico grande mare e le sue onde sono le anime degli uomini.",
    "29. La perfezione divina è infinita, perciò il progresso dell’anima è illimitato. Fin da quando un essere umano viene al mondo, l’anima progredisce, l’intelletto cresce e la conoscenza aumenta. Quando il corpo muore, l’anima sopravvive.",
    "30. Prego che ciascuno di voi sia come una fiamma d’amore nel mondo e che la chiarezza della vostra luce e il calore del vostro affetto raggiungano il cuore di ogni triste e addolorato figlio di Dio.",
    "31. Alcuni uomini si occupano solamente delle cose di questo mondo. Le loro menti sono limitate dalle maniere esteriori e dai tradizionali interessi e non vedono nessun altro regno dell’esistenza, non capiscono il significato spirituale di tutte le cose.",
    "32. Lavoriamo e preghiamo per l’unità del genere umano, perché tutte le razze della terra diventino una razza sola, tutti i paesi un unico paese e tutti i cuori battano come un sol cuore, lavorando insieme per l’unità e la fratellanza perfette.",
    "33. Dal principio del mondo fino ad oggi ogni Manifestazione di Dio ha trovato opposizione in un’incarnazione del «potere delle tenebre».",
    "34. La crudeltà e la ferocia sono naturali per gli animali, ma gli uomini dovrebbero mostrare amore e affetto. Dio mandò nel mondo i Suoi Profeti con uno scopo: seminare amore e benevolenza nei cuori degli uomini e per questo ideale essi furono pronti a soffrire e morire.",
    "35. In questo mondo siamo influenzati da due sentimenti: la gioia e il dolore.",
    "36. Voi appartenete al mondo della purezza e non vi accontentate di vivere la vita dell’animale e passare così i vostri giorni mangiando, bevendo e dormendo. Siete veramente uomini. I vostri pensieri e le vostre ambizioni tendono ad acquisire la perfezione umana. Vivete per far bene e dar felicità agli altri.",
    "37. Se gli uomini si amassero e si aiutassero invece di essere così bramosi di distruggersi l’un l’altro a colpi di spada e di cannone, quanto più nobili sarebbero! Se vivessero come uno stormo di colombi in pace e in armonia invece di essere come lupi e sbranarsi reciprocamente, quanto meglio sarebbe!",
    "38. Quando Cristo apparve, Si manifestò a Gerusalemme. Egli invitò gli uomini al Regno di Dio, li chiamò alla Vita eterna e disse loro di acquisire le perfezioni umane. La Luce della guida irradiò da quella raggiante Stella e alla fine Egli dette la vita in sacrificio per l’umanità.",
    "39. Venne Gesù e accese la torcia ardente della verità e la levò in alto perché il mondo intero ne fosse illuminato. Dopo di Lui vennero i suoi Apostoli eletti ed essi andarono dappertutto, portando la luce degli insegnamenti del loro Maestro nel mondo buio. Poi anche loro passarono a vita eterna.",
    "40. Il sole sorge sempre dall’orizzonte, però in estate si alza più a nord, in inverno più a sud. Ma, pur sorgendo da punti diversi, è sempre lo stesso sole.",
    "41. Chi desidera aver successo nella ricerca della Verità deve in primo luogo chiudere gli occhi su tutte le superstizioni tradizionali del passato.",
    "42. Tutti gli uomini sono servi di un Dio Unico. Un Unico Dio regna su tutte le nazioni del mondo e Si compiace in tutti i Suoi figli. Tutti gli uomini appartengono a una sola famiglia, la corona dell’umanità è posata sulla testa d’ogni essere umano.",
    "43. «che la religione debba essere causa d’amore e d’affetto» è stato già messo in grande evidenza negli appunti tratti da molti altri discorsi riportati in questo libro, e nelle spiegazioni di molti altri principi",
    "44. Non v’è contraddizione fra la vera religione e la scienza. Quando si oppone alla scienza, la religione diventa superstizione. Tutto ciò che è contrario al sapere è ignoranza.",
    "45. Tutti i pregiudizi, siano essi religiosi, razziali, politici o nazionalistici, devono essere abbandonati perché hanno causato la rovina del mondo.",
    "46. Uno dei più importanti principi fra gli Insegnamenti di Bahá’u’lláh è il diritto di ogni essere umano al pane quotidiano col quale sussiste, ovvero la parificazione dei mezzi di sussistenza.",
    "47. Quando in tutti i paesi dell’Oriente e dell’Occidente regnerà una giustizia perfetta, la terra diverrà un luogo magnifico.",
    "48. I popoli e i governi di tutte le Nazioni dovranno fondare un Tribunale supremo composto di membri eletti da ogni paese e governo. I membri di questo grande Consesso dovranno riunirsi in perfetta unità.",
    "49. La religione s’interessa delle cose del cuore, dello spirito e della morale. La politica si occupa delle cose materiali della vita. I maestri delle religioni non devono invadere il regno della politica.",
    "50. Dio ha creato tutte le creature a coppie. Uomini, animali o vegetali, tutte le cose di questi tre regni sono di due sessi e fra loro v’è parità assoluta.",
    "51. Negli insegnamenti di Bahá’u’lláh è scritto: «Soltanto con il potere dello Spirito Santo l’uomo può progredire, perché la Potenza divina è infinita». ",
    "52. Dovete cercare sempre di vivere e agire nella diretta obbedienza agli insegnamenti e alle leggi di Bahá’u’lláh, così che ogni persona possa vedere in tutti gli atti della vostra vita che, in parole e opere, voi siete seguaci della Perfezione Benedetta. ",
    "53. Non lasciate che le vostre menti si soffermino sul presente. Fate che guardino con occhi fiduciosi al futuro, perché in verità lo Spirito di Dio sta operando fra voi.",
    "54. Bahá’u’lláh dice che in ogni fenomeno vi è un segno di Dio. Il segno dell’intelletto è la contemplazione e il segno della contemplazione è il silenzio, perché non è possibile che un uomo faccia due cose per volta. Non si può parlare e meditare allo stesso tempo.",
    "55. ‘Abdu’l-Bahá: «Sì, nella Causa bahá’í le arti, le scienze e tutti i mestieri sono (considerati) atti di culto. L’uomo che fabbrica un pezzo di carta da scrivere, mettendo nel suo lavoro il meglio della sua abilità, eseguendolo coscienziosamente, concentrando tutte le forze per perfezionarlo, rende lode a Dio.",
    "56. Che cos’è il male?",
    "57. ‘Abdu’l-Bahá: «La mente e lo spirito dell’uomo progrediscono quando egli è provato dalle sofferenze. Più la terra è arata, meglio si sviluppa il seme, più abbondante sarà il raccolto. Come l’aratro solca profondamente la terra, liberandola dalle erbacce e dai rovi, così la sofferenza e l’afflizione liberano l’uomo dalle meschinità di questa vita mondana e gradatamente egli perviene a uno stato di completo distacco.",
    "58. Nel mondo dell’esistenza non c’è in verità una forza più grande di quella dell’amore. Quando la fiamma dell’amore è accesa nel suo cuore, l’uomo è pronto a sacrificare tutto, anche la vita. Nel Vangelo è detto che Dio è amore.",
    "59. In questa Rivelazione di Bahá’u’lláh, le donne procedono di pari passo con gli uomini.",

];
