import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";
// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero17 = () => (
    <div>
        <p/>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>17. LO SPIRITO SANTO È IL POTERE INTERMEDIARIO TRA DIO E L’UOMO
            <br/>Avenue de Camoëns 4
            <br/>31 ottobre 1911
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1388413360&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p>La Realtà divina è Inconcepibile, Illimitata, Eterna, Immortale e Invisibile.</p>
            <p>Il mondo della creazione è limitato dalla legge naturale, è finito e mortale.</p>
            <p>Della Realtà Infinita non si può dire che ascenda o discenda. Essa trascende la comprensione dell’uomo e
                non può esser descritta in termini che si applicano alla sfera dei fenomeni del mondo creato.</p>
            <p>L’uomo, dunque, ha assoluta necessità del solo Potere per mezzo del quale può ricevere l’aiuto della
                Realtà divina, essendo quel solo Potere capace di metterlo in contatto con la Sorgente della vita.</p>
            <p>Per mettere in relazione due estremi, occorre un intermediario. Ricchezza e povertà, abbondanza e
                bisogno, senza un intermediario fra loro non vi sarebbe alcun rapporto.</p>
            <p>Così si può dire che occorre un Mediatore fra Dio e l’uomo e questo Mediatore non è che lo Spirito Santo,
                che mette in contatto il mondo creato con l’«Inconcepibile», con la Realtà divina.</p>
            <p>La Realtà divina può essere paragonata al sole e lo Spirito Santo ai raggi del sole. Come i raggi del
                sole portano la luce e il calore del sole alla terra, dando vita a tutti gli esseri creati, così le
                «Manifestazioni» (Le Manifestazioni di Dio) portano all’umanità dal Sole della divina essenza il potere
                dello Spirito Santo per dare luce e vita alle anime.</p>
            <p>Come vedete, fra il sole e la terra occorre un intermediario. Il sole non discende in terra e la terra
                non sale fino al sole. Il contatto si effettua mediante i raggi del sole che portano la luce e il
                calore.</p>
            <p>Lo Spirito Santo è la luce del Sole della Verità che, col Suo potere infinito, porta vita e luce a tutta
                l’umanità, che inonda tutte le anime di Splendore divino, che impartisce al mondo intero le benedizioni
                della Misericordia di Dio. Senza il calore e la luce dei raggi del sole, la terra non potrebbe riceverne
                alcun beneficio.</p>
            <p>Similmente lo Spirito Santo è la vera causa della vita dell’uomo. Senza lo Spirito Santo egli non avrebbe
                l’intelletto, non potrebbe acquistare quelle cognizioni scientifiche, alle quali deve la sua grande
                influenza sul resto della creazione. L’illuminazione dello Spirito Santo dà all’uomo il potere del
                pensiero e lo rende capace di fare scoperte grazie alle quali egli piega le leggi della natura alla
                propria volontà.</p>
            <p>Lo Spirito Santo è ciò che, per mezzo dei Profeti di Dio, insegna all’uomo le virtù spirituali e lo rende
                degno di ottenere la Vita eterna.</p>
            <p>Tutte queste benedizioni sono portate all’uomo dallo Spirito Santo. Perciò possiamo comprendere che esso
                è l’intermediario tra il Creatore e il creato. La luce e il calore del Sole rendono la terra fruttifera
                e creano la vita in tutto ciò che cresce e lo Spirito Santo vivifica le anime degli uomini.</p>
            <p>I due grandi Apostoli, san Pietro e san Giovanni Evangelista, erano uomini semplici, umili lavoratori che
                faticavano per guadagnarsi il pane quotidiano. Le loro anime furono illuminate dal Potere dello Spirito
                Santo ed essi ricevettero le benedizioni eterne di nostro Signore Gesù Cristo.</p>
        </Wrapper>
    </div>

)
