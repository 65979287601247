import React from 'react';
import {SoundCloudPlayer} from '../SoundCloudPlayer';
import styled from "styled-components";

// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.0em;
  text-align: center;
`;

const Title2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
`;
// @ts-ignore
export const Numero2 = () => (
    <div>
        <Title>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </Title>
        <Title2>2. IL POTERE E IL VALORE DEI PENSIERI GIUSTI DIPENDONO DALL’AZIONE
            <br/>18 ottobre 1911
        </Title2>
        <Wrapper>
            <SoundCloudPlayer
                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1375847575&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
            <p>
                La realtà dell’uomo è il suo pensiero, non il suo corpo fisico. La forza del pensiero e la forza animale
                sono compagne. Benché sia parte del regno animale, l’uomo ha capacità di pensiero superiore a tutte le
                altre creature.
            </p>
            <p>
                L’uomo il cui pensiero aspira solo alle cose del cielo diventa santo. Ma se il suo pensiero non
                s’innalza, se è diretto verso il basso e si concentra sulle cose di questo mondo, egli diventa sempre
                più materialista fino ad abbassarsi a una condizione di poco migliore di quella delle bestie.
            </p>
            <p>
                I pensieri si possono dividere in due classi:
                <br/>(primo) pensieri che appartengono alla sfera del pensiero soltanto,
                <br/>(secondo) pensieri che si esprimono in azioni.
            </p>
            <p>
                Vi sono donne e uomini che si gloriano dei loro elevati pensieri. Ma se non arrivano mai sul piano delle
                azioni, questi pensieri sono inutili. Il potere del pensiero dipende dalla sua realizzazione. Il
                pensiero d’un filosofo può, tuttavia, nel mondo del progresso e dell’evoluzione, trasferirsi nelle
                azioni di altre persone, anche se il filosofo non vuole o non può realizzare i suoi grandi ideali nella
                sua vita. La maggioranza dei filosofi appartiene a questa classe, essendo i loro insegnamenti al di
                sopra delle loro azioni. Questa è la differenza fra i filosofi che sono Maestri spirituali e quelli che
                sono semplici filosofi. Il Maestro spirituale è il primo a seguire i propri insegnamenti. Egli trasporta
                nel mondo dell’azione i Suoi concetti e i Suoi ideali spirituali. I Suoi pensieri divini si manifestano
                al mondo. Il Suo pensiero è Lui Stesso ed Egli ne è inseparabile. Quando troviamo un filosofo che
                magnifica l’importanza e la grandezza della giustizia e poi incoraggia un monarca rapace
                nell’oppressione e nella tirannia, capiamo subito che appartiene alla prima classe, perché ha pensieri
                sublimi, ma non pratica altrettanto sublimi virtù.
            </p>
            <p>
                Questo non può avvenire ai Filosofi spirituali, perché essi esprimono sempre i loro eccelsi e nobili
                pensieri nelle azioni.
            </p>
        </Wrapper>
    </div>

)
